import { throwErrorNotification } from 'ADMIN_COMMON/ac/notificationMethods';
import { t } from '@lingui/macro';

export const standardError = (response) => {
    let message = t`Что-то пошло не так`;
    if (response?.message) message = response.message;
    if (response?.responseJSON?.message)
        message = response.responseJSON.message;
    if (response?.error)
        message = `${response.error.code}: ${response.error.message}`;
    window.page.dispatch(throwErrorNotification(message));
    window.page.hideSpinner();
};
