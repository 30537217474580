'use strict';

import { find } from 'UTILS/find';
import { removeNode } from 'UTILS/removeNode';

/**
 * @param {Element} node
 * */
function removeErrorHelper(node) {
    if (!node) return;
    removeNode(find('.js_error_helper', node.parentNode));
}

export { removeErrorHelper };
