import React, { useEffect } from 'react';

export const FilterWrapper = ({
    label,
    children,
    requestFilters,
    className = '',
    blockFiltersRequest = false,
    style = {},
}) => {
    useEffect(() => {
        requestFilters && !blockFiltersRequest && requestFilters();
    }, [blockFiltersRequest]);
    return (
        <div className={`filter ${className}`} style={style}>
            <div>
                <strong className="filter__label">{label}</strong>
            </div>
            <div>{children}</div>
        </div>
    );
};
