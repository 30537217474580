import { t } from '@lingui/macro';
import React, { useCallback, useState } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { BatteriesChargeLevelEditModal } from './BatteriesChargeLevelEditModal';
import { BatteriesChargeLevelTableHead } from './BatteriesChargeLevelTableHead';
import { BatteriesChargeLevelTableRow } from './BatteriesChargeLevelTableRow';

export const BatteriesChargeLevelTable = ({ list }) => {
    const [selectedSet, setSelectedSet] = useState(new Set());
    const [editingList, setEditingList] = useState([]);

    const handleSelectRegion = useCallback(
        (e) => {
            const { checked, value } = e.target;

            setSelectedSet((prev) => {
                if (value === 'all') {
                    return new Set(
                        checked ? list.map((item) => item.region.id) : []
                    );
                }

                if (checked) {
                    prev.add(value);
                } else {
                    prev.delete(value);
                }

                return new Set(prev);
            });
        },
        [list]
    );

    const handleEditClick = useCallback((e) => {
        const { regionId } = e.target.dataset;

        const itemWithRegion = list.find((item) => item.region.id === regionId);

        if (itemWithRegion) {
            setEditingList([itemWithRegion]);
        }
    }, []);

    const handleBulkEditClick = useCallback(() => {
        setEditingList(list.filter((item) => selectedSet.has(item.region.id)));
    }, [selectedSet, list]);

    const handleEditClose = useCallback(() => {
        setEditingList([]);
    }, []);

    return (
        <div>
            <ButtonToolbar
                className="justify-content-end"
                style={{ minHeight: 31 }}
            >
                {selectedSet.size > 0 && (
                    <Button
                        size="sm"
                        onClick={handleBulkEditClick}
                        hidden={selectedSet.size === 0}
                    >
                        {t`Изменить для выбранных`}
                    </Button>
                )}
            </ButtonToolbar>

            <div className="table-responsive users-list">
                <table className="table table-striped table-sm">
                    <BatteriesChargeLevelTableHead
                        selected={selectedSet}
                        list={list}
                        onSelectAll={handleSelectRegion}
                    />

                    <tbody>
                        {list.map((item) => (
                            <BatteriesChargeLevelTableRow
                                key={item.region.id}
                                item={item}
                                selected={selectedSet.has(item.region.id)}
                                onSelectRegion={handleSelectRegion}
                                onEditClick={handleEditClick}
                            />
                        ))}
                    </tbody>
                </table>
            </div>

            <BatteriesChargeLevelEditModal
                editingList={editingList}
                show={editingList.length > 0}
                onHide={handleEditClose}
            />
        </div>
    );
};
