export const START = '_START';
export const SUCCESS = '_SUCCESS';
export const ERROR = '_ERROR';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SORT = 'SET_SORT';
export const CHECK_DEVICES = 'CHECK_DEVICES';
export const SAVE_LAST_ACTION = 'SAVE_LAST_ACTION';
export const GET_PARKING_LOTS = 'GET_PARKING_LOTS';
export const TOGGLE_SWITCH = 'TOGGLE_SWITCH';
export const NEW_PARKING_MODAL = 'NEW_PARKING_MODAL';
export const NEW_ZONE_MODAL = 'NEW_ZONE_MODAL';
export const CHANGE_PARKING_FORM = 'CHANGE_PARKING_FORM';
export const EDIT_PARKING_MODAL = 'EDIT_PARKING_MODAL';
export const CHANGE_ZONE_FORM = 'CHANGE_ZONE_FORM';
export const EDIT_ZONE_MODAL = 'EDIT_ZONE_MODAL';
export const UPDATE_COORDINATES_MODAL = 'UPDATE_COORDINATES_MODAL';
export const OPEN = '_OPEN';
export const CLOSE = '_CLOSE';
export const RESET_FORM = 'RESET_FORM';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const DELETE_PARKING = 'DELETE_PARKING';
export const UPDATE_PARKING = 'UPDATE_PARKING';
export const POST_PARKING = 'POST_PARKING';
export const GET_ZONES = 'GET_ZONES';
export const FETCH_DISTRICTS = 'FETCH_DISTRICTS';
export const POST_NEW_ZONE = 'POST_NEW_ZONE';
export const UPDATE_ZONE = 'UPDATE_ZONE';
export const UPDATE_ZONE_BORDER = 'UPDATE_ZONE_BORDER';
export const UPDATE_COORDINATES = 'UPDATE_COORDINATES';
export const DELETE_ZONE = 'DELETE_ZONE';
export const DEVICE_MODAL = 'DEVICE_MODAL';
export const REQUEST_WAREHOUSES = 'REQUEST_WAREHOUSES';
export const NEW_DEVICE_MODAL = 'NEW_DEVICE_MODAL';
export const CHANGE_WAREHOUSE_FORM = 'CHANGE_WAREHOUSE_FORM';
export const EDIT_WAREHOUSE_MODAL = 'EDIT_WAREHOUSE_MODAL';
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';
export const REQUEST_CARS = 'REQUEST_CARS';
export const ADD_NEW_CAR_MODAL = 'ADD_NEW_CAR_MODAL';
export const ADD_NEW_WAREHOUSE = 'ADD_NEW_WAREHOUSE';
export const ADD_WAREHOUSE_MODAL = 'ADD_WAREHOUSE_MODAL';
export const GET_CELLS = 'GET_CELLS';
export const ADD_NEW_GRID = 'ADD_NEW_GRID';
export const REQUEST_DEVICE_TRACK = 'REQUEST_DEVICE_TRACK';
export const BLOCK_USER_MODAL = 'BLOCK_USER_MODAL';
export const BLOCK_USER = 'BLOCK_USER';
export const REQUEST_OPERATION_AREAS = 'REQUEST_OPERATION_AREAS';
export const REQUEST_LABELS = 'REQUEST_LABELS';
export const UPDATE_LABEL = 'UPDATE_LABEL';
export const DELETE_LABEL = 'DELETE_LABEL';
export const POST_LABEL = 'POST_LABEL';
export const REQUEST_PENALTIES = 'REQUEST_PENALTIES';
export const REQUEST_PENALTY_TARIFFS = 'REQUEST_PENALTY_TARIFFS';
export const POST_PENALTY = 'POST_PENALTY';
export const POST_PENALTY_TARIFF = 'POST_PENALTY_TARIFF';
export const DELETE_PENALTY_TARIFF = 'DELETE_PENALTY_TARIFF';
export const UPDATE_PENALTY_TARIFF = 'UPDATE_PENALTY_TARIFF';
export const REQUEST_TARIFFS = 'REQUEST_TARIFFS';
export const POST_TARIFF = 'POST_TARIFF';
export const DELETE_TARIFF = 'DELETE_TARIFF';
export const UPDATE_TARIFF = 'UPDATE_TARIFF';
export const UNBLOCK_USER = 'UNBLOCK_USER';
export const TOGGLE_MAP = 'TOGGLE_MAP';
export const CHANGE_CAR_FORM = 'CHANGE_CAR_FORM';
export const EDIT_CAR_MODAL = 'EDIT_CAR_MODAL';
export const UPDATE_CAR = 'UPDATE_CAR';
export const DELETE_CAR = 'DELETE_CAR';
export const REQUEST_MODELS = 'REQUEST_MODELS';
export const REQUEST_LOCKS = 'REQUEST_LOCKS';
export const UPDATE_FIRMWARE = 'UPDATE_FIRMWARE';
export const REQUEST_PARKING_PARTNERS = 'REQUEST_PARKING_PARTNERS';
export const POST_PARKING_PARTNER = 'POST_PARKING_PARTNER';
export const DELETE_PARKING_PARTNER = 'DELETE_PARKING_PARTNER';
export const REQUEST_BATTERY_MODELS = 'REQUEST_BATTERY_MODELS';
export const REQUEST_LOGGED_USER = 'REQUEST_LOGGED_USER';
export const REQUEST_REGION_FILTERS = 'REQUEST_REGION_FILTERS';
export const REQUEST_ORGANIZATION_FILTERS = 'REQUEST_ORGANIZATION_FILTERS';
export const THROW_NOTIFICATION = 'THROW_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const CLOSE_ALL_NOTIFICATIONS = 'CLOSE_ALL_NOTIFICATIONS';
export const UNBLOCK_USER_MODAL = 'UNBLOCK_USER_MODAL';
export const POST_DEVICE_COMMENT = 'POST_DEVICE_COMMENT';
export const REQUEST_WEBAPP_CONFIGS = 'REQUEST_WEBAPP_CONFIGS';
export const PARTIAL_ZONE_ROUNDING_ENABLED = 'PARTIAL_ZONE_ROUNDING_ENABLED';
export const FULL_ZONE_ROUNDING_ENABLED = 'FULL_ZONE_ROUNDING_ENABLED';
export const SET_ROUNDED_ZONE_DATA = 'SET_ROUNDED_ZONE_DATA';
export const RESET_ZONE_ROUNDING = 'RESET_ZONE_ROUNDING';
export const SET_ROUNDED_ZONE_BORDERS = 'SET_ROUNDED_ZONE_BORDERS';
export const SUBMIT_PARTIAL_ZONE_ROUNDING = 'SUBMIT_PARTIAL_ZONE_ROUNDING';
export const SUBMIT_FULL_ZONE_ROUNDING = 'SUBMIT_FULL_ZONE_ROUNDING';
export const REQUEST_REGION_BORDERS = 'REQUEST_REGION_BORDERS';
export const REQUEST_PARKING_MANAGERS = 'REQUEST_PARKING_MANAGERS';
export const REQUEST_PARKING_OWNERS = 'REQUEST_PARKING_OWNERS';
export const REQUEST_PARKING_ADMINISTRATIVE_STATUSES =
    'REQUEST_PARKING_ADMINISTRATIVE_STATUSES';
export const REQUEST_PARKING_ADMINISTRATIVE_AGREEMENT_TYPES =
    'REQUEST_PARKING_ADMINISTRATIVE_AGREEMENT_TYPES';
export const REQUEST_ADMIN_CONFIGURATION = 'REQUEST_ADMIN_CONFIGURATION';
export const CHANGE_MAP_REGION = 'CHANGE_MAP_REGION';
export const EXTERNAL_FUNCTIONAL_AREA_MODAL = 'EXTERNAL_FUNCTIONAL_AREA_MODAL';
export const NEW_APP_ALERT = 'NEW_APP_ALERT';
