'use strict';

(function (d) {
    d.clearTime = d.clearTime || function () {
        this.setHours(0);
        this.setMinutes(0);
        this.setSeconds(0);
        this.setMilliseconds(0);
        return this;
    };
    d.addYears = function (y) {
        const year = this.getFullYear() + y;
        const date = new Date(this.getTime());

        date.setFullYear(year);
        return date;
    };
    // CHANGES ONLY MONTH VALUE IGNORING MONTH DAY
    d.addMonths = function (m) {
        const month = this.getMonth() + m;
        const date = new Date(this.getTime());

        date.setMonth(month);
        return date;
    };
    d.addDays = function (d) {
        const day = this.getDate() + d;
        const date = new Date(this.getTime());

        date.setDate(day);
        return date;
    };
})(Date.prototype);
