import * as T from '../constants/types';
import { SUCCESS, ERROR } from 'ADMIN_COMMON/constants/types';

const initialState = {
    isLoggedIn: false,
    error: null,
};

export const login = (state = initialState, { type, payload }) => {
    switch (type) {
        case T.SIGN_IN + SUCCESS:
            return {
                isLoggedIn: true,
                error: null,
            };

        case T.SIGN_IN + ERROR:
            return {
                isLoggedIn: false,
                error: payload,
            };
        default:
            return state;
    }
};
