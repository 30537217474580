import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import React, { useEffect, useMemo } from 'react';
import styles from './dropdownRenderer.module.scss';
import { Trans } from '@lingui/macro';

const dropdownRenderer = ({ props, state, methods }) => {
    // Эффект для очистки внутреннего состояния react-dropdown-select
    // Т.к. мы можем выбрать элементы, сменить валюту,
    // но внутренне состояние сохранится не смотря на values = []
    useEffect(() => {
        if (props.values.length === 0) {
            methods.clearAll();
        }
    }, []);

    const values = useMemo(
        () => state.values.map(({ value }) => value),
        [state]
    );

    const showControls = props.options.every(({ disabled }) => !disabled);
    return (
        <div className={styles.rendererWrapper}>
            {showControls && (
                <ButtonGroup className={styles.btnGroup}>
                    <Button variant="secondary" onClick={methods.selectAll}>
                        <Trans>Выбрать все</Trans>
                    </Button>
                    <Button variant="secondary" onClick={methods.clearAll}>
                        <Trans>Убрать все</Trans>
                    </Button>
                </ButtonGroup>
            )}
            {props.options.map((option) => (
                <Form.Check
                    label={option.label}
                    id={option.value}
                    key={option.value}
                    type="checkbox"
                    disabled={option.disabled}
                    onChange={() => methods.addItem(option)}
                    checked={values.includes(option.value)}
                />
            ))}
        </div>
    );
};

export default dropdownRenderer;
