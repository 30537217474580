import { useMessages } from 'ADMIN_COMMON/hooks/useMessages';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { processMenuItems } from 'UTILS/processMenuItems';
import { useSession } from '~/session';
import { HeaderMenu } from './HeaderMenu';
import { menuItems } from './menuItems';

export const MainHeader = () => {
    const session = useSession();
    const { getMessage } = useMessages();

    const processedMenuItems = useMemo(
        () => processMenuItems(menuItems, session),
        [session]
    );

    return (
        <div className="d-flex justify-content-between main-header">
            <ul className="nav nav-pills">
                {processedMenuItems.map(({ code, path }) => (
                    <li key={code} className="nav-item">
                        <a
                            href={path}
                            className={classnames('nav-link', {
                                active: location.pathname === path,
                            })}
                        >
                            {getMessage(code, code)}
                        </a>
                    </li>
                ))}
            </ul>
            <HeaderMenu />
        </div>
    );
};
