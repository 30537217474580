import statusDict from 'ADMIN_COMMON/utils/statusDict';
import {
    getLocalStorageItem,
    saveLocalStorageItem,
} from 'UTILS/localStorageHelpers';

const filtersDict = {
    bootVersions: 'filterController',
    firmwareVersions: 'filterFirmware',
    hardwareVersions: 'filterHardware',
    state: 'filterState',
    models: 'filterModels',
    statuses: 'filterStatus',
    regions: 'filterRegion',
    labels: 'filterLabels',
    ecuVersions: 'filterEcus',
    bcuVersions: 'filterBcus',
    dashVersions: 'filterDashes',
    organizations: 'filterOrganizations',
};
export const saveFilterItems = (key, value) => {
    if (filtersDict[key]) saveLocalStorageItem(filtersDict[key], value);
};
export const getFilterItem = (key) => {
    const value = filtersDict[key];
    if (key === 'state') return getLocalStorageItem(value) || 'ALL';
    if (key === 'regions') return getLocalStorageItem(value) || [];
    if (key === 'statuses')
        return (
            getLocalStorageItem(value) || statusDict.map(({ value }) => value)
        );
    return getLocalStorageItem(value) || [];
};
