import { useMessages } from 'ADMIN_COMMON/hooks/useMessages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useRegionFilterOptions() {
    const regionFilters = useSelector(
        ({ regionFilters }) => regionFilters ?? []
    );
    const { getMessage } = useMessages();
    const options = useMemo(
        () =>
            regionFilters.map(({ id, code, name }) => ({
                value: id,
                label: getMessage(`region.name.${code}`, name),
            })),
        [regionFilters]
    );

    return options;
}
