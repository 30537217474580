'use strict';

import { find } from 'UTILS/find';
import { removeNode } from 'UTILS/removeNode';

function removeLoaderWrapper() {
    const locker = find('.js_page_locker')[0];
    locker ? removeNode(locker) : '';
}

export { removeLoaderWrapper };
