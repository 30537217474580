'use strict';

(function (e) {
    e.getDataset = e.getDataset || function () {
        try {
            return JSON.parse(JSON.stringify(this.dataset));
        } catch (e) {
            console.warn('Cannot get element dataset');
            return {};
        }
    };
})(Element.prototype);
