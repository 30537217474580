'use strict';

import { find } from 'UTILS/find';
import { removeErrorLabel } from 'UTILS/removeErrorLabel';

/**
 * @param {Element} node
 * */
function prepareRadio(node) {
    const container = node.closest('.js-radio-group');

    if (!container) return;

    const radio = find('input[type=radio]', container);
    for (let i = 0; i < radio.length; i++) {
        removeErrorLabel(radio[i]);
    }
}

export { prepareRadio };
