import { createStore } from 'redux-dynamic-modules';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { saveLastActionExtension } from 'UTILS/saveLastActionMiddleware';
import { getAsyncLoggedUserModule } from 'ADMIN_COMMON/asyncReducerModules/getAsyncLoggedUserModule';
import { getAsyncNotificationsModule } from 'ADMIN_COMMON/asyncReducerModules/getAsyncNotificationsModule';
import { getAsyncConfigsModule } from 'ADMIN_COMMON/asyncReducerModules/getAsyncConfigsModule';

const getThunkExtension = () => ({ middleware: [thunk] });

export const createAsyncReduxStore = (
    isSaveLastAction = false,
    defaultModules = [
        getAsyncLoggedUserModule,
        getAsyncNotificationsModule,
        getAsyncConfigsModule,
    ]
) => {
    const store = createStore(
        {
            enhancements: [composeWithDevTools],
            extensions: [
                getThunkExtension(),
                ...(isSaveLastAction ? [saveLastActionExtension()] : []),
            ],
        },
        defaultModules.map((module) => module?.())
    );

    window.page.dispatch = store.dispatch;
    return store;
};
