import * as T from '../constants/types';
import axios from 'COMMON/axios';
import { SUCCESS, ERROR } from 'ADMIN_COMMON/constants/types';
import { requestAccidents } from 'ADMIN_ACCIDENTS/ac/requestAccidents';

export const postAccidentDuplicates =
    (duplicateAccidents) => async (dispatch) => {
        window.page.showSpinner();
        await axios.put('/accidents/duplicates', { duplicateAccidents });
        await dispatch(requestAccidents());
        try {
            dispatch({
                type: T.POST_ACCIDENT_DUPLICATES + SUCCESS,
                payload: {},
            });
        } catch (error) {
            dispatch({ type: T.POST_ACCIDENT_DUPLICATES + ERROR });
        }
        window.page.hideSpinner();
    };
