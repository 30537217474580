import { requestAccidents } from 'ADMIN_ACCIDENTS/ac/requestAccidents';
import {
    deleteAccidentImages,
    postAccidentImages,
} from 'ADMIN_ACCIDENTS/utils/accidentImageRequests';
import { ERROR, SUCCESS } from 'ADMIN_COMMON/constants/types';
import axios from 'COMMON/axios';
import * as T from '../constants/types';

export const updateAccident =
    ({ files = [], filesToDelete = [], ...updatedAccident }) =>
    async (dispatch) => {
        window.page.showSpinner();
        await axios.put('/accidents', { accident: updatedAccident });
        if (files.length) await postAccidentImages(files, updatedAccident.id);
        if (filesToDelete.length)
            await deleteAccidentImages(filesToDelete, updatedAccident.id);
        await dispatch(requestAccidents());
        try {
            dispatch({ type: T.UPDATE_ACCIDENT + SUCCESS });
        } catch (error) {
            dispatch({ type: T.UPDATE_ACCIDENT + ERROR });
        }
        window.page.hideSpinner();
    };
