import { WEBAPP_CONFIGS } from 'ADMIN_COMMON/constants/localStorageKeys';
import * as T from 'ADMIN_COMMON/constants/types';
import axios from 'COMMON/axios';
import {
    getLocalStorageItem,
    saveLocalStorageItem,
} from 'UTILS/localStorageHelpers';
import { processAllSettledPromise } from 'UTILS/processAllSettledPromise';
import { standardError } from 'UTILS/standardError';
import isEmpty from 'lodash/isEmpty';

const requestInfo = {
    messages: {
        requestUrl: '/configs/webapp/messages',

        processCallback: (data) => {
            const config = {};
            Object.entries(data).forEach(
                ([key, { code, value }]) => (config[key] = { code, value })
            );
            return config;
        },
    },

    application: {
        requestUrl: '/configs/webapp/application',

        processCallback: (data) => {
            const config = {};
            Object.entries(data).forEach(
                ([key, { value }]) => (config[key] = value)
            );
            return config;
        },
    },

    currency: {
        requestUrl: '/configs/properties/currency',

        processCallback: (data) => {
            const { 'billing.currency.numbers': currencyObject } = data;

            const config = Object.keys(currencyObject).map((code) => ({
                code,
                number: currencyObject[code],
            }));
            return config;
        },
    },

    region: {
        requestUrl: '/configs/properties/region',

        processCallback: (data) => {
            const { maps, 'billing.supplier': rbsTypes } = data;

            return {
                maps,
                rbsTypes,
            };
        },
    },

    subscription: {
        requestUrl: '/configs/properties/subscription',
        processCallback: (data) => ({
            availableSubscriptionPeriods:
                data['available.subscription.periods'],
            availableSubscriptionTrialPeriods:
                data['available.subscription.trial.periods'],
        }),
    },
};

const requestInfoKey = Object.keys(requestInfo);

const requestWebappConfigsSuccess = (payload) => ({
    type: T.REQUEST_WEBAPP_CONFIGS + T.SUCCESS,
    payload,
});

export const requestWebappConfigs = () => async (dispatch) => {
    try {
        const savedConfig = getLocalStorageItem(WEBAPP_CONFIGS);

        if (
            !isEmpty(savedConfig) &&
            savedConfig.messages &&
            savedConfig.application &&
            savedConfig.menuItems &&
            savedConfig.currency &&
            savedConfig.region &&
            savedConfig.subscription
        ) {
            return dispatch(requestWebappConfigsSuccess(savedConfig));
        }

        const response = await Promise.allSettled(
            requestInfoKey.map((key) => {
                const { requestUrl } = requestInfo[key];
                return axios.get(requestUrl);
            })
        );
        const configs = {};
        processAllSettledPromise(response).forEach(({ data: { config } }) => {
            if (!config)
                return standardError({ message: 'Не удалось получить конфиг' });
            const { name, params } = config;
            const { label, processCallback } = requestInfo[name];
            configs[label || name] = processCallback
                ? processCallback(params)
                : params;
        });
        dispatch(requestWebappConfigsSuccess(configs));
        saveLocalStorageItem(WEBAPP_CONFIGS, configs);
    } catch (err) {
        dispatch({ type: T.REQUEST_WEBAPP_CONFIGS + T.ERROR });
    }
};
