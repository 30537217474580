'use strict';

import { isDomNode } from 'UTILS/isDomNode';
import { isNodeList } from 'UTILS/isNodeList';

/**
 * @param {(Array|Element)} child
 * @param {Element} parent
 * @param {Element=} beforeThis
 * @returns {(Array|Element)} child
 * */
function insertNode(child, parent, beforeThis) {
    if (!child || !parent || !isDomNode(parent)) return null;
    if (isNodeList(child)) {
        const result = [];
        const childElements = [...Array.from(child)];
        while (childElements.length) {
            const node = childElements[0];
            if (isDomNode(node))
                result.push(
                    parent.insertBefore(node, beforeThis ? beforeThis : null)
                );
            if (~childElements.indexOf(node))
                childElements.splice(childElements.indexOf(node), 1);
        }
        if (!result.length) return null;
        if (result.length === 1) return result[0];
        return result;
    }
    if (!isDomNode(child)) return null;
    return parent.insertBefore(
        child,
        beforeThis && isDomNode(beforeThis) ? beforeThis : null
    );
}

export { insertNode };
