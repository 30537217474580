'use strict';

import { createDiv } from 'UTILS/createDiv';
import { isDomNode } from 'UTILS/isDomNode';
import { isNodeList } from 'UTILS/isNodeList';

/**
 * @param {(string|Element|NodeList|HTMLCollection|Array)} source
 * @returns {Array}
 * */
function getSearchContext(source) {
    if (!source) return [document.body];
    if (typeof source === 'string') {
        const div = createDiv();
        div.innerHTML = source;
        return [div];
    }
    if (isDomNode(source)) {
        return [source];
    }
    if (isNodeList(source)) {
        const result = [];
        for (let i = 0; i < source.length; i++) {
            if (isDomNode(source[i])) result.push(source[i]);
        }
        return result;
    }

    return [document.body];
}

export { getSearchContext };
