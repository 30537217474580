import React from 'react';

const Spinner = () => (
    <div className="spinner-wrap h-100 w-100">
        <div className="spinner">
            <div className="spinner-tail" />
        </div>
    </div>
);

export default Spinner;
