import React, { Fragment } from 'react';
import { useSession } from './SessionContext';

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} children
 * @property {import('../core/UserPermission').UserPermission} permission
 * @property {React.ReactNode} [fallback]
 */

/**
 * @type {React.FC<Props>}
 */
export const ProtectedView = ({ children, permission, fallback = null }) => {
    const session = useSession();

    if (session.hasPermission(permission)) {
        return <Fragment>{children}</Fragment>;
    }

    return fallback;
};
