import { t } from '@lingui/macro';
import { Center, Stack, Text, Title } from '@mantine/core';
import { env } from 'ADMIN_COMMON/constants/enviroments';
import React from 'react';

/**
 * @typedef {Object} Props
 * @property {ReactNode} [title]
 * @property {ReactNode} [children]
 */

/**
 * @type {React.FC<Props>}
 */
export const ForbiddenPage = ({
    title = t`У вас нет доступа к этой странице`,
    children,
}) => (
    <Center mih="100dvh">
        <Stack spacing="xl" align="center">
            <Text size={36}>{'❌'}</Text>

            <Title>{title}</Title>

            {children}

            <Text span={true} color="dimmed">
                {t`Если вы считаете это ошибкой`}
                {', '}
                <Text
                    component="a"
                    href={`mailto:${env.SUPPORT_EMAIL}`}
                    color="dimmed"
                    underline={true}
                    transform="lowercase"
                >
                    {t`Обратитесь в поддержку`}
                </Text>
            </Text>
        </Stack>
    </Center>
);
