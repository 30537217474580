import { useEffect, useState } from 'react';

/**
 * @param {import('./Subscribable').Subscribable} sub
 */
export function useObserver(sub) {
    const [value, setValue] = useState(sub.value);

    useEffect(() => sub.subscribe(setValue));

    return value;
}
