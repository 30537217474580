'use strict';

/**
 * @param {string} dateString
 * @returns {Date}
 * */
function parseStandardDate(dateString) {
    if (!dateString) return null;

    const date = new Date(
        +dateString.split('.')[2],
        +dateString.split('.')[1] - 1,
        +dateString.split('.')[0]
    );
    if (date.getTime()) {
        date.clearTime();
        return date;
    }
}

export { parseStandardDate };
