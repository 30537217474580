import * as COMMON_TYPES from 'ADMIN_COMMON/constants/types';

const initialState = {
    messages: {},
    menuItems: [],
    application: {},
    currency: [],
    adminConfiguration: {},
    subscription: {},
};

export const configs = (state = initialState, { type, payload }) => {
    switch (type) {
        case COMMON_TYPES.REQUEST_WEBAPP_CONFIGS + COMMON_TYPES.SUCCESS:
        case COMMON_TYPES.REQUEST_ADMIN_CONFIGURATION:
            return { ...state, ...payload };
        default:
            return state;
    }
};
