import React from 'react';

import { LazySubscriptionContainer } from './Container';
import Provider from 'react-redux/es/components/Provider';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';

const PageContent = withWrappedPage(LazySubscriptionContainer);

export const PageSubscription = () => (
    <Provider store={createAsyncReduxStore()}>
        <PageContent />
    </Provider>
);
