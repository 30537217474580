'use strict';

import { isDomNode } from 'UTILS/isDomNode';
import { isNodeList } from 'UTILS/isNodeList';

/**
 * @param {(Array|Element)} node
 * @returns {(Array|Element)}
 * */
function removeNode(node) {
    if (!node) return null;
    if (isNodeList(node)) {
        for (let i = 0; i < node.length; i++) {
            if (!node[i].parentNode) {
                node[i] = null;
            } else if (isDomNode(node[i])) {
                node[i].parentNode.removeChild(node[i]);
            } else node[i] = null;
        }
        return Array.from(node);
    }
    if (isDomNode(node) && node.parentNode)
        return node.parentNode.removeChild(node);
    return (node = null);
}

export { removeNode };
