import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { t } from '@lingui/macro';
import { LazySSContainer } from 'ADMIN_SS/components/Container';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import 'ADMIN_SS/components/steal-suspicion.scss';

const PageContent = withWrappedPage(LazySSContainer);

export const PageStealSuspicion = () => (
    <Provider store={createAsyncReduxStore(true)}>
        <Helmet>
            <title>{t`Сигналы Steal Suspicion`}</title>
        </Helmet>
        <div className="container-fluid modern-page">
            <div className="row">
                <main role="main" className="px-4 w-100">
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_ss_react"></div>

                    <PageContent />
                </main>
            </div>
        </div>
    </Provider>
);
