'use strict';

import { showSpinner } from 'UTILS/showSpinner';

function sessionExpired() {
    showSpinner();
    setTimeout(() => (window.location.href = window.location.href), 150);
}

export { sessionExpired };
