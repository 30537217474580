'use strict';

import { prepareInput } from 'UTILS/prepareInput';
import { prepareRadio } from 'UTILS/prepareRadio';

/**
 * @param {Element} node
 * */
function prepareForValidation(node) {
    return node.type === 'radio' ? prepareRadio(node) : prepareInput(node);
}

export { prepareForValidation };
