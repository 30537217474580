'use strict';

/**
 * @returns {Boolean}
 * */
function isNodeList(list) {
    const string = Object.prototype.toString.call(list);
    return (
        Array.isArray(list) ||
        (typeof list === 'object' &&
            /^\[object (HTMLCollection|NodeList|Object)\]$/.test(string) &&
            typeof list.length === 'number' &&
            (list.length === 0 ||
                (typeof list[0] === 'object' && list[0].nodeType > 0)))
    );
}

export { isNodeList };
