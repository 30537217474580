import { useState, useEffect } from 'react';
import { LANG } from 'ADMIN_COMMON/constants/localStorageKeys';
import { defaultLocale, locales } from '../../../i18n';
import { getLocalStorageItem } from 'UTILS/localStorageHelpers';
import { useSelector } from 'react-redux';

const localesMapTable = {
    yandex: {
        url: 'https://api-maps.yandex.ru/2.1/?apikey=bb5af1fc-711f-40b9-916c-77609fc096df',
        langs: {
            [locales.en]: 'en_US',
            [locales.ru]: 'ru_RU',
        },
        langKey: 'lang',
    },
    google: {
        url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBW9nkksHTg57-suTETC1HqOt3kxpxRP4M&libraries=drawing,places,geocoding',
        langs: {
            [locales.en]: 'en',
            [locales.ru]: 'ru',
        },
        langKey: 'language',
    },
};

const getMapUrl = (mapType) => {
    if (!Object.keys(localesMapTable).includes(mapType)) {
        throw new Error(`Map type ${mapType} incorrect`);
    }

    const { langs, langKey, url } = localesMapTable[mapType];
    const lang = getLocalStorageItem(LANG) ?? defaultLocale;

    const langValue = langs[lang];
    const langParams = [langKey, langValue].join('=');

    return [url, langParams].join('&');
};

export const useMapsConnections = () => {
    const mapProvider = useSelector(
        ({ configs: { adminConfiguration } }) => adminConfiguration.mapProvider
    );

    const [mapsCreated, setMapsCreated] = useState(false);
    const [mapsLoadingError, setMapsLoadingError] = useState(false);

    const isMap = (mapId) => document.getElementById(mapId) !== null;

    const connectMap = ({
        mapUrl,
        mapId,
        loadedCallback,
        errorLoadingCallback,
    }) => {
        const script = document.createElement('script');

        script.setAttribute('src', mapUrl);
        script.setAttribute('id', mapId);

        document.body.appendChild(script);

        script.onload = () => {
            loadedCallback();
        };

        script.onerror = () => {
            errorLoadingCallback();
        };
    };

    const googleMapsId = 'googleMaps';
    const yandexMapsId = 'yandexMaps';

    useEffect(() => {
        if (mapProvider === 'YANDEX') {
            if (isMap(googleMapsId)) {
                document.getElementById(googleMapsId).remove();
            }

            const isYandexMap = window?.ymaps;

            if (isYandexMap) {
                setMapsCreated(true);
            }

            if (!isMap(yandexMapsId) && !isYandexMap) {
                connectMap({
                    mapUrl: getMapUrl('yandex'),
                    mapId: yandexMapsId,
                    loadedCallback: () => {
                        setMapsCreated(true);
                        setMapsLoadingError(false);
                    },
                    errorLoadingCallback: () => {
                        setMapsCreated(false);
                        setMapsLoadingError(true);
                    },
                });
            }
        }

        if (mapProvider === 'GOOGLE') {
            if (isMap(yandexMapsId)) {
                document.getElementById(yandexMapsId).remove();
            }

            const isGoogleMap = window?.google?.maps;

            if (isGoogleMap) {
                setMapsCreated(true);
            }

            if (!isMap(googleMapsId) && !isGoogleMap) {
                connectMap({
                    mapUrl: getMapUrl('google'),
                    mapId: googleMapsId,
                    loadedCallback: () => {
                        setMapsCreated(true);
                        setMapsLoadingError(false);
                    },
                    errorLoadingCallback: () => {
                        setMapsCreated(false);
                        setMapsLoadingError(true);
                    },
                });
            }
        }

        return () => {
            if (isMap(googleMapsId)) {
                document.getElementById(googleMapsId).remove();
            }

            if (isMap(yandexMapsId)) {
                document.getElementById(yandexMapsId).remove();
            }
        };
    }, [mapProvider]);

    return { mapsCreated, mapsLoadingError };
};
