import { convertToISOString } from 'UTILS/convertToISOString';

const getFullDateTime = (
    dateString,
    addSeconds = false,
    removeTimezone = false
) => {
    if (!dateString || dateString === '-') return '';
    if (removeTimezone) dateString = convertToISOString(dateString);
    const seconds = addSeconds ? `:${dateString.substring(17, 19)}` : '';
    return `${dateString.substring(8, 10)}-${dateString.substring(
        5,
        7
    )}-${dateString.substring(0, 4)} ${dateString.substring(
        11,
        13
    )}:${dateString.substring(14, 16)}${seconds}`;
};

export { getFullDateTime };
