'use strict';

import state from 'COMMON/State';
import { addClass } from 'UTILS/addClass';
import { appendNode } from 'UTILS/appendNode';
import { createNode } from 'UTILS/createNode';
import { find } from 'UTILS/find';
import { isDomNode } from 'UTILS/isDomNode';

/**
 * @param {(Element|string)} target
 * */
function showSpinner(target = document.body) {
    function getSpinner(box, parentWidth, parentHeight) {
        return `<div class="js_spinner spinner-${
            box ? 'box' : 'wrap'
        }" style="${parentWidth ? `width:${parentWidth}px;` : ''}${
            parentHeight ? `height:${parentHeight}px;` : ''
        }">
                    <div class="spinner">
                        <div class="spinner-tail"></div>
                    </div>
                </div>`;
    }

    target = isDomNode(target)
        ? target
        : typeof target === 'string'
        ? find(target, document.body)[0] || document.body
        : document.body;

    if (find('.js_spinner', target)[0]) return;

    const spinner = createNode(
        getSpinner(
            target !== document.body,
            target.getBoundingClientRect().width,
            target.getBoundingClientRect().height
        )
    );

    appendNode(spinner, target);
    addClass('fadeout_enable', target);
    state.activeRequest = true;
}

export { showSpinner };
