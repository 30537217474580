import * as T from 'ADMIN_COMMON/constants/types';
import axios from 'COMMON/axios';

const fallbackConfiguration = {
    adminConfiguration: {
        mapProvider: 'YANDEX',
    },
};

export const requestAdminConfiguration = () => async (dispatch) => {
    try {
        const { data } = await axios.get('/admin/configuration');
        dispatch({
            type: T.REQUEST_ADMIN_CONFIGURATION,
            payload: data,
        });
    } catch (err) {
        dispatch({
            type: T.REQUEST_ADMIN_CONFIGURATION,
            payload: fallbackConfiguration,
        });
    }
};
