'use strict';

import { parseStandardDate } from 'UTILS/parseStandardDate';

class State {
    constructor() {
        if (window.page && window.page.state) return window.page.state;
        this.mobile = document.body.hasClass('ots-device-mobile');
        this.tablet = document.body.hasClass('ots-device-tablet');
        this.desktop = document.body.hasClass('ots-device-normal');
        this.localeContainer = document.getElementById('locale_language');
        this.locale = this.localeContainer
            ? this.localeContainer.value || ''
            : '';
        this.pageName = document.head.dataset.page || '';
        this.private = State.checkPrivate();
        this.activeRequest = false;
        this._now = State.getNow();
    }

    get now() {
        return new Date(this._now.getTime());
    }

    get authenticated() {
        return document.body.dataset.authenticated === 'true';
    }

    get owner() {
        return document.body.dataset.owner;
    }

    static getNow() {
        const dateString = document.getElementById('now')
            ? document.getElementById('now').value
            : '';
        const date = parseStandardDate(dateString) || new Date();
        return date.clearTime();
    }
    static checkPrivate() {
        try {
            window.localStorage.privateBrowsingTest = null;
            return false;
        } catch (e) {
            return true;
        }
    }
}

const state = new State();
export default state;
