export const FIRST = 'FIRST';
export const PREVIOUS = 'PREVIOUS';
export const NEXT = 'NEXT';
export const LAST = 'LAST';

export const getOffsetOption = (
    offsetOption = FIRST,
    currentOffset = 0,
    offsetSize = 50
) => {
    if (offsetOption === FIRST) return { offset: 0, lastPage: false };
    if (offsetOption === PREVIOUS) {
        const difference = currentOffset - offsetSize;
        const offset = difference > 0 ? difference : 0;
        return { offset, lastPage: false };
    }
    if (offsetOption === NEXT)
        return { offset: currentOffset + offsetSize, lastPage: false };
    if (offsetOption === LAST) return { lastPage: true };
};

export const defaultOffsetPaginationData = {
    page: 1,
    offset: 0,
    lastPage: false,
    hasPrevious: false,
    hasNext: false,
};

export const transformOffsetResponse = (
    { offset, hasNext, hasPrevious },
    size
) => ({
    hasNext,
    hasPrevious,
    offset,
    page: offset / size + 1,
});
