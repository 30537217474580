export default [
    { value: 'NEW', label: 'New' },
    { value: 'STAND_BY', label: 'Stand by' },
    { value: 'IN_USE', label: 'In use' },
    { value: 'MAINTENANCE', label: 'Maintenance' },
    { value: 'STEAL_SUSPICION', label: 'Steal suspicion' },
    { value: 'LOST', label: 'Lost' },
    { value: 'DELETED', label: 'Deleted' },
    { value: 'NOT_READY', label: 'Not ready' },
    { value: 'CHARGE', label: 'Charge' },
    { value: 'FORWARDING', label: 'Forwarding' },
    { value: 'BOOKED', label: 'Booked' },
    { value: 'SLEEP', label: 'Sleep' },
    { value: 'CHANGE_BATTERY', label: 'Change battery' },
    { value: 'FROZEN', label: 'Frozen' },
];
