import React from 'react';
import { Helmet } from 'react-helmet';

import { LazyRegionsContainer } from './Container';
import Provider from 'react-redux/es/components/Provider';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';

const PageContent = withWrappedPage(LazyRegionsContainer);

export const PageRegions = () => (
    <Provider store={createAsyncReduxStore()}>
        <Helmet>
            <title>{t`Регионы`}</title>
        </Helmet>

        <div className="container-fluid">
            <div className="row">
                <main role="main">
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_regions_react"></div>

                    <PageContent />
                </main>
            </div>
        </div>
    </Provider>
);
