import React from 'react';
import SelectInput from 'ADMIN_COMMON/components/SelectInput';
import { Form } from 'react-bootstrap';

const DefaultModalSelect = ({
    value,
    onChange,
    options = [],
    label,
    id,
    children,
    invalidFeedback = '',
    isInvalid = false,
    disabled = false,
    className = '',
}) => (
    <div className={`form-group ${className}`}>
        {label && (
            <label htmlFor={id} className="form-label">
                {label}
            </label>
        )}
        <SelectInput
            value={value}
            id={id}
            onChange={onChange}
            options={options}
            isInvalid={isInvalid || Boolean(invalidFeedback)}
            disabled={disabled}
        >
            {children}
        </SelectInput>
        <Form.Control.Feedback type="invalid">
            {invalidFeedback}
        </Form.Control.Feedback>
    </div>
);

export default DefaultModalSelect;
