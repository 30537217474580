import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { t } from '@lingui/macro';
import { LazyDailyPassesContainer } from 'ADMIN_DAILY_PASSES/components/Container';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';

const PageContent = withWrappedPage(LazyDailyPassesContainer);

export const PageDailyPasses = () => (
    <Provider store={createAsyncReduxStore()}>
        <Helmet>
            <title>Daily Passes</title>
        </Helmet>
        <div className="container-fluid">
            <div className="row">
                <main role="main" className="w-100">
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_ss_react"></div>

                    <PageContent />
                </main>
            </div>
        </div>
    </Provider>
);
