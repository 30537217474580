import React, { useMemo } from 'react';
import Form from 'react-bootstrap/Form';

const processStringOptions = (options) =>
    options.map((value) => ({ value, label: value }));
const SelectInput = ({
    value,
    onChange,
    options,
    children, // children as additional options
    id = '',
    isInvalid,
    disabled = false,
    name,
}) => {
    const validOptions = useMemo(() => {
        if (!options) return [];
        if (typeof options[0] === 'string')
            return processStringOptions(options);
        return options;
    }, [options]);
    return (
        <Form.Control
            as="select"
            value={value}
            onChange={onChange}
            id={id}
            data-id={id}
            isInvalid={isInvalid}
            disabled={disabled}
            name={name}
        >
            {children}
            {validOptions.map(({ value, label, disabled = false }, idx) => (
                <option
                    value={value}
                    key={`${value}-${idx}`}
                    disabled={disabled}
                >
                    {label}
                </option>
            ))}
        </Form.Control>
    );
};

export default SelectInput;
