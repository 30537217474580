import { Hub } from '@aws-amplify/core';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { env } from 'ADMIN_COMMON/constants/enviroments';
import { keysToReset } from 'ADMIN_COMMON/constants/localStorageKeys';
import { ApiProvider } from 'COMMON/ApiProvider';
import { apiClient } from 'COMMON/apiClient';
import { removeLocalStorageItem } from 'UTILS/localStorageHelpers';
import moment from 'moment';
import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { App } from './App/App';
import { CognitoAuthAdapter } from './auth/cognito';
import { defaultLocale, dynamicActivate } from './i18n';
import { UserApi } from './admin/user/src/api/UserApi';

i18n.on('change', () => {
    if (i18n.locale) {
        moment.locale(i18n.locale);
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

Hub.listen('auth', async (data) => {
    switch (data.payload.event) {
        case 'signOut':
            keysToReset.forEach((key) => removeLocalStorageItem(key));
            break;
        default:
            break;
    }
});

const cognitoAuthAdapter = new CognitoAuthAdapter();

cognitoAuthAdapter
    .setup({
        userPoolId: env.userPoolId,
        userPoolWebClientId: env.userPoolWebClientId,
        region: env.region,
        authenticationFlowType: env.authenticationFlowType,
        endpoint: env.cognitoUrl,
    })
    .catch(console.error);

const userApi = new UserApi(apiClient);

const LocalizedApp = () => {
    useEffect(() => {
        dynamicActivate(defaultLocale);
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <I18nProvider i18n={i18n}>
                <ApiProvider client={apiClient}>
                    <App
                        cognitoAuthAdapter={cognitoAuthAdapter}
                        userApi={userApi}
                    />
                </ApiProvider>
            </I18nProvider>
        </QueryClientProvider>
    );
};
const rootElement = document.getElementById('root');

render(<LocalizedApp />, rootElement);
