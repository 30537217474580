import { withSuspense } from 'ADMIN_COMMON/components/HOC/withSuspense';
import React, { lazy } from 'react';
import { connect } from 'react-redux';

const AccidentYandexMap = lazy(() => import('./AccidentYandexMap'));
const AccidentGoogleMap = lazy(() => import('./AccidentGoogleMap'));

const AccidentModalMapComponent = ({ coordinate, mapProvider }) =>
    mapProvider === 'GOOGLE' ? (
        <AccidentGoogleMap coordinate={coordinate} />
    ) : (
        <AccidentYandexMap coordinate={coordinate} />
    );

const mapStateToProps = ({
    configs: {
        adminConfiguration: { mapProvider },
    },
}) => ({
    mapProvider,
});

export const AccidentModalMap = withSuspense(
    connect(mapStateToProps)(AccidentModalMapComponent)
);
