/**
 * @readonly
 * @enum {string}
 */
export const UserPermission = {
    AccidentStatusEdit: 'AccidentStatusEdit',
    Batteries: 'Batteries',
    BatteriesChargeLevel: 'BatteriesChargeLevel',
    CarEdit: 'CarEdit',
    DailyPass: 'DailyPass',
    DailyPassEdit: 'DailyPassEdit',
    DailyPassRefund: 'DailyPassRefund',
    Device: 'Device',
    DeviceChangeStatusToDeleted: 'DeviceChangeStatusToDeleted',
    DeviceChangeStatusToForwarding: 'DeviceChangeStatusToForwarding',
    DeviceChangeStatusToFrozen: 'DeviceChangeStatusToFrozen',
    DeviceChangeStatusToMaintenance: 'DeviceChangeStatusToMaintenance',
    DeviceChangeStatusToNew: 'DeviceChangeStatusToNew',
    DeviceDownload: 'DeviceDownload',
    DeviceEdit: 'DeviceEdit',
    DeviceLabels: 'DeviceLabels',
    DeviceMap: 'DeviceMap',
    DeviceMapProtectedLayers: 'DeviceMapProtectedLayers',
    DeviceMassActions: 'DeviceMassActions',
    Devices: 'Devices',
    ExternalFunctionalAreas: 'ExternalFunctionalAreas',
    FirmwareUpdate: 'FirmwareUpdate',
    Organizations: 'Organizations',
    ParkingEdit: 'ParkingsEdit',
    Partners: 'Partners',
    Penalties: 'Penalties',
    PenaltiesEdit: 'PenaltiesEdit',
    Promocodes: 'Promocodes',
    PromocodesEdit: 'PromocodesEdit',
    PromocodesSensitiveFilters: 'PromocodesSensitiveFilters',
    Regions: 'Regions',
    RegionsEdit: 'RegionsEdit',
    RegionsStatusEdit: 'RegionsStatusEdit',
    ServiceCars: 'ServiceCars',
    StealSuspicion: 'StealSuspicion',
    StealSuspicionMassActions: 'StealSuspicionMassActions',
    Subscriptions: 'Subscriptions',
    Tariffs: 'Tariffs',
    TariffsEdit: 'TariffsEdit',
    Terms: 'Terms',
    TripDangerActions: 'TripDangerActions',
    TripPenaltiesIssue: 'TripPenaltiesIssue',
    TripShorten: 'TripShorten',
    UserBlock: 'UserBlock',
    UserCreate: 'UserCreate',
    UserDelete: 'UserDelete',
    UserEditSensitive: 'UserEditSensitive',
    UserPolicyEdit: 'UserPolicyEdit',
    UserSubscriptionPause: 'UserSubscriptionPause',
    UserSubscriptionRefund: 'UserSubscriptionRefund',
    VirtualDevices: 'VirtualDevices',
    Warehouses: 'Warehouses',
    WarehouseEdit: 'WarehouseEdit',
    Works: 'Works',
    ZoneDownload: 'ZoneDownload',
    ZoneEdit: 'ZonesEdit',
};
