import React, { useCallback, useMemo, useState } from 'react';
import dropdownRenderer from './dropdownRenderer';
import Select from 'react-dropdown-select';
import styles from './MultiSelect.module.scss';
import classnames from 'classnames';
import { useSwitch } from 'ADMIN_COMMON/hooks/useSwitch';
import { MultiSelectSearchInput } from './MultiSelectSearchInput';

const MultiSelect = ({
    values,
    options,
    contentRenderer,
    onChange,
    error = null,
    disabled = false,
    className = '',
}) => {
    const [isMenuOpen, onMenuOpen, onMenuClose] = useSwitch(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [searchInputNode, setSearchInputNode] = useState(null);
    const onChangeSearchInput = ({ target: { value } }) =>
        setSearchInputValue(value);
    const resetSearchInputValue = () => setSearchInputValue('');
    const multiSelectContentRenderer = useCallback(
        isMenuOpen
            ? () => (
                  <MultiSelectSearchInput
                      value={searchInputValue}
                      onChange={onChangeSearchInput}
                      isMenuOpen={isMenuOpen}
                      resetSearchInputValue={resetSearchInputValue}
                      setSearchInputNode={setSearchInputNode}
                  />
              )
            : contentRenderer,
        [searchInputValue, isMenuOpen, setSearchInputNode, contentRenderer]
    );
    const filteredOptions = useMemo(
        () =>
            searchInputValue
                ? options.filter(({ label }) =>
                      label
                          .toLowerCase()
                          .includes(searchInputValue.toLowerCase())
                  )
                : options,
        [searchInputValue, options]
    );
    const onChangeMultiSelect = useCallback(
        (values) => {
            onChange && onChange(values);
            searchInputNode?.focus();
        },
        [searchInputNode, onChange]
    );
    return (
        <>
            <Select
                disabled={disabled}
                values={values}
                multi
                className={classnames(
                    'form-control',
                    {
                        'is-invalid': error,
                        [styles.disabledMultiselect]: disabled,
                    },
                    className
                )}
                options={filteredOptions}
                onChange={onChangeMultiSelect}
                contentRenderer={multiSelectContentRenderer}
                dropdownRenderer={dropdownRenderer}
                dropdownPosition="auto"
                onDropdownOpen={onMenuOpen}
                onDropdownClose={onMenuClose}
                backspaceDelete={false}
            />
            {error && <div className={styles.invalidFeedback}>{error}</div>}
        </>
    );
};

export default MultiSelect;
