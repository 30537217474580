'use strict';

import { find } from 'UTILS/find';
import { removeNode } from 'UTILS/removeNode';
import { removeErrorHelper } from 'UTILS/removeErrorHelper';
import { removeErrorLabel } from 'UTILS/removeErrorLabel';

/**
 * @param {Element} node
 * */
function prepareInput(node) {
    removeErrorLabel(node);
    removeErrorHelper(node);
    removeNode(find('.js_counter', node.parentNode));
}

export { prepareInput };
