export const replaceObjectInArray = (items, replaceItem, key = 'id') => {
    const itemIndex = items.findIndex((item) => replaceItem[key] === item[key]);
    return [
        ...items.slice(0, itemIndex),
        replaceItem,
        ...items.slice(itemIndex + 1),
    ];
};

export const removeObjectFromArray = (items, value, key = 'id') =>
    items.filter((item) => value !== item[key]);
