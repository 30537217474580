import axios from 'COMMON/axios';

export const postAccidentImages = async (images, id) => {
    const formData = new FormData();
    images.forEach((image) => formData.append('files', image));
    await axios.post(`/accidents/${id}/files`, formData);
};

export const deleteAccidentImages = async (files, id) => {
    await axios.delete(`/accidents/${id}/files`, { data: { files } });
};
