import * as T from '../constants/types';
import axios from 'COMMON/axios';
import { SUCCESS, ERROR } from 'ADMIN_COMMON/constants/types';
import { requestAccidents } from 'ADMIN_ACCIDENTS/ac/requestAccidents';

export const updateAccidentStatus =
    ({ id, status, comment, complaint = null }) =>
    async (dispatch) => {
        await axios.put(`/accidents/${id}/status`, {
            complaint,
            comment: comment || null,
            status,
        });
        await dispatch(requestAccidents());
        try {
            dispatch({ type: T.UPDATE_ACCIDENT_STATUS + SUCCESS });
        } catch (error) {
            dispatch({ type: T.UPDATE_ACCIDENT_STATUS + ERROR });
        }
    };
