import { defineMessage } from '@lingui/macro';

export const statuses = 'statuses';
export const firmwareVersions = 'firmwareVersions';
export const hardwareVersions = 'hardwareVersions';
export const bootVersions = 'bootVersions';
export const ecuVersions = 'ecuVersions';
export const bcuVersions = 'bcuVersions';
export const dashVersions = 'dashVersions';
export const models = 'models';
export const labels = 'labels';
export const organizations = 'organizations';
export const defaultCoordinates = { lat: 55.72991, lng: 37.638694 };
export const multiSelectOptions = [
    'VERIFIED',
    'MANUAL_ADDED',
    'SYSTEM_ADDED',
    'DEACTIVATED',
];
export const isAddParkingModalOpen = 'isAddParkingModalOpen';
export const isAddZoneModalOpen = 'isAddZoneModalOpen';
export const isEditParkingModalOpen = 'isEditParkingModalOpen';
export const isEditZoneModalOpen = 'isEditZoneModalOpen';
export const isUpdateCoordsModalOpen = 'isUpdateCoordsModalOpen';
export const isShowEditWarehouseModal = 'isShowEditWarehouseModal';
export const isShowNewCarModal = 'isShowNewCarModal';
export const isShowAddWarehouseModal = 'isShowAddWarehouseModal';
export const isShowEditCarOpen = 'isShowEditCarOpen';
export const isExternalFunctionalAreaModalOpen =
    'isExternalFunctionalAreaModalOpen';
export const mapActionKeys = [
    isAddParkingModalOpen,
    isAddZoneModalOpen,
    isEditParkingModalOpen,
    isEditZoneModalOpen,
    isUpdateCoordsModalOpen,
    isShowEditWarehouseModal,
    isShowNewCarModal,
    isShowAddWarehouseModal,
    isShowEditCarOpen,
    isExternalFunctionalAreaModalOpen,
];
export const NEW = 'NEW';
export const STAND_BY = 'STAND_BY';
export const MAINTENANCE = 'MAINTENANCE';
export const STEAL_SUSPICION = 'STEAL_SUSPICION';
export const LOST = 'LOST';
export const DELETED = 'DELETED';
export const NOT_READY = 'NOT_READY';
export const CHARGE = 'CHARGE';
export const FORWARDING = 'FORWARDING';
export const CHANGE_BATTERY = 'CHANGE_BATTERY';
export const FROZEN = 'FROZEN';
export const RESET = 'RESET';
export const WAREHOUSE = 'WAREHOUSE';
export const PARKING = 'PARKING';
export const ZONE = 'ZONE';
export const SELECT_RECTANGLE = 'SELECT_RECTANGLE';
export const resetButtonId = 'resetButton';
export const addWarehouseButtonId = 'addWarehouseButton';
export const addZoneButtonId = 'addZoneButton';
export const addParkingButtonId = 'addParkingButton';
export const selectRectangleButtonId = 'selectRectangleButton';
export const daysOfWeekOptions = [
    { id: 'MONDAY', label: 'ПН', fullDay: 'Понедельник' },
    { id: 'TUESDAY', label: 'ВТ', fullDay: 'Вторник' },
    { id: 'WEDNESDAY', label: 'СР', fullDay: 'Среда' },
    { id: 'THURSDAY', label: 'ЧТ', fullDay: 'Четверг' },
    { id: 'FRIDAY', label: 'ПТ', fullDay: 'Пятница' },
    { id: 'SATURDAY', label: 'СБ', fullDay: 'Суббота' },
    { id: 'SUNDAY', label: 'ВС', fullDay: 'Воскресенье' },
];
const defaultAreaScheduleStartTime = { hours: '00', minutes: '00' };
const defaultAreaScheduleEndTime = { hours: 23, minutes: 59 };
export const defaultAreaScheduleTime = {
    from: defaultAreaScheduleStartTime,
    to: defaultAreaScheduleEndTime,
};
export const defaultAreaSchedule = {
    MONDAY: { isSelected: true, timeslots: [defaultAreaScheduleTime] },
    TUESDAY: { isSelected: true, timeslots: [defaultAreaScheduleTime] },
    WEDNESDAY: { isSelected: true, timeslots: [defaultAreaScheduleTime] },
    THURSDAY: { isSelected: true, timeslots: [defaultAreaScheduleTime] },
    FRIDAY: { isSelected: true, timeslots: [defaultAreaScheduleTime] },
    SATURDAY: { isSelected: true, timeslots: [defaultAreaScheduleTime] },
    SUNDAY: { isSelected: true, timeslots: [defaultAreaScheduleTime] },
};
export const ACTIVE = 'ACTIVE';
export const ON_HOLD = 'ON_HOLD';
export const PAUSED = 'PAUSED';
export const REFUNDED = 'REFUNDED';
export const unblockUserFormId = 'unblockUserForm';
export const LOCK = 'LOCK';
export const UNLOCK = 'UNLOCK';
export const ENGAGE = 'ENGAGE';
export const DISENGAGE = 'DISENGAGE';
export const ALARM_ON = 'ALARM_ON';
export const ALARM_OFF = 'ALARM_OFF';
export const HEADLIGHT_ON = 'HEADLIGHT_ON';
export const HEADLIGHT_OFF = 'HEADLIGHT_OFF';
export const CRUISE_MODE_OFF = 'CRUISE_MODE_OFF';
export const ECO_MODE_ON = 'ECO_MODE_ON';
export const ECO_MODE_OFF = 'ECO_MODE_OFF';
export const accidentFormId = 'accidentFormId';
export const deviceCommentFormId = 'deviceCommentFormId';
export const EXTERNAL = 'EXTERNAL';
export const INACTIVE = 'INACTIVE';
export const FUNCTIONAL = 'FUNCTIONAL';
export const NO_LOCKING = 'NO_LOCKING';
export const FORBIDDEN = 'FORBIDDEN';
export const OPERATION = 'OPERATION';
export const PERMITTED = 'PERMITTED';
export const LIMITED = 'LIMITED';
export const SPEED_LIMIT = 'SPEED_LIMIT';
export const STOP_LIMIT = 'STOP_LIMIT';
export const REACTION_TESTING = 'REACTION_TESTING';
export const zoneTypes = [
    PERMITTED,
    LIMITED,
    FORBIDDEN,
    OPERATION,
    FUNCTIONAL,
    NO_LOCKING,
    REACTION_TESTING,
];
export const ECO = 'ECO';
export const DRIVE = 'DRIVE';
export const speedLimitTypes = [ECO, DRIVE];
export const STATUS = 'STATUS';
export const COMMAND = 'COMMAND';
export const LABELS = 'LABELS';
export const FIRMWARES = 'FIRMWARES';
export const COMMENTS_RESOLUTION = 'COMMENTS_RESOLUTION';
export const HELMET_PROCESSING = 'HELMET_PROCESSING';
export const ORGANIZATION = 'ORGANIZATION';
export const CUSTOM = 'CUSTOM';
export const IN_USE = 'IN_USE';
export const BOOKED = 'BOOKED';
export const roundedZoneData = {
    id: '',
    coordinatesToRound: [],
    isFullZoneRounding: false,
    isPartialZoneRounding: false,
    newCoordinates: [],
    originalCoordinates: [],
};
export const changeBordersButtons = [
    { id: 'submitBordersButton', label: 'Изменить зону', variant: 'success' },
    { id: 'resetBordersButton', label: 'Сброс', variant: 'danger' },
];
export const roundingButtons = [
    { id: 'submitRoundingBounds', label: 'Скруглить', variant: 'primary' },
    { id: 'resetRoundingButton', label: 'Отмена', variant: 'danger' },
];
export const editBorderButtonIds = [
    '#submitBordersButton',
    '#resetBordersButton',
];
export const roundingBorderButtonIds = [
    '#submitRoundingBounds',
    '#resetRoundingButton',
];
export const maxScheduleTimeslotsLength = 1; // TODO change to 3 after 1.86 release

export const statusesThatRequireComment = new Set([NOT_READY, FROZEN]);

export const otherFrozenReasonCode = 'device.reason.frozen.other';

defineMessage({
    id: 'weekday.short.monday',
    message: 'Пн',
});
defineMessage({
    id: 'weekday.short.tuesday',
    message: 'Вт',
});
defineMessage({
    id: 'weekday.short.wednesday',
    message: 'Ср',
});
defineMessage({
    id: 'weekday.short.thursday',
    message: 'Чт',
});
defineMessage({
    id: 'weekday.short.friday',
    message: 'Пт',
});
defineMessage({
    id: 'weekday.short.saturday',
    message: 'Сб',
});
defineMessage({
    id: 'weekday.short.sunday',
    message: 'Вс',
});
