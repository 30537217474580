import * as COMMON_TYPES from 'ADMIN_COMMON/constants/types';

const initialState = {};

export const loggedUser = (state = initialState, { type, payload }) => {
    switch (type) {
        case COMMON_TYPES.REQUEST_LOGGED_USER + COMMON_TYPES.SUCCESS: {
            return payload;
        }
        default:
            return state;
    }
};
