import React from 'react';
import { Helmet } from 'react-helmet';

import { LazyParkingPartnersContainer } from './Container';
import Provider from 'react-redux/es/components/Provider';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';

const PageContent = withWrappedPage(LazyParkingPartnersContainer);

export const PageParkingPartners = () => (
    <Provider store={createAsyncReduxStore()}>
        <Helmet>
            <title>{t`Список партнеров`}</title>
        </Helmet>

        <div className="container-fluid">
            <div className="row">
                <main role="main" style={{ width: '100%' }}>
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_parking_partners_react"></div>

                    <PageContent />
                </main>
            </div>
        </div>
    </Provider>
);
