import React, { createContext, useContext } from 'react';

const ApiContext = createContext(null);

export const ApiProvider = ({ client, children }) => (
    <ApiContext.Provider value={client}>{children}</ApiContext.Provider>
);

/**
 * @returns {import('axios').Axios}
 */
export const useApiClient = () => {
    const context = useContext(ApiContext);

    if (!context) {
        throw new Error('ApiContext: ApiProvider not in tree');
    }

    return context;
};
