'use strict';

/**
 * @param {function} callback
 * */
function documentReady(callback) {
    $(() => typeof callback === 'function' && callback());
}

export { documentReady };
