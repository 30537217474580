import { createStyles } from '@mantine/core';

export const useAlertStyles = createStyles((theme) => ({
    alert: {
        borderRadius: '8px 0px 0px 8px',
        overflow: 'unset',
        padding: 0,
        backgroundColor: theme.colors.dark[9],
        position: 'fixed',
        bottom: '24px',
        right: 0,
        zIndex: 5,
    },
    collapseButton: {
        borderRadius: '8px 0px 0px 8px',
        borderRight: `1px solid ${theme.colors.dark[4]}`,
        backgroundColor: theme.colors.dark[6],
        padding: '16px',
        ':hover': {
            cursor: 'pointer',
        },
    },
    button: {
        backgroundColor: '#f8d867',
        color: '#090a0e',
        '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.lighten('#f8d867', 0.2),
        }),
        ':hover': {
            color: '#090a0e',
        },
    },
    chevron: {
        color: 'white',
        margin: '0 auto',
        textAlign: 'center',
    },
    icon: {
        width: '32px',
        height: '32px',
        padding: '6px 0',
        backgroundColor: '#F8D867',
        borderRadius: '16px',
        position: 'absolute',
        top: '-16px',
        left: '-16px',
        textAlign: 'center',
    },
}));
