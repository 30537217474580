import { withSuspense } from 'ADMIN_COMMON/components/HOC/withSuspense';
import React, { lazy } from 'react';
import { LazyComponentsWrapper } from 'ADMIN_COMMON/components/LazyComponentsWrapper';

const LazyImageModal = lazy(() => import('./Image'));
const WrappedModal = (props) => (
    <LazyComponentsWrapper show={props.show}>
        <LazyImageModal {...props} />
    </LazyComponentsWrapper>
);

export const Image = withSuspense(WrappedModal);
