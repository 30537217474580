const arrayToCoordinates = (arrayCoords) => {
    const [lat, lng] = arrayCoords;
    return { lat, lng };
};

export const processAccidents = (
    { region, deviceCode, paymentId, assignee, ...accident },
    coordinate
) => {
    const processAccidents = {
        ...accident,
        region: { id: region },
        device: deviceCode ? { code: deviceCode } : null,
        trip: paymentId ? { paymentId } : null,
        user:
            assignee.length && assignee[0].value
                ? { id: assignee[0].value }
                : null,
        coordinate: arrayToCoordinates(coordinate),
    };
    delete processAccidents.enteredAddress;
    return processAccidents;
};
