import * as T from 'ADMIN_COMMON/constants/types';
import { requestLoggedUser } from 'UTILS/loggedUserUtils';

export const requestLoggedUserInfo = () => async (dispatch) => {
    const loggedUser = await requestLoggedUser();
    if (!loggedUser) return dispatch({ type: T.REQUEST_LOGGED_USER + T.ERROR });
    const { organizations = [] } = loggedUser;
    const roles = loggedUser.roles?.split(':') || [];
    const user = {
        ...loggedUser,
        roles,
        organizations: organizations.map(({ id, name }) => ({ id, name })),
        isPrimaryUser: organizations.some(({ primary }) => primary),
        isAdmin: roles.includes('ADMIN'),
    };
    dispatch({ type: T.REQUEST_LOGGED_USER + T.SUCCESS, payload: user });
};
