export const REQUEST_ACCIDENTS = 'REQUEST_ACCIDENTS';
export const POST_ACCIDENT = 'POST_ACCIDENT';
export const CHANGE_ACCIDENT_PAGE = 'CHANGE_ACCIDENT_PAGE';
export const UPDATE_ACCIDENT = 'UPDATE_ACCIDENT';
export const UPDATE_ACCIDENT_STATUS = 'UPDATE_ACCIDENT_STATUS';
export const POST_ACCIDENT_DUPLICATES = 'POST_ACCIDENT_DUPLICATES';
export const REQUEST_ACCIDENT_ASSIGNEES = 'REQUEST_ACCIDENT_ASSIGNEES';
export const RESET_ACCIDENT_FILTERS = 'RESET_ACCIDENT_FILTERS';
export const ALL_LOCATIONS_OPTION = 'ALL_LOCATIONS_OPTION';
export const GET_ASSIGNEES_FILTER = 'GET_ASSIGNEES_FILTER';
export const REQUEST_ACCIDENT_TYPES = 'REQUEST_ACCIDENT_TYPES';
