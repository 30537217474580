import { onSignIn } from '../ac/onSignIn';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export const useLogin = ({ redirectUri }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const error = useSelector((state) => state.login.error);
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const [hasError, setHasError] = useState(error);
    const closeError = useCallback(() => setHasError(false), []);
    const [form, setForm] = useState({
        name: '',
        password: '',
    });
    const handleChange = (key) => (evt) => {
        setForm((prevState) => ({
            ...prevState,
            [key]: evt.target.value,
        }));
    };
    const handleSubmit = async (evt) => {
        evt.preventDefault();
        dispatch(
            onSignIn({
                login: form.name,
                password: form.password,
            })
        );
    };
    useEffect(() => {
        if (error) {
            setHasError(true);
        }
    }, [error]);
    useEffect(() => {
        if (isLoggedIn) {
            navigate(redirectUri || '/home');
        }
    }, [isLoggedIn]);

    return {
        handleSubmit,
        handleChange,
        form,
        hasError,
        closeError,
        error,
    };
};
