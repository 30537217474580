'use strict';

import { find } from 'UTILS/find';
import { removeNode } from 'UTILS/removeNode';
import { documentReady } from 'UTILS/documentReady';
import {
    saveLocalStorageItem,
    getSSNotificationsStatus,
} from 'UTILS/localStorageHelpers';
import { toggleClass } from 'UTILS/toggleClass';
import { requestLoggedUser } from 'UTILS/loggedUserUtils';
import * as SockJS from 'sockjs-client';
import axios from 'COMMON/axios';
import * as V from 'ADMIN_COMMON/constants/variables';
import { SS_NOTIFICATION_DISABLED } from 'ADMIN_COMMON/constants/localStorageKeys';
import { env } from 'ADMIN_COMMON/constants/enviroments';
import { t } from '@lingui/macro';
// TODO move to new notifications block and remove http request after 1.80
let blockRequest = false;

export class StealSuspicionNotifications {
    constructor({
        receiveSsNotificationsUsingWebSocketEnabled,
        freezeRequestIsActive,
    }) {
        documentReady(() => {
            this.freezeRequestIsActive = freezeRequestIsActive;
            this.receiveSsNotificationsUsingWebSocketEnabled;
            this.showedNotification = [];
            this.addMargin = false;
            this.disconnectFromStompClient = null;
            const webSocketEnabled =
                receiveSsNotificationsUsingWebSocketEnabled;
            webSocketEnabled ? this.initSocket() : this.initRequests();
            this.webSocketEnabled = webSocketEnabled;
            this.eventListeners();
        });
    }

    async initSocket() {
        if (getSSNotificationsStatus()) return;
        this.requestNotificationsByHttp();
        const sockjs = new SockJS(
            `${env.baseURL.slice(0, -3)}/whoosh-websocket`
        );
        const stompClient = Stomp.over(sockjs);
        stompClient.reconnect_delay = 5000;
        stompClient.connect({}, () => {
            const { unsubscribe } = stompClient.subscribe(
                '/topic/ss-notifications',
                (devicesResponse) => {
                    if (getSSNotificationsStatus()) {
                        StealSuspicionNotifications.removeAllNotifications();
                        StealSuspicionNotifications.stopSound();
                        return;
                    }
                    const { device, notificationStatus } = JSON.parse(
                        devicesResponse.body
                    );
                    if (device && notificationStatus === V.ACTIVE)
                        this.pushNewStealSuspicion(device);
                    if (device && notificationStatus === V.DELETED)
                        this.removeNodeWithoutRequest(device.code);
                }
            );
            this.disconnectFromStompClient = () => {
                unsubscribe();
                stompClient.disconnect();
            };
        });
    }

    removeNodeWithoutRequest(code) {
        blockRequest = true;
        const node = find(
            `.js_device_notification[data-notification-id="${code}"]`
        )[0];
        StealSuspicionNotifications.closetNotificationByNode(node);
    }

    pushNewStealSuspicion(device) {
        this.showedNotification.push(device.code);
        StealSuspicionNotifications.showNotification(device);
        StealSuspicionNotifications.playSound();
    }

    initRequests() {
        if (!getSSNotificationsStatus()) this.checkNotifications();
        setInterval(() => {
            if (getSSNotificationsStatus()) {
                StealSuspicionNotifications.removeAllNotifications();
                StealSuspicionNotifications.stopSound();
            }
            if (!getSSNotificationsStatus()) this.checkNotifications();
        }, 5000);
    }

    static removeAllNotifications() {
        removeNode(find('div[data-notify="container"]'));
    }

    async checkNotifications() {
        const loggedUser = await requestLoggedUser();
        if (
            this.freezeRequestIsActive === 'false' ||
            (this.freezeRequestIsActive === 'true' && !document.hidden) ||
            loggedUser?.roles?.includes('operator')
        )
            this.requestNotificationsByHttp();
    }

    async requestNotificationsByHttp() {
        const {
            data: { notifications = [] },
        } = await axios.get('/devices/notifications');
        this.processDevices(notifications.map(({ device }) => device));
    }

    processDevices(devices) {
        const deviceIds = [];

        for (let i = 0; i < devices.length; i++) {
            deviceIds.push(devices[i].code);
            if (
                this.showedNotification.find((code) => code === devices[i].code)
            )
                continue;
            this.pushNewStealSuspicion(devices[i]);
            if (this.addMargin)
                toggleClass(
                    'alert-with-margin',
                    find(
                        `.js_device_notification[data-notification-id="${this.showedNotification[i]}"]`
                    )[0].closest('div')
                );
        }

        const elementsToRemove = [];
        for (let i = 0; i < this.showedNotification.length; i++) {
            if (
                !deviceIds.find((code) => code === this.showedNotification[i])
            ) {
                elementsToRemove.push(this.showedNotification[i]);
                StealSuspicionNotifications.closetNotificationByNode(
                    find(
                        `.js_device_notification[data-notification-id="${this.showedNotification[i]}"]`
                    )[0]
                );
            }
        }

        this.showedNotification = this.showedNotification.filter(
            (code) => !elementsToRemove.find((_code) => _code === code)
        );

        StealSuspicionNotifications.playSound();
    }

    static closetNotificationByNode(node) {
        if (!node) return;

        const container = node.closest('div');
        const button = find('button', container)[0];

        button.click();
    }

    static closeNotificationAfterClickLink(event) {
        const container = event.target.closest('div');
        const button = find('button', container)[0];

        button.click();
    }

    static notifcationsExist() {
        return find('.js_device_notification')[0];
    }

    static playSound() {
        if (!StealSuspicionNotifications.notifcationsExist()) return;
        setTimeout(() => {
            find('#stealSuspicionAlarm')[0].play();
        }, 500);
    }

    static stopSound() {
        if (StealSuspicionNotifications.notifcationsExist()) return;
        find('#stealSuspicionAlarm')[0].pause();
    }

    stealSuspicionNotificationsOff = () => {
        saveLocalStorageItem(SS_NOTIFICATION_DISABLED, true);
        StealSuspicionNotifications.removeAllNotifications();
        StealSuspicionNotifications.stopSound();
        if (this.webSocketEnabled && this.disconnectFromStompClient)
            this.disconnectFromStompClient();
    };

    stealSuspicionNotificationsOn = () => {
        saveLocalStorageItem(SS_NOTIFICATION_DISABLED, false);
        this.addMargin = true;
        this.webSocketEnabled ? this.initSocket() : this.checkNotifications();
    };

    eventListeners() {
        $(document.body)
            .on('click', 'a[data-notify]', (event) =>
                StealSuspicionNotifications.closeNotificationAfterClickLink(
                    event
                )
            )
            .on('hide.bs.dropdown', '#currentUserDropdown', () => {
                this.addMargin = false;
                toggleClass(
                    'alert-with-margin',
                    find('div[data-notify="container"]')
                );
            })
            .on('show.bs.dropdown', '#currentUserDropdown', () => {
                this.addMargin = true;
                toggleClass(
                    'alert-with-margin',
                    find('div[data-notify="container"]')
                );
            });
    }

    static closeNotification() {
        const deviceCode = find('.js_device_notification', this[0])[0].dataset
            .notificationId;
        if (!blockRequest)
            axios.delete(`/ss-devices/${deviceCode}/notification`);
        blockRequest = false;
    }

    static showNotification(device) {
        $.notify(
            {
                // options
                title: `<strong class="js_device_notification" data-notification-id="${device.code}">${device.code}</strong>`,
                message: `<br>${
                    device.displayedStatus === V.LOST
                        ? 'LOST ONLINE'
                        : t`Причина` +
                          ` ${device?.displayedStatus}: ${device.stealSuspicionReason}`
                }`,
                url: `/devices/description/${device.id}`,
                target: '_blank',
                icon: 'glyphicon glyphicon-search',
            },
            {
                // settings
                element: 'body',
                position: null,
                type: device.displayedStatus === V.LOST ? 'warning' : 'danger',
                allow_dismiss: true,
                newest_on_top: false,
                showProgressbar: false,
                placement: {
                    from: 'top',
                    align: 'right',
                },
                offset: 50,
                spacing: 10,
                z_index: 1031,
                delay: 0,
                url_target: '_blank',
                mouse_over: null,
                animate: {
                    enter: 'animated fadeInRight',
                    exit: 'animated fadeOutRight',
                },
                onShow: null,
                onShown: null,
                onClose: StealSuspicionNotifications.closeNotification,
                onClosed: StealSuspicionNotifications.stopSound,
                icon_type: 'class',
            }
        );
    }
}
