import React from 'react';
import { Helmet } from 'react-helmet';
import { LazyDeviceContainer } from './Container';
import Provider from 'react-redux/es/components/Provider';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';

const PageContent = withWrappedPage(LazyDeviceContainer);

export const PageDevice = () => (
    <Provider store={createAsyncReduxStore(true)}>
        <Helmet>
            <title>{t`Устройство`}</title>
        </Helmet>

        <div className="container-fluid js_main_container modern-page">
            <div className="row">
                <main role="main" className="px-4 w-100">
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_device_react"></div>

                    <PageContent />
                </main>
            </div>
        </div>
    </Provider>
);
