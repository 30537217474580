import React from 'react';
import { connect } from 'react-redux';
import styles from './NotificationsBlock.module.scss';
import { NotificationToast } from './NotificationToast';
import Button from 'react-bootstrap/Button';
import { closeAllNotifications } from 'ADMIN_COMMON/ac/notificationMethods';

const NotificationsBlockComponent = ({
    notifications,
    closeAllNotifications,
}) => (
    <div className={styles.notificationWrapper}>
        {notifications.map((notification) => (
            <NotificationToast
                key={notification.timestamp}
                notification={notification}
            />
        ))}
        {notifications.length > 1 && (
            <Button
                size="sm"
                variant="dark"
                className={styles.closeAllButton}
                onClick={closeAllNotifications}
            >
                Закрыть всё
            </Button>
        )}
    </div>
);
const mapStateToProps = ({ notifications }) => ({ notifications });
export const NotificationsBlock = connect(mapStateToProps, {
    closeAllNotifications,
})(NotificationsBlockComponent);
