import * as COMMON_TYPES from 'ADMIN_COMMON/constants/types';

const initialState = [];

export const notifications = (state = initialState, { type, payload }) => {
    switch (type) {
        case COMMON_TYPES.THROW_NOTIFICATION:
        case COMMON_TYPES.CLOSE_NOTIFICATION:
        case COMMON_TYPES.CLOSE_ALL_NOTIFICATIONS: {
            return payload;
        }
        default:
            return state;
    }
};
