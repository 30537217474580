import * as T from 'ADMIN_COMMON/constants/types';
import { commonFiltersRequest } from 'ADMIN_COMMON/ac/commonFiltersRequest';

const requestRegionFiltersSuccess = (filters) => ({
    type: T.REQUEST_REGION_FILTERS + T.SUCCESS,
    payload: filters,
});

export const requestRegionFilters = () => async (dispatch) =>
    dispatch(
        commonFiltersRequest({
            successAction: requestRegionFiltersSuccess,
            url: '/regions/filters',
            localStorageKey: 'REGION_FILTER_OPTIONS',
            actionType: T.REQUEST_REGION_FILTERS,
        })
    );
