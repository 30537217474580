import React, { useEffect, useRef } from 'react';
import styles from '../MultiSelect.module.scss';

export const MultiSelectSearchInput = ({
    value,
    onChange,
    isMenuOpen,
    resetSearchInputValue,
    setSearchInputNode,
}) => {
    const inputDOMObject = useRef(null);
    useEffect(() => {
        if (isMenuOpen) {
            setSearchInputNode(inputDOMObject.current);
            inputDOMObject.current.focus();
        }
        return resetSearchInputValue;
    }, [isMenuOpen, setSearchInputNode]);
    return (
        <input
            value={value}
            onChange={onChange}
            className={styles.searchInput}
            ref={inputDOMObject}
        />
    );
};
