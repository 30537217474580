import React from 'react';
import classnames from 'classnames';
import styles from './AccidentStatus.module.scss';
import { accidentsDict } from 'ADMIN_ACCIDENTS/constants/variables';

export const AccidentStatus = ({ status }) => (
    <span className={classnames('font-weight-bold', styles[status])}>
        {accidentsDict[status]}
    </span>
);
