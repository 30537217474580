export const saveLastActionMiddleware = (store) => (next) => (action) => {
    next(action);
    const { type, toggleId, saveAction = false } = action;
    if (saveAction) {
        const lastDispatchedAction = toggleId ? `${type}__${toggleId}` : type;
        next({ type: 'SAVE_LAST_ACTION', payload: { lastDispatchedAction } });
        setTimeout(() =>
            next({
                type: 'SAVE_LAST_ACTION',
                payload: { lastDispatchedAction: '' },
            })
        );
    }
};
export const saveLastActionExtension = () => ({
    middleware: [saveLastActionMiddleware],
});
