import { t } from '@lingui/macro';

export const accidentsDict = {
    NEW: t`Новый`,
    IN_PROGRESS: t`Взят в работу`,
    SOLVED_UNKNOWN_RISK: t`Решён, риски неизвестны`,
    SOLVED_WITH_RISK: t`Решён с рисками`,
    SOLVED_WITHOUT_RISK: t`Решён без рисков`,
    NOT_CONFIRMED: t`Не подтвердился`,
    PAUSED: t`Приостановлен`,
};
export const participantsDict = {
    SCOOTER: t`Самокат`,
    PEDESTRIAN: t`Пешеход`,
    CAR: t`Автомобиль`,
};
export const emergencyServicesDict = {
    MIA: t`МВД`,
    EMERGENCY: t`Скорая помощь`,
};
export const severityDict = {
    LOW: t`Легкая`,
    MEDIUM: t`Средняя`,
    HIGH: t`Тяжелая`,
    CRITICAL: t`Критическая`,
};

export const accidentTypeDict = {
    'accident.type.crash': t`ДТП`,
    'accident.type.fall': t`Падение`,
    'accident.type.user.property': t`Имущество`,
    'accident.type.conflict': t`Конфликт с сервисом`,
    'accident.type.other': t`Другое`,
};

export const accidentSubtypeDict = {
    'accident.type.scooter.and.car': t`Самокат + Автомобиль`,
    'accident.type.scooter.and.parked.car': t`Самокат + Припаркованный автомобиль`,
    'accident.type.scooter.and.pedestrian': t`Самокат + Пешеход`,
    'accident.type.scooter.and.personal.transporter': t`Самокат + СИМ`,
    'accident.type.scooter.and.public.transport': t`Самокат + Общественный транспорт`,
    'accident.cause.user.property.gadget': t`Гаджет`,
    'accident.cause.user.property.accessory': t`Аксессуар`,
};

export const accidentCauseDict = {
    'accident.cause.undefined': t`Не установлено`,
    'accident.cause.traffic.rules.violation.by.the.user': t`Нарушение ПДД пользователем Whoosh`,
    'accident.cause.traffic.rules.violation.by.third.party': t`Нарушение ПДД третьим лицом`,
    'accident.cause.lost.control': t`Не справился с управлением`,
    'accident.cause.broken.scooter': t`Поломка СИМ`,
};

export const accidentCauseDetailDict = {
    'accident.cause.driving.through.crosswalk': t`не спешился на переходе`,
    'accident.cause.crossing.the.road.in.the.wrong.place': t`пересечение дороги в неположенном месте`,
    'accident.cause.driving.in.a.crowd': t`не спешился при большом скоплении людей`,
    'accident.cause.driving.along.a.carriageway': t`езда по проезжей части`,
    'accident.cause.running.a.red.light': t`езда на красный свет`,
    'accident.cause.underage': t`несовершеннолетний`,
    'accident.cause.driving.together': t`езда вдвоём`,
    'accident.cause.driving.together.with.parents': t`езда вдвоём с родителями`,
    'accident.cause.scooter.breakdown': t`поломка самоката`,
    'accident.cause.alcohol.intoxication': t`алкогольное опьянение`,
    'accident.cause.road.surface': t`дорожное покрытие`,
    'accident.cause.little.driving.experience': t`малый опыт вождения`,
    'accident.cause.problem.with.the.scooter': t`проблема с самокатом`,
    'accident.cause.traffic.violation': t`нарушение ПДД`,
    'accident.cause.accident.prevention': t`предупреждение ДТП`,
    'accident.cause.entering.a.speed.limit.zone': t`заезд в зону с ограничением скорости`,
    'accident.cause.weather.condition': t`погодные условия`,
    'accident.cause.other': t`другое`,
    'accident.cause.broken.scooter.rack': t`стойка`,
    'accident.cause.broken.scooter.handlebars': t`руль`,
    'accident.cause.broken.scooter.pedal': t`педаль`,
    'accident.cause.broken.scooter.grip': t`грипса`,
    'accident.cause.broken.scooter.wheel.fork': t`вилка колеса`,
    'accident.cause.broken.scooter.headlight': t`фара`,
    'accident.cause.broken.scooter.tire': t`шина`,
    'accident.cause.broken.scooter.gas.trigger': t`курок газа`,
    'accident.cause.broken.scooter.brake': t`тормоз`,
    'accident.cause.broken.scooter.seat': t`сиденье велосипеда`,
    'accident.cause.broken.scooter.kickstand': t`подножка`,
};

export const accidentCauseTitleDict = {
    'accident.cause.traffic.rules.violation.by.the.user': t`Вид нарушения ПДД пользователем Whoosh`,
    'accident.cause.lost.control': t`Падение вызвало`,
    'accident.cause.broken.scooter': t`Вид поломки`,
};

export const userRoles = [
    'ADMIN',
    'operator',
    'operator_teamlead',
    'operator_alarm',
];

export const roleTypes = {
    all: 'ALL',
    operator: 'operator',
};

export const criticalCases = [
    {
        value: 'ALL',
        label: t`Все`,
    },
    {
        value: 'IS_CRITICAL',
        label: t`Да`,
    },
    {
        value: 'NON_CRITICAL',
        label: t`Нет`,
    },
];

export const criticalCasesMap = {
    ALL: null,
    IS_CRITICAL: ['CRITICAL'],
    NON_CRITICAL: [],
};

export const mimeTypeByExtension = {
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    mp3: 'audio/mpeg',
    pdf: 'application/pdf',
    png: 'image/png',
};

export const labelByFileType = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'docx',
    'audio/mpeg': 'mp3',
    'application/pdf': 'pdf',
};

const transformToOptions = (dict) =>
    Object.entries(dict).map(([value, label]) => ({ value, label }));
export const accidentParticipants = transformToOptions(participantsDict);
export const emergencyServices = transformToOptions(emergencyServicesDict);
export const severityOptions = transformToOptions(severityDict);
export const accidentsStatusOptions = transformToOptions(accidentsDict);
export const DEFAULT_MODE = 'DEFAULT_MODE';
export const RESOLVE_MODE = 'RESOLVE_MODE';
export const EDIT_MODE = 'EDIT_MODE';
export const DUPLICATE_MODE = 'DUPLICATE_MODE';
export const assigneeDefaultValue = { value: '', label: t`Не выбран` };
