'use strict';

import { isDomNode } from 'UTILS/isDomNode';

/**
 * @param {HTMLElement} node
 * @return {Boolean}
 * */
function isDisplayed(node) {
    if (!isDomNode(node))
        throw new TypeError('Passed parameter is not a DOM node');
    return node.offsetHeight > 0;
}

export { isDisplayed };
