'use strict';

(function () {
    if (!Object.values) {
        var reduce = Function.bind.call(Function.call, Array.prototype.reduce),
            isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable),
            concat = Function.bind.call(Function.call, Array.prototype.concat);
        Object.values = function values (O) {
            return reduce(Object.keys(O), (v, k) => concat(v, typeof k === 'string' && isEnumerable(O, k) ? [ O[k] ] : []), []);
        };
    }
})();
