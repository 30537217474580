export const WEBAPP_CONFIGS = 'WEBAPP_CONFIGS';
export const LOGGED_USER = 'LOGGED_USER';
export const REGION_FILTER_OPTIONS = 'REGION_FILTER_OPTIONS';
export const ORGANIZATION_FILTER_OPTIONS = 'ORGANIZATION_FILTER_OPTIONS';
export const SS_NOTIFICATION_DISABLED = 'SS_NOTIFICATION_DISABLED';
export const MESSAGES_LANG_MAP = 'MESSAGES_LANG_MAP'; // TODO удалить (пока нужен, чтобы очищался)
export const LANG = 'LANG';
export const keysToReset = [
    WEBAPP_CONFIGS,
    LOGGED_USER,
    REGION_FILTER_OPTIONS,
    ORGANIZATION_FILTER_OPTIONS,
    SS_NOTIFICATION_DISABLED,
    MESSAGES_LANG_MAP,
    LANG,
];
