export class UserApi {
    /**
     * @param {import('axios').AxiosInstance} client
     */
    constructor(client) {
        /** @private */
        this.client = client;
    }

    async fetchLoggedUser(signal) {
        const response = await this.client.get('/users/logged', { signal });
        const { user } = response.data;

        user.roles = user.roles?.split(':') || [];

        if (!user.organizations) {
            user.organizations = [];
        }

        return user;
    }
}
