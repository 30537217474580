'use strict';

(function (e) {
    e.closest = e.closest || function (selector) {
        let node = this;

        while (node && node !== document) {
            if (node.matches(selector)) return node;
            node = node.parentNode;
        }

        return null;
    };
})(Element.prototype);
