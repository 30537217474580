import * as T from '../constants/types';
import { ALL_LOCATIONS_OPTION } from '../constants/types';
import axios from 'COMMON/axios';
import { SUCCESS, ERROR, SET_FILTERS } from 'ADMIN_COMMON/constants/types';
import { saveFilterItems } from 'UTILS/filterUtils';
import { transformOffsetResponse } from 'UTILS/offsetPaginationHelpers';
import { processAccidentFilters } from 'ADMIN_ACCIDENTS/utils/processAccidentFilters';

export const requestAccidents = () => async (dispatch, getState) => {
    const {
        filters,
        regionFilters,
        accidents: { offset, lastPage, size },
    } = getState();
    const processedFilters = processAccidentFilters(filters);
    if (!filters.region) {
        const region = regionFilters[0].id;
        saveFilterItems('regions', [region]);
        processedFilters.region = region;
        dispatch({ type: SET_FILTERS, payload: processedFilters });
    }

    // Если выбрана опция "Все регионы", отправляем запрос со всеми доступными id регионов
    if (filters.region === ALL_LOCATIONS_OPTION) {
        processedFilters.regions = regionFilters.map((item) => item.id);
    }

    const url = `/accidents/searches?offset=${offset}&lastPage=${lastPage}&size=${size}`;
    window.page.showSpinner();
    const {
        data: { accidents, ...paginationData },
    } = await axios.post(url, {
        searchParams: processedFilters,
        sortingParams: null,
    });
    try {
        dispatch({
            type: T.REQUEST_ACCIDENTS + SUCCESS,
            payload: {
                accidents,
                ...transformOffsetResponse(paginationData, size),
            },
        });
    } catch (error) {
        dispatch({ type: T.REQUEST_ACCIDENTS + ERROR });
    }
    window.page.hideSpinner();
};
