'use strict';

import { removeClass } from 'UTILS/removeClass';

function removeErrorLabel(node) {
    const parent = node.closest('.error, .form__item_error');
    if (parent) removeClass('error form__item_error', parent);
}

export { removeErrorLabel };
