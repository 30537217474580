'use strict';

/**
 * @returns {Boolean}
 * */
function isDomNode(node) {
    return node instanceof Element;
}

export { isDomNode };
