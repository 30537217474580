import React from 'react';
import { Helmet } from 'react-helmet';
import { LazyTripContainer } from './TripContainer';
import Provider from 'react-redux/es/components/Provider';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';
import './trip.scss';

const PageContent = withWrappedPage(LazyTripContainer);

export const PageTrip = () => (
    <Provider store={createAsyncReduxStore(true)}>
        <Helmet>
            <title>{t`Поездка`}</title>
        </Helmet>
        <div className="trip-page">
            <div className="container-fluid modern-page">
                <div className="row">
                    <main role="main" className="px-4 w-100">
                        <div className="js_request_success"></div>
                        <div className="js_request_error"></div>
                        <div className="js_trip_react"></div>
                        <PageContent />
                    </main>
                </div>
            </div>
        </div>
    </Provider>
);
