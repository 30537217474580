import { Trans } from '@lingui/macro';
import { StealSuspicionNotifications } from 'ADMIN_COMMON/AdminNotifications';
import { locales, useChangeLocale } from 'ADMIN_COMMON/hooks/useChangeLocale';
import { getUserNameAbbreviation } from 'UTILS/getUserNameAbbreviation';
import { getSSNotificationsStatus } from 'UTILS/localStorageHelpers';
import { standardError } from 'UTILS/standardError';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { connect } from 'react-redux';
import { useSession } from '../../../../../session/ui/SessionContext';
import styles from './HeaderMenu.module.scss';

const HeaderMenuComponent = ({
    id,
    name,
    receiveSsNotificationsUsingWebSocketEnabled,
    freezeRequestIsActive,
}) => {
    const session = useSession();
    const { changeLocale, currentLocale } = useChangeLocale();
    const abbreviation = useMemo(() => getUserNameAbbreviation(name), [name]);
    const [
        stealSuspicionNotificationsDisabled,
        setStealSuspicionNotificationsDisabled,
    ] = useState(getSSNotificationsStatus());
    const SSNotifications = useRef(null);

    useEffect(() => {
        if (
            !receiveSsNotificationsUsingWebSocketEnabled &&
            !freezeRequestIsActive &&
            window.page.StealSuspicionNotifications
        )
            return;

        window.page.StealSuspicionNotifications =
            new StealSuspicionNotifications({
                receiveSsNotificationsUsingWebSocketEnabled:
                    receiveSsNotificationsUsingWebSocketEnabled === 'true',
                freezeRequestIsActive,
            });

        SSNotifications.current = window.page.StealSuspicionNotifications;
    }, [receiveSsNotificationsUsingWebSocketEnabled, freezeRequestIsActive]);

    const onClickNotificationIcon = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            stealSuspicionNotificationsDisabled
                ? SSNotifications.current.stealSuspicionNotificationsOn()
                : SSNotifications.current.stealSuspicionNotificationsOff();

            setStealSuspicionNotificationsDisabled(
                !stealSuspicionNotificationsDisabled
            );
        },
        [stealSuspicionNotificationsDisabled]
    );

    const handleSignOut = () => {
        session.signOut().then(() => {
            window.location.href = '/login';
        }, standardError);
    };

    const handleLocaleChange = async (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        const target = evt.target;
        const selectedIdx = target.selectedIndex;
        const nextLocale = evt.target.options[selectedIdx].value;
        await changeLocale(nextLocale);
        window.location.reload();
    };
    return (
        <div className="current-user-dropdown" id="currentUserDropdown">
            <div className="btn-group">
                <button
                    type="button"
                    className="btn btn-primary current-user-icon"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span className="m-0 p-0 user-name-abbreviation">
                        {abbreviation}
                    </span>
                </button>
                <span
                    className="d-flex align-items-center justify-content-center dropdown-toggle dropdown-toggle-split dropdown-arrow"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span className="sr-only" />
                </span>
                <div className="dropdown-menu dropdown-menu-right user-dropdown-menu">
                    <ul className="navbar-nav px-3">
                        <a href={`/view/users/${id}`}>{name}</a>
                    </ul>
                    <div className="dropdown-divider" />
                    <ul className="navbar-nav px-3">
                        <button
                            type="button"
                            onClick={onClickNotificationIcon}
                            className={styles.navbarButton}
                        >
                            <Trans>Нотификации SS</Trans>:
                            <i
                                className={`ml-1 steal-suspicion-${
                                    stealSuspicionNotificationsDisabled
                                        ? 'off'
                                        : 'on'
                                }`}
                            />
                        </button>
                    </ul>
                    <div className="dropdown-divider" />
                    <div className="px-3">
                        <div
                            className={styles.localeChange}
                            onClick={(evt) => {
                                evt.stopPropagation();
                            }}
                        >
                            <span className={styles.localeChangeTitle}>
                                <Trans>Язык</Trans>
                            </span>

                            <select
                                defaultValue={currentLocale}
                                className="form-control"
                                onChange={handleLocaleChange}
                            >
                                {Object.values(locales).map((locale) => (
                                    <option key={locale} value={locale}>
                                        {locale}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="dropdown-divider" />
                    <ul className="navbar-nav px-3">
                        <li className="nav-item text-nowrap">
                            <button
                                type="button"
                                onClick={handleSignOut}
                                className="btn btn-primary btn-sm w-100"
                            >
                                <Trans>Выйти</Trans>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <audio preload="true" id="stealSuspicionAlarm" loop>
                <source
                    src="/audio/steal-suspicion-sound.mp3"
                    type="audio/mpeg"
                />
            </audio>
        </div>
    );
};
const mapStateToProps = ({
    loggedUser: { id = '', name = '' },
    configs: { application },
}) => ({
    id,
    name,
    receiveSsNotificationsUsingWebSocketEnabled:
        application['receive.ss.notifications.using.websocket.enabled'],
    freezeRequestIsActive: application['freeze.request.is.active'],
});

export const HeaderMenu = connect(mapStateToProps)(HeaderMenuComponent);
