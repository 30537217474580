'use strict';

(function (n) {
    /**
     * @param {Number} c - цифр после запятой
     * @param {String=} d - разделитель целой и дробной части
     * @param {String=} t - разделитель групп разрядов
     * @default - 2, ',', ' '
     * */
    n.formatMoney = function (c, d, t) {
        var n = this,
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = document.body.dataset.decimalSeparator  || d || ',',
            t = document.body.dataset.groupingSeparator || t || ' ',
            s = n < 0 ? '-' : '',
            i = parseInt(n = Math.abs(+n || 0).toFixed(c), 10) + '',
            j = (j = i.length) > 3 ? j % 3 : 0;
        return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
    };
})(Number.prototype);
