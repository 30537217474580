'use strict';

(function (s) {
    s.transliterate = function (properties) {
        const defaults = {
            direction: 'bc2l',
            maps: {
                l2c:  { charMap: [ 'a|b|v|g|d|e|e|zh|z|i|y|k|l|m|n|o|p|r|s|t|u|f|kh|ts|ch|sh|shch|null|y|null|e|yu|ya|A|B|V|G|D|E|E|ZH|Z|I|Y|K|L|M|N|O|P|R|S|T|U|F|KH|TS|CH|SH|SHCH|null|Y|null|E|YU|YA', 'а|б|в|г|д|е|ё|ж|з|и|й|к|л|м|н|о|п|р|с|т|у|ф|х|ц|ч|ш|щ|ъ|ы|ь|э|ю|я|А|Б|В|Г|Д|Е|Ё|Ж|З|И|Й|К|Л|М|Н|О|П|Р|С|Т|У|Ф|Х|Ц|Ч|Ш|Щ|Ъ|Ы|Ь|Э|Ю|Я' ]},
                c2l:  { charMap: [ 'а|б|в|г|д|е|ё|ж|з|и|й|к|л|м|н|о|п|р|с|т|у|ф|х|ц|ч|ш|щ|ъ|ы|ь|э|ю|я|А|Б|В|Г|Д|Е|Ё|Ж|З|И|Й|К|Л|М|Н|О|П|Р|С|Т|У|Ф|Х|Ц|Ч|Ш|Щ|Ъ|Ы|Ь|Э|Ю|Я', 'a|b|v|g|d|e|e|zh|z|i|y|k|l|m|n|o|p|r|s|t|u|f|kh|ts|ch|sh|shch|null|y|null|e|yu|ya|A|B|V|G|D|E|E|ZH|Z|I|Y|K|L|M|N|O|P|R|S|T|U|F|KH|TS|CH|SH|SHCH|null|Y|null|E|YU|YA' ]},
                bc2l: { charMap: [ 'а|б|в|г|д|е|ё|ж|з|и|й|к|л|м|н|о|п|р|с|т|у|ф|х|ц|ч|ш|щ|ъ|ы|ь|э|ю|я|А|Б|В|Г|Д|Е|Ё|Ж|З|И|Й|К|Л|М|Н|О|П|Р|С|Т|У|Ф|Х|Ц|Ч|Ш|Щ|Ъ|Ы|Ь|Э|Ю|Я', 'a|b|v|g|d|e|e|zh|z|i|y|k|l|m|n|o|p|r|s|t|u|f|x|ts|ch|sh|sc|null|y|null|e|yu|ya|A|B|V|G|D|E|E|ZH|Z|I|Y|K|L|M|N|O|P|R|S|T|U|F|X|TS|CH|SH|SC|null|Y|null|E|YU|YA' ]},
                r2e:  { charMap: [ 'й|ц|у|к|е|н|г|ш|щ|з|х|ъ|ф|ы|в|а|п|р|о|л|д|ж|э|я|ч|с|м|и|т|ь|б|ю|ё|Й|Ц|У|К|Е|Н|Г|Ш|Щ|З|Х|Ъ|Ф|Ы|В|А|П|Р|О|Л|Д|Ж|Э|Я|Ч|С|М|И|Т|Ь|Б|Ю|Ё', "q|w|e|r|t|y|u|i|o|p|[|]|a|s|d|f|g|h|j|k|l|;|'|z|x|c|v|b|n|m|,|.|`|Q|W|E|R|T|Y|U|I|O|P|{|}|A|S|D|F|G|H|J|K|L|:|\"|Z|X|C|V|B|N|M|<|>|~" ]},
                e2r:  { charMap: [ "q|w|e|r|t|y|u|i|o|p|[|]|a|s|d|f|g|h|j|k|l|;|'|z|x|c|v|b|n|m|,|.|`|Q|W|E|R|T|Y|U|I|O|P|{|}|A|S|D|F|G|H|J|K|L|:|\"|Z|X|C|V|B|N|M|<|>|~", 'й|ц|у|к|е|н|г|ш|щ|з|х|ъ|ф|ы|в|а|п|р|о|л|д|ж|э|я|ч|с|м|и|т|ь|б|ю|ё|Й|Ц|У|К|Е|Н|Г|Ш|Щ|З|Х|Ъ|Ф|Ы|В|А|П|Р|О|Л|Д|Ж|Э|Я|Ч|С|М|И|Т|Ь|Б|Ю|Ё' ]},
                s2eb: { charMap: [ 'й|ц|у|к|е|н|г|ш|щ|з|х|ъ|ф|ы|в|а|п|р|о|л|д|ж|э|я|ч|с|м|и|т|ь|б|ю|ё|Й|Ц|У|К|Е|Н|Г|Ш|Щ|З|Х|Ъ|Ф|Ы|В|А|П|Р|О|Л|Д|Ж|Э|Я|Ч|С|М|И|Т|Ь|Б|Ю|Ё|q|w|e|r|t|y|u|i|o|p|a|s|d|f|g|h|j|k|l|z|x|c|v|b|n|m', "Q|W|E|R|T|Y|U|I|O|P|{|}|A|S|D|F|G|H|J|K|L|:|\'|Z|X|C|V|B|N|M|<|>|~|Q|W|E|R|T|Y|U|I|O|P|{|}|A|S|D|F|G|H|J|K|L|:|\'|Z|X|C|V|B|N|M|<|>|~|Q|W|E|R|T|Y|U|I|O|P|A|S|D|F|G|H|J|K|L|Z|X|C|V|B|N|M" ]}
            }
        };
        const options = Object.assign(defaults, properties);

        function transliteratedString (string) {
            const text = '' + string;
            if (text) return transliterate(text);
            return '';
        }

        function transliterate (string) {
            const fromChars = options.maps[options.direction].charMap[0].split('|');
            const toChars = options.maps[options.direction].charMap[1].split('|');
            const re = new RegExp(fromChars.join('|'), 'g');
            const charMap = {};

            for (let i = 0; i < fromChars.length; i++) {
                charMap[fromChars[i]] = i < toChars.length ? toChars[i] : fromChars[i];
            }

            string = string.replace(re, function (c) {
                const ch = charMap[c];
                return ch ? ch === 'null' ? '' : ch : c;
            });

            return string;
        }

        return transliteratedString(this);
    };
})(String.prototype);
