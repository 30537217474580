import { t } from '@lingui/macro';
import React from 'react';
import { Helmet } from 'react-helmet';

import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { Provider } from 'react-redux';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { LazyHomeContainer } from './Container';
import './home.scss';

const PageContent = withWrappedPage(LazyHomeContainer);

export const PageHome = () => {
    return (
        <Provider store={createAsyncReduxStore(true)}>
            <Helmet>
                <title>{t`Онлайн карта`}</title>
            </Helmet>

            <div className="container-fluid modern-page">
                <div className="row">
                    <main role="main" className="px-4 w-100">
                        <div className="js_request_success"></div>
                        <div className="js_request_error"></div>
                        <div className="home-page-react"></div>

                        <PageContent />
                    </main>
                </div>
            </div>
        </Provider>
    );
};
