import { i18n } from '@lingui/core';
import { en, ru } from 'make-plural/plurals';
import {
    getLocalStorageItem,
    saveLocalStorageItem,
} from 'UTILS/localStorageHelpers';
import { LANG } from 'ADMIN_COMMON/constants/localStorageKeys';

export const defaultLocale = getLocalStorageItem(LANG) || 'ru';

export const locales = {
    en: 'en',
    ru: 'ru',
};

i18n.loadLocaleData({
    [locales.en]: { plurals: en },
    [locales.ru]: { plurals: ru },
});

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale) {
    const { messages } = await import(
        `@lingui/loader!../../locales/${locale}/messages.po`
    );
    saveLocalStorageItem(LANG, locale);
    i18n.load(locale, messages);
    i18n.activate(locale);
}
