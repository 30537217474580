import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

export function useMessages() {
    const { i18n } = useLingui();
    const messages = useSelector((state) => state.configs?.messages ?? {});

    const getMessage = (key, fallback = '') => {
        return messages[`${key}:${i18n.locale}`]?.value || fallback;
    };

    return {
        getMessage,
    };
}
