import { env } from 'ADMIN_COMMON/constants/enviroments';
import {
    authFulfilledInterceptor,
    authRejectedInterceptor,
    responseFulfilledInterceptor,
    responseRejectedInterceptor,
} from 'COMMON/axios';
import axios from 'axios';
import { createSearchParams } from 'react-router-dom';

export const apiClient = axios.create({
    baseURL: env.baseURL.replace('/v0', ''),
    paramsSerializer: (params) => createSearchParams(params),
});

apiClient.interceptors.request.use(
    authFulfilledInterceptor,
    authRejectedInterceptor
);

apiClient.interceptors.response.use(
    responseFulfilledInterceptor,
    responseRejectedInterceptor
);
