import React from 'react';
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
} from 'react-router-dom';

import '../admin/common/common';

import { ForbiddenPage } from 'ADMIN_COMMON/components/Pages/ForbiddenPage';
import {
    ProtectedRoute,
    ProtectedView,
    SessionProvider,
    UserPermission,
} from '~/session';
import { PageAccidents } from '../admin/accidents';
import { AccidentModal } from '../admin/accidents/src/components/Container/AccidentModals/AccidentModal/';
import { PageBatteries } from '../admin/batteries';
import { BatteriesChargeLevelPage } from '../admin/batteries-charge-level';
import { PageDailyPass } from '../admin/daily-pass';
import { PageDailyPasses } from '../admin/daily-passes';
import { PageDevice } from '../admin/device';
import { PageDeviceStatusHistory } from '../admin/device-status-history';
import { PageFirmware } from '../admin/firmware';
import { PageHome } from '../admin/home';
import { PageLabels } from '../admin/labels';
import { PageLogin } from '../admin/login-page';
import { PageOrganizations } from '../admin/organizations';
import { PageParkingPartners } from '../admin/parking-partners';
import { PageParkings } from '../admin/parkings';
import { PagePenalties } from '../admin/penalties';
import { PagePromoCodes } from '../admin/promocodes';
import { PageRegions } from '../admin/regions';
import { PageSS } from '../admin/ss';
import { PageStealSuspicion } from '../admin/steal-suspicion';
import { PageSubscription } from '../admin/subscription';
import { PageSubscriptions } from '../admin/subscriptions';
import { PageTariffs } from '../admin/tariffs';
import { PageTerms } from '../admin/terms';
import { PageTrip } from '../admin/trip';
import { PageTrips } from '../admin/trips';
import { PageUser } from '../admin/user';
import { PageUsers } from '../admin/users';
import { PageVirtualDevices } from '../admin/virtualDevices';
import { PageWork } from '../admin/work';

import './css/global.scss';

export const App = ({ cognitoAuthAdapter, userApi }) => {
    const forbiddenPage = <ForbiddenPage />;

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="login"
                    element={<PageLogin auth={cognitoAuthAdapter} />}
                />

                <Route
                    element={
                        <ProtectedRoute auth={cognitoAuthAdapter}>
                            <SessionProvider
                                auth={cognitoAuthAdapter}
                                userApi={userApi}
                            >
                                <Outlet />
                            </SessionProvider>
                        </ProtectedRoute>
                    }
                >
                    <Route path="/" element={<Navigate replace to="/home" />} />

                    <Route path="*" element={<Navigate to="/home" replace />} />

                    <Route
                        path="/home"
                        element={
                            <ProtectedView
                                permission={UserPermission.Devices}
                                fallback={forbiddenPage}
                            >
                                <PageHome />
                            </ProtectedView>
                        }
                    />

                    <Route path="/accidents" element={<PageAccidents />}>
                        <Route path=":accidentId" element={<AccidentModal />} />
                    </Route>

                    <Route
                        path="/removable-batteries"
                        element={
                            <ProtectedView
                                permission={UserPermission.Batteries}
                                fallback={forbiddenPage}
                            >
                                <PageBatteries />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/batteries-charge-level"
                        element={
                            <ProtectedView
                                permission={UserPermission.BatteriesChargeLevel}
                                fallback={forbiddenPage}
                            >
                                <BatteriesChargeLevelPage />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/subscriptions"
                        element={
                            <ProtectedView
                                permission={UserPermission.Subscriptions}
                                fallback={forbiddenPage}
                            >
                                <PageSubscriptions />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/subscriptions/description"
                        element={
                            <ProtectedView
                                permission={UserPermission.Subscriptions}
                                fallback={forbiddenPage}
                            >
                                <PageSubscription />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/virtual/devices/view"
                        element={
                            <ProtectedView
                                permission={UserPermission.VirtualDevices}
                                fallback={forbiddenPage}
                            >
                                <PageVirtualDevices />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/ss-devices"
                        element={
                            <ProtectedView
                                permission={UserPermission.StealSuspicion}
                                fallback={forbiddenPage}
                            >
                                <PageStealSuspicion />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/ss-devices/old"
                        element={
                            <ProtectedView
                                permission={UserPermission.StealSuspicion}
                                fallback={forbiddenPage}
                            >
                                <PageSS />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/firmware-update"
                        element={
                            <ProtectedView
                                permission={UserPermission.FirmwareUpdate}
                                fallback={forbiddenPage}
                            >
                                <PageFirmware />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/parking-partners"
                        element={
                            <ProtectedView
                                permission={UserPermission.Partners}
                                fallback={forbiddenPage}
                            >
                                <PageParkingPartners />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/labels"
                        element={
                            <ProtectedView
                                permission={UserPermission.DeviceLabels}
                                fallback={forbiddenPage}
                            >
                                <PageLabels />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/organizations"
                        element={
                            <ProtectedView
                                permission={UserPermission.Organizations}
                                fallback={forbiddenPage}
                            >
                                <PageOrganizations />
                            </ProtectedView>
                        }
                    />

                    <Route path="/parkings" element={<PageParkings />} />

                    <Route
                        path="/penalties"
                        element={
                            <ProtectedView
                                permission={UserPermission.Penalties}
                                fallback={forbiddenPage}
                            >
                                <PagePenalties />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/promo"
                        element={
                            <ProtectedView
                                permission={UserPermission.Promocodes}
                                fallback={forbiddenPage}
                            >
                                <PagePromoCodes />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/tariffs"
                        element={
                            <ProtectedView
                                permission={UserPermission.Tariffs}
                                fallback={forbiddenPage}
                            >
                                <PageTariffs />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/regions/view"
                        element={
                            <ProtectedView
                                permission={UserPermission.Regions}
                                fallback={forbiddenPage}
                            >
                                <PageRegions />
                            </ProtectedView>
                        }
                    />

                    <Route path="/trips/view" element={<PageTrips />} />

                    <Route path="/trip" element={<PageTrip />} />

                    <Route path="/users" element={<PageUsers />} />

                    <Route
                        path="/view/users/:userId/*"
                        element={<PageUser />}
                    />

                    <Route
                        path="/devices/description/:deviceId"
                        element={<PageDevice />}
                    />

                    <Route
                        path="/statistic/device-states"
                        element={<PageDeviceStatusHistory />}
                    />

                    <Route
                        path="/work"
                        element={
                            <ProtectedView
                                permission={UserPermission.Works}
                                fallback={forbiddenPage}
                            >
                                <PageWork />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/daily-passes"
                        element={
                            <ProtectedView
                                permission={UserPermission.DailyPass}
                                fallback={forbiddenPage}
                            >
                                <PageDailyPasses />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/daily-passes/:id"
                        element={
                            <ProtectedView
                                permission={UserPermission.DailyPass}
                                fallback={forbiddenPage}
                            >
                                <PageDailyPass />
                            </ProtectedView>
                        }
                    />

                    <Route
                        path="/terms"
                        element={
                            <ProtectedView
                                permission={UserPermission.Terms}
                                fallback={forbiddenPage}
                            >
                                <PageTerms />
                            </ProtectedView>
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
