import React from 'react';
import { Helmet } from 'react-helmet';

import { LazyTripsContainer } from './Container';
import Provider from 'react-redux/es/components/Provider';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';

const PageContent = withWrappedPage(LazyTripsContainer);

export const PageTrips = () => (
    <Provider store={createAsyncReduxStore()}>
        <Helmet>
            <title>{t`Список поездок`}</title>
        </Helmet>
        <div className="container-fluid">
            <div className="row">
                <main
                    role="main"
                    className="col-md-9 ml-sm-auto col-lg-10 px-4"
                >
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_trips_react"></div>

                    <PageContent />
                </main>
            </div>
        </div>
    </Provider>
);
