import * as T from 'ADMIN_COMMON/constants/types';
import { saveLocalStorageItem } from 'UTILS/localStorageHelpers';

export const onNewAppAlertToggle = () => (dispatch, getState) => {
    const { newAppAlert } = getState();
    saveLocalStorageItem('isNewAppAlertHidden', !newAppAlert.isHidden);
    dispatch({
        type: T.NEW_APP_ALERT,
        payload: { isHidden: !newAppAlert.isHidden },
    });
};
