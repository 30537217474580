import React, { Suspense } from 'react';
import Spinner from 'ADMIN_COMMON/components/Spinner';

export const withSuspense =
    (WrappedComponent, FallbackComponent = null) =>
    (props) =>
        (
            <Suspense fallback={FallbackComponent || <Spinner />}>
                <WrappedComponent {...props} />
            </Suspense>
        );
