import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { withSuspense } from 'ADMIN_COMMON/components/HOC/withSuspense';

const EditAccidentYandexMap = lazy(() => import('./EditAccidentYandexMap'));
const EditAccidentGoogleMap = lazy(() => import('./EditAccidentGoogleMap'));

const EditAccidentModalMapComponent = ({
    accident,
    region,
    setAddressInputValue,
    updateCoordinates,
    enteredAddress,
    regionFilters,
    mapProvider,
}) =>
    mapProvider === 'GOOGLE' ? (
        <EditAccidentGoogleMap
            accident={accident}
            region={region}
            setAddressInputValue={setAddressInputValue}
            updateCoordinates={updateCoordinates}
            enteredAddress={enteredAddress}
            regionFilters={regionFilters}
        />
    ) : (
        <EditAccidentYandexMap
            accident={accident}
            region={region}
            setAddressInputValue={setAddressInputValue}
            updateCoordinates={updateCoordinates}
            enteredAddress={enteredAddress}
            regionFilters={regionFilters}
        />
    );

const mapStateToProps = ({
    regionFilters,
    configs: {
        adminConfiguration: { mapProvider },
    },
}) => ({
    regionFilters,
    mapProvider,
});

export const EditAccidentModalMap = withSuspense(
    connect(mapStateToProps)(EditAccidentModalMapComponent)
);
