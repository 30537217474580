import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { requestRegionFilters } from 'ADMIN_COMMON/ac/requestRegionFilters';
import { FilterWrapper } from 'ADMIN_COMMON/components/FilterWrapper';
import SelectInput from 'ADMIN_COMMON/components/SelectInput';
import MultiSelect from 'ADMIN_COMMON/components/MultiSelect';
import { t } from '@lingui/macro';
import { ALL_LOCATIONS_OPTION } from '../../../../accidents/src/constants/types';
import { useRegionFilterOptions } from './useRegionFilterOptions';

const RegionFilterComponent = ({
    requestRegionFilters,
    value,
    onChange,
    id = 'regions',
    className = '',
    children,
    disabled = false,
    isMultiSelect = false,
    values = [],
    label = t`Регионы` + ':',
    error,
    contentRenderer = null,
    isAllOption = false,
}) => {
    const options = useRegionFilterOptions();
    const filterComponent = useMemo(() => {
        // Если isAllOption добавляем опцию "Все регионы"
        const selectOptions = isAllOption
            ? [
                  { value: ALL_LOCATIONS_OPTION, label: t`Все регионы` },
                  ...options,
              ]
            : options;

        if (isMultiSelect) {
            return (
                <MultiSelect
                    values={values}
                    options={selectOptions}
                    onChange={onChange}
                    contentRenderer={
                        contentRenderer ||
                        (() => (
                            <div>
                                {values.length} {t`регионов`}
                            </div>
                        ))
                    }
                    error={error}
                    disabled={disabled}
                />
            );
        }

        if (!isMultiSelect) {
            return (
                <SelectInput
                    value={value}
                    options={selectOptions}
                    onChange={onChange}
                    id={id}
                    disabled={disabled}
                >
                    {children}
                </SelectInput>
            );
        }
    }, [
        isMultiSelect,
        values,
        value,
        options,
        onChange,
        id,
        disabled,
        children,
        isAllOption,
    ]);
    return (
        <FilterWrapper
            label={label}
            requestFilters={requestRegionFilters}
            className={className}
            blockFiltersRequest={options.length}
        >
            {filterComponent}
        </FilterWrapper>
    );
};

export const RegionFilter = connect(null, { requestRegionFilters })(
    RegionFilterComponent
);
