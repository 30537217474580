export class Subscribable {
    value;

    /**
     * @private
     * @readonly
     */
    handlers = new Set();

    constructor(value) {
        this.value = value;
    }

    subscribe(fn) {
        this.handlers.add(fn);

        return () => this.handlers.delete(fn);
    }

    next(value) {
        this.value = value;
        this.handlers.forEach((fn) => fn(value));
    }
}
