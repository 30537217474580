import { t, Trans } from '@lingui/macro';
import { updateAccident } from 'ADMIN_ACCIDENTS/ac/updateAccident';
import { DuplicateForm } from 'ADMIN_ACCIDENTS/components/Container/AccidentModals/AccidentModal/DuplicateForm/DuplicateForm';
import { ResolveAccidentForm } from 'ADMIN_ACCIDENTS/components/Container/AccidentModals/AccidentModal/ResolveAccidentForm';
import { EditAccidentModalBody } from 'ADMIN_ACCIDENTS/components/Container/AccidentModals/EditAccidentModalBody';
import commonStyles from 'ADMIN_ACCIDENTS/components/Container/AccidentModals/NewAccidentModal/NewAccidentsModal.module.scss';
import { AccidentStatus } from 'ADMIN_ACCIDENTS/components/Container/AccidentStatus';
import * as V from 'ADMIN_ACCIDENTS/constants/variables';
import { getFileTypeByName } from 'ADMIN_ACCIDENTS/utils/getFileTypeByName';
import Spinner from 'ADMIN_COMMON/components/Spinner';
import classnames from 'classnames';
import axios from 'COMMON/axios';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getFullDateTime } from 'UTILS/getFullDateTime';
import styles from './AccidentModal.module.scss';
import { AccidentModalBody } from './AccidentModalBody';

export const AccidentModal = () => {
    const { accidentId = '' } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onClose = useCallback(() => navigate('/accidents'), [navigate]);

    const { isLoading, data: accident } = useQuery(
        ['accident', accidentId],
        async () => {
            const { data } = await axios.get(`/accidents/${accidentId}`);

            return data;
        },
        {
            select: (data) =>
                data.accident?.files?.length
                    ? {
                          ...data.accident,
                          files: data.accident.files.map((file) => ({
                              ...file,
                              type: getFileTypeByName(file.name),
                          })),
                      }
                    : data.accident,
            enabled: !!accidentId,
            onError: onClose,
            cacheTime: 0,
        }
    );

    const [activeMode, setActiveMode] = useState(V.DEFAULT_MODE);
    const setEditMode = () => setActiveMode(V.EDIT_MODE);
    const setDefaultMode = () => setActiveMode(V.DEFAULT_MODE);
    const setResolveMode = () => setActiveMode(V.RESOLVE_MODE);
    const setDuplicateMode = () => setActiveMode(V.DUPLICATE_MODE);
    const [showImage, setShowImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const onOpenImageModal = useCallback((image) => {
        setSelectedImage(image);
        setShowImage(true);
    }, []);
    const onCloseImageModal = useCallback(() => {
        setShowImage(false);
        setSelectedImage('');
    }, []);
    useEffect(() => {
        if (!accidentId) setDefaultMode();
    }, [accidentId]);
    const onSubmitAccident = async (updatedAccident) => {
        await dispatch(
            updateAccident({ ...updatedAccident, number: accident.number })
        );
        onClose();
    };

    if (isLoading) {
        return <Spinner />;
    }

    if (accident) {
        return (
            <Modal
                show
                onHide={onClose}
                className={classnames(commonStyles.sidebarModalWrapper, {
                    [styles.backgroundModal]: showImage,
                })}
            >
                <Modal.Header className="border-0 pb-2" closeButton>
                    <Modal.Title>
                        {(activeMode === V.DEFAULT_MODE ||
                            activeMode === V.EDIT_MODE) &&
                            t`Инцидент ${accident.id}`}
                        {activeMode === V.RESOLVE_MODE && t`Решение инцидента`}
                        {activeMode === V.DUPLICATE_MODE &&
                            t`Пометить как дубликат`}
                    </Modal.Title>
                </Modal.Header>
                {activeMode === V.DEFAULT_MODE && !isEmpty(accident) && (
                    <>
                        <div className={styles.headerLabel}>
                            <div className="d-flex align-items-center">
                                <p className="m-0">
                                    {getFullDateTime(
                                        accident.committedAt,
                                        false,
                                        true
                                    )}
                                </p>
                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    className="ml-2"
                                    onClick={setDuplicateMode}
                                >
                                    <Trans>Дубликат</Trans>
                                </Button>
                            </div>
                            <AccidentStatus status={accident.status} />
                        </div>
                        <AccidentModalBody
                            onClose={onClose}
                            setEditMode={setEditMode}
                            accident={accident}
                            showImage={showImage}
                            selectedImage={selectedImage}
                            onOpenImageModal={onOpenImageModal}
                            onCloseImageModal={onCloseImageModal}
                            setResolveMode={setResolveMode}
                        />
                    </>
                )}
                {activeMode === V.RESOLVE_MODE && (
                    <ResolveAccidentForm
                        disableResolveMode={setDefaultMode}
                        accident={accident}
                        onClose={onClose}
                    />
                )}
                {activeMode === V.EDIT_MODE && (
                    <EditAccidentModalBody
                        onClose={setDefaultMode}
                        onSubmitAccident={onSubmitAccident}
                        accident={accident}
                    />
                )}
                {activeMode === V.DUPLICATE_MODE && (
                    <DuplicateForm onClose={setDefaultMode} />
                )}
            </Modal>
        );
    }
};
