import React from 'react';
import { Helmet } from 'react-helmet';

import { LazyPenaltyContainer } from './Container';
import Provider from 'react-redux/es/components/Provider';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';

const PageContent = withWrappedPage(LazyPenaltyContainer);

export const PagePenalties = () => (
    <Provider store={createAsyncReduxStore()}>
        <Helmet>
            <title>{t`Штрафы`}</title>
        </Helmet>

        <div className="container-fluid">
            <div className="row">
                <main role="main" className="w-100">
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_penalties_react"></div>

                    <PageContent />
                </main>
            </div>
        </div>
    </Provider>
);
