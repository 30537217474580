import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Subscribable } from 'COMMON/reactive/Subscribable';
import { AuthChallengeType, AuthStateName } from '../AuthAdapter';
import {
    AuthError,
    IncorrectChallengeResponseError,
    IncorrectPasswordError,
    UserBlockedError,
    UserNotAuthorizedError,
} from '../authErrors';

export class CognitoAuthAdapter {
    cognitoUser;
    state = new Subscribable({ name: AuthStateName.Idle });
    hubListenerDestructor;

    constructor() {
        this.hubListenerDestructor = Hub.listen('auth', (capsule) => {
            this.handleAuthHubCapsule(capsule).catch(console.error);
        });
    }

    async setup(options) {
        this.setState({ name: AuthStateName.Setup });

        Auth.configure(options);

        try {
            const user = await Auth.currentAuthenticatedUser();

            this.cognitoUser = user;
            this.setState({ name: AuthStateName.Authenticated });
        } catch (err) {
            this.setState({ name: AuthStateName.SignIn });
        }
    }

    teardown() {
        if (typeof this.hubListenerDestructor === 'function') {
            this.hubListenerDestructor();
        }
    }

    async signIn(username, password) {
        try {
            const user = await Auth.signIn(username, password);

            this.cognitoUser = user;
        } catch (err) {
            this.handleCognitoError(err);
        }

        if (!this.cognitoUser) {
            throw new UserNotAuthorizedError();
        }

        if (this.isUserWithCustomChallenge(this.cognitoUser)) {
            this.handleCustomChallenge(this.cognitoUser);
        }
    }

    async sendChallengeAnswer(answer) {
        if (!this.cognitoUser) {
            throw new UserNotAuthorizedError();
        }

        try {
            const user = await Auth.sendCustomChallengeAnswer(
                this.cognitoUser,
                answer
            );

            if (user) {
                this.cognitoUser = user;
            }
        } catch (err) {
            this.handleCognitoError(err);
        }

        if (!this.cognitoUser) {
            throw new UserNotAuthorizedError();
        }

        if (this.isUserWithCustomChallenge(this.cognitoUser)) {
            this.handleCustomChallenge(this.cognitoUser);

            if (this.cognitoUser.challengeParam.error === 'wrong.password') {
                throw new IncorrectPasswordError();
            }

            if (this.cognitoUser.challengeParam.error === 'wrong.otp') {
                throw new IncorrectChallengeResponseError();
            }
        }
    }

    async resendChallenge() {
        if (!this.cognitoUser) {
            throw new UserNotAuthorizedError();
        }

        try {
            const user = await Auth.sendCustomChallengeAnswer(
                this.cognitoUser,
                ' '
            );

            if (user) {
                this.cognitoUser = user;
            }
        } catch (err) {
            this.handleCognitoError(err);
        }

        if (!this.cognitoUser) {
            throw new UserNotAuthorizedError();
        }

        if (this.isUserWithCustomChallenge(this.cognitoUser)) {
            this.handleCustomChallenge(this.cognitoUser);
        }
    }

    dismissChallenge() {
        this.setState({ name: AuthStateName.SignIn });
    }

    async getTokens() {
        const session = await Auth.currentSession();

        return {
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
            accessToken: session.getAccessToken().getJwtToken(),
        };
    }

    async signOut() {
        this.setState({ name: AuthStateName.SignOut });

        await Auth.signOut();

        this.setState({ name: AuthStateName.SignIn });
    }

    setState(nextState) {
        this.state.next(nextState);
    }

    isUserWithCustomChallenge(user) {
        return user.challengeName === 'CUSTOM_CHALLENGE';
    }

    handleCustomChallenge(user) {
        const challenge = {
            type: AuthChallengeType.Undefined,
            params: user.challengeParam,
        };
        const nextState = {
            name: AuthStateName.ConfirmSignIn,
            challenge,
        };

        switch (user.challengeParam.confirmType) {
            case 'COGNITO_PASSWORD':
                challenge.type = AuthChallengeType.Password;
                break;
            case 'sms':
                challenge.type = AuthChallengeType.Sms;
                break;
            case 'push':
                challenge.type = AuthChallengeType.Push;
                break;
            case 'call':
                challenge.type = AuthChallengeType.Call;
                break;
            case 'voice':
                challenge.type = AuthChallengeType.Voice;
                break;
            case 'undefined':
                throw new UserBlockedError();
            default:
                throw new AuthError('Unknown confirmType for challenge');
        }

        this.setState(nextState);
    }

    async handleAuthHubCapsule(capsule) {
        /**
         * @see https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
         */
        switch (capsule.payload.event) {
            case 'signIn': {
                const user = capsule.payload.data;

                this.cognitoUser = user;
                this.setState({ name: AuthStateName.Authenticated });
                break;
            }
            case 'tokenRefresh':
                try {
                    const user = await Auth.currentAuthenticatedUser();

                    this.cognitoUser = user;
                } catch (err) {
                    return this.signOut();
                }
                break;
            case 'signOut':
            case 'tokenRefresh_failure':
                // eslint-disable-next-line no-undefined
                this.cognitoUser = undefined;
                this.setState({ name: AuthStateName.SignIn });
                break;
        }
    }

    handleCognitoError(err) {
        if (err instanceof Error) {
            switch (err.name) {
                case 'NotAuthorizedException':
                    throw new UserNotAuthorizedError();
                case 'UserLambdaValidationException':
                    throw new UserBlockedError();
                default:
                    throw new AuthError();
            }
        } else {
            throw new AuthError();
        }
    }
}
