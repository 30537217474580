import { Trans } from '@lingui/macro';
import { updateAccidentStatus } from 'ADMIN_ACCIDENTS/ac/updateAccidentStatus';
import { AccidentImage } from 'ADMIN_ACCIDENTS/components/Container/AccidentModals/AccidentImage';
import { AccidentModalMap } from 'ADMIN_ACCIDENTS/components/Container/AccidentModals/AccidentModal/AccidentModalBody/AccidentModalMap';
import * as V from 'ADMIN_ACCIDENTS/constants/variables';
import { withUseMapsConnectionsHook } from 'ADMIN_COMMON/components/HOC/withUseMapsConnectionsHook';
import { Image } from 'ADMIN_TRIP/components/TripDescription/Images/List/Image';
import { getFullDateTime } from 'UTILS/getFullDateTime';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { ProtectedView, UserPermission } from '~/session';
import styles from './AccidentModalBody.module.scss';

const AccidentModalBodyComponent = ({
    regionFilters,
    accident: { id, files = [], coordinate, ...accident },
    setEditMode,
    showImage,
    selectedImage,
    onOpenImageModal,
    onCloseImageModal,
    setResolveMode,
    mapsCreated,
    updateAccidentStatus,
    onClose,
}) => {
    const onGetInProgress = async () => {
        await updateAccidentStatus({ id, status: 'IN_PROGRESS' });
        onClose();
    };

    const onNotConfirmed = async () => {
        await updateAccidentStatus({ id, status: 'NOT_CONFIRMED' });
        onClose();
    };

    const onSetPause = async () => {
        await updateAccidentStatus({ id, status: 'PAUSED' });
        onClose();
    };

    return (
        <>
            <Modal.Body className="scroll-modal-body">
                {mapsCreated && <AccidentModalMap coordinate={coordinate} />}
                <p>
                    <strong>
                        <Trans>Регион</Trans>:{' '}
                    </strong>
                    {
                        regionFilters.find(
                            ({ id }) => id === accident.region.id
                        )?.name
                    }
                </p>
                <p>
                    <strong>
                        <Trans>Адрес</Trans>:{' '}
                    </strong>
                    {accident.address}
                </p>
                {accident.device && (
                    <p>
                        <strong>
                            <Trans>ID самоката</Trans>:{' '}
                        </strong>
                        {accident.device.code}
                    </p>
                )}
                <p>
                    <strong>
                        <Trans>Дата и время инцидента</Trans>:{' '}
                    </strong>
                    {getFullDateTime(accident.committedAt, false, true)}
                </p>
                <p>
                    <strong>
                        <Trans>Тип инцидента</Trans>:{' '}
                    </strong>
                    {V.accidentTypeDict[accident.type]}
                </p>
                {accident.subtype && (
                    <p>
                        <strong>
                            <Trans>Вид инцидента</Trans>:{' '}
                        </strong>
                        {V.accidentSubtypeDict[accident.subtype]}
                    </p>
                )}
                {accident.cause && (
                    <p>
                        <strong>
                            <Trans>Причина инцидента</Trans>:{' '}
                        </strong>
                        {V.accidentCauseDict[accident.cause]}
                    </p>
                )}
                {accident.cause && accident.causeDetails?.length && (
                    <p>
                        <strong>
                            {V.accidentCauseTitleDict[accident.cause]}:{' '}
                        </strong>
                        {accident.causeDetails
                            .map(
                                (causeDetail) =>
                                    V.accidentCauseDetailDict[causeDetail]
                            )
                            .join(', ')}
                    </p>
                )}
                <p>
                    <strong>
                        <Trans>Экстренные службы на месте</Trans>:{' '}
                    </strong>
                    {accident.emergencyServices
                        .map((service) => V.emergencyServicesDict[service])
                        .join(', ')}
                </p>
                <p>
                    <strong>
                        <Trans>Тяжесть</Trans>:{' '}
                    </strong>
                    {accident.criticalCase
                        ? V.severityDict.CRITICAL
                        : V.severityDict[accident.severity]}
                </p>
                <div className={styles.descriptionWrapper}>
                    <strong>
                        <Trans>Описание</Trans>:{' '}
                    </strong>
                    <p className="m-0">{accident.description}</p>
                    {files.length ? (
                        <div className={styles.imagesBlock}>
                            {files.map(({ url, name, key, type }) => (
                                <AccidentImage
                                    key={key}
                                    src={url}
                                    alt={name}
                                    hideIcon
                                    onClick={() => onOpenImageModal(url)}
                                    type={type}
                                />
                            ))}
                        </div>
                    ) : null}
                    <Image
                        show={showImage}
                        handleClose={onCloseImageModal}
                        image={selectedImage}
                    />
                </div>
                <div className={styles.descriptionWrapper}>
                    <strong>
                        <Trans>Решение</Trans>:{' '}
                    </strong>
                    <p className="m-0">{accident.solution}</p>
                </div>
                <p>
                    <strong>
                        <Trans>ID треда</Trans>:{' '}
                    </strong>
                    {accident.threadId}
                </p>
                {accident.trip && (
                    <p>
                        <strong>
                            <Trans>ID поездки</Trans>:{' '}
                        </strong>
                        {accident.trip.paymentId}
                    </p>
                )}
                {accident.user?.id && (
                    <p>
                        <strong>
                            <Trans>Ответственный</Trans>:{' '}
                        </strong>
                        <a href={`/view/users/${accident.user.id}`}>
                            {accident.user.name}
                        </a>
                    </p>
                )}
            </Modal.Body>

            <ProtectedView permission={UserPermission.AccidentStatusEdit}>
                <Modal.Footer>
                    {accident.status !== 'IN_PROGRESS' ? (
                        <Button
                            size="sm"
                            variant="warning"
                            onClick={onGetInProgress}
                        >
                            <Trans>Взять в работу</Trans>
                        </Button>
                    ) : null}
                    {accident.status === 'IN_PROGRESS' ? (
                        <>
                            <Button
                                size="sm"
                                variant="danger"
                                onClick={onSetPause}
                            >
                                <Trans>Приостановить</Trans>
                            </Button>
                            <Button
                                size="sm"
                                variant="success"
                                onClick={setResolveMode}
                            >
                                <Trans>Решить</Trans>
                            </Button>
                        </>
                    ) : null}
                    {accident.status === 'NEW' ||
                    accident.status === 'IN_PROGRESS' ? (
                        <Button
                            size="sm"
                            variant="dark"
                            onClick={onNotConfirmed}
                        >
                            <Trans>Не подтвердился</Trans>
                        </Button>
                    ) : null}
                    <Button
                        size="sm"
                        onClick={setEditMode}
                        variant="outline-primary"
                    >
                        <Trans>Изменить</Trans>
                    </Button>
                </Modal.Footer>
            </ProtectedView>
        </>
    );
};

const mapStateToProps = ({ regionFilters }) => ({ regionFilters });

export const AccidentModalBody = connect(mapStateToProps, {
    updateAccidentStatus,
})(withUseMapsConnectionsHook(AccidentModalBodyComponent));
