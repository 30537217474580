export const processAllSettledPromise = (result) => {
    const fulfilledResult = [];
    const rejectedResult = [];
    result.forEach(({ status, value, reason }) => {
        if (status === 'fulfilled') return fulfilledResult.push(value);
        rejectedResult.push(reason);
    });
    if (rejectedResult.length)
        console.error('Some Promises was rejected', rejectedResult);
    return fulfilledResult;
};
