import * as T from 'ADMIN_COMMON/constants/types';
import { SIGN_IN } from '../constants/types';
import { Auth } from '@aws-amplify/auth';
import { t } from '@lingui/macro';

const AUTH_CODE_MAP = {
    NOT_AUTHORIZED: 'NotAuthorizedException',
};
const translateMessageFromError = (error) => {
    switch (error.code) {
        case AUTH_CODE_MAP.NOT_AUTHORIZED:
            return t`Неверное имя пользователя или пароль`;

        default:
            return error?.message ?? t`Что-то пошло не так`;
    }
};

/**
 * @param {{
 *  login: string,
 *  password: string,
 *  }} userInfo
 */
export const onSignIn =
    ({ login, password }) =>
    async (dispatch) => {
        try {
            await Auth.signIn(login, password);

            dispatch({ type: SIGN_IN + T.SUCCESS });
        } catch (error) {
            const message = translateMessageFromError(error);
            dispatch({ type: SIGN_IN + T.ERROR, payload: message });
        }
    };
