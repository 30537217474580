'use strict';

import './array-from';

(function (e) {
    e.collectValidData = function () {
        const inputs = Array.from(this.querySelectorAll('input, select'));
        const enabledInputs = inputs.filter(node => !node.disabled);
        const validInputs = enabledInputs.filter(node => node.validate(1));
        const map = validInputs.map(node => {
            if (node.type === 'checkbox') return `"${node.name}": ${node.checked}`;
            if (node.type === 'radio' && !node.checked) return false;
            if (node.name) return `"${node.name}": "${node.value}"`;
        });
        const purgedMap = map.filter(keyValue => !!keyValue);
        try {
            return JSON.parse(`{${purgedMap.join(',')}}`);
        } catch (error) {
            return null;
        }
    };
})(Element.prototype);
