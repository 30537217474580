import { configsID } from './asyncModuleIDs';
import { configs } from 'ADMIN_COMMON/reducers/configs';
import { requestWebappConfigs } from 'ADMIN_COMMON/ac/requestWebappConfigs';
import { requestAdminConfiguration } from 'ADMIN_COMMON/ac/requestAdminConfiguration';

export const getAsyncConfigsModule = () => ({
    id: configsID,
    reducerMap: { configs },
    initialActions: [requestWebappConfigs(), requestAdminConfiguration()],
});
