import React from 'react';
import { Helmet } from 'react-helmet';
import { LazyAccidentsContainer } from './Container';
import Provider from 'react-redux/es/components/Provider';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';
import { Outlet } from 'react-router-dom';

const PageContent = withWrappedPage(LazyAccidentsContainer);

export const PageAccidents = () => (
    <Provider store={createAsyncReduxStore()}>
        <Helmet>
            <title>{t`Инциденты`}</title>
        </Helmet>

        <div className="container-fluid modern-page">
            <div className="row">
                <main role="main" className="m-0 px-4 w-100">
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_accidents_react"></div>

                    <PageContent />
                </main>
            </div>
        </div>

        <Outlet />
    </Provider>
);
