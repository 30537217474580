import { t } from '@lingui/macro';
import { useMessages } from 'ADMIN_COMMON/hooks/useMessages';
import React from 'react';
import { Button } from 'react-bootstrap';

export const BatteriesChargeLevelTableRow = ({
    item,
    selected,
    onSelectRegion,
    onEditClick,
}) => {
    const { getMessage } = useMessages();

    return (
        <tr>
            <td style={{ textAlign: 'center' }}>
                <input
                    type="checkbox"
                    value={item.region.id}
                    onChange={onSelectRegion}
                    checked={selected}
                />
            </td>
            <td>
                {getMessage(
                    `region.name.${item.region.code}`,
                    item.region.name
                )}
            </td>
            <td>
                {item.value.maxLevel}
                {'%'}
            </td>
            <td style={{ textAlign: 'right' }}>
                <Button
                    size="sm"
                    variant="outline-dark"
                    data-region-id={item.region.id}
                    onClick={onEditClick}
                >
                    {t`Изменить`}
                </Button>
            </td>
        </tr>
    );
};
