'use strict';

import { insertNode } from 'UTILS/insertNode';
import { isDomNode } from 'UTILS/isDomNode';
import { isNodeList } from 'UTILS/isNodeList';

/**
 * @param {(Array|Element)} child
 * @param {Element} parent
 * @returns {(Array|Element)} child
 * */
function appendNode(child, parent) {
    if (isNodeList(child)) {
        const result = [];
        const childrenElements = [...Array.from(child)];
        while (childrenElements.length) {
            const node = childrenElements[0];
            const nodeResult = insertNode(node, parent);
            nodeResult && result.push(nodeResult);
            if (~childrenElements.indexOf(node))
                childrenElements.splice(childrenElements.indexOf(node), 1);
        }
        if (!result.length) return null;
        return result;
    }
    if (isDomNode(child)) return insertNode(child, parent);
    return null;
}

export { appendNode };
