'use strict';

import state from 'COMMON/State';
import { find } from 'UTILS/find';
import { isDomNode } from 'UTILS/isDomNode';
import { removeClass } from 'UTILS/removeClass';
import { removeNode } from 'UTILS/removeNode';

/**
 * @param {(Element|string)} target
 * */
function hideSpinner(target = document.body) {
    target = isDomNode(target)
        ? target
        : typeof target === 'string'
        ? find(target, document.body)[0] || document.body
        : document.body;

    const spinner = find('.js_spinner', target);

    if (!spinner.length) return;

    removeNode(spinner);
    removeClass('fadeout_enable', target);
    state.activeRequest = false;
}

export { hideSpinner };
