import { t } from '@lingui/macro';
import { Alert, Box, Button, Group, Text } from '@mantine/core';
import { onNewAppAlertToggle } from 'ADMIN_COMMON/ac/onNewAppAlertToggle';
import { env } from 'ADMIN_COMMON/constants/enviroments';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './new-app-alert.module.scss';
import { useAlertStyles } from './useAlertStyles';

export const NewAppAlert = ({ title, message, to }) => {
    const { classes } = useAlertStyles();
    const { isHidden } = useSelector((state) => state.newAppAlert);
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(onNewAppAlertToggle());
    };

    const arrow = isHidden ? (
        <Box className={classes.collapseButton} onClick={handleToggle}>
            <Box className={classes.icon}>
                <span className={'fa fa-exclamation fa-lg'} />
            </Box>
            <span className={`fa fa-chevron-left ${classes.chevron}`} />
        </Box>
    ) : (
        <Box className={classes.collapseButton} onClick={handleToggle}>
            <span className={`fa fa-chevron-right ${classes.chevron}`} />
        </Box>
    );

    const content = isHidden ? null : (
        <Group spacing={36} p="0 16px">
            <Group spacing={16}>
                <Text color="#F8D867" size={16} weight={600}>
                    {title || t`Внимание!`}
                </Text>
                <Text color="white">{message}</Text>
            </Group>
            <Button
                component="a"
                className={classes.button}
                href={`${env.NEW_APP_URL}${to}`}
                target="_blank"
            >
                {t`Admin 2.0`}
                <span className={styles.externalLinkIcon} />
            </Button>
        </Group>
    );

    return (
        <Alert className={classes.alert}>
            <Group>
                {arrow}
                {content}
            </Group>
        </Alert>
    );
};
