import { t } from '@lingui/macro';
import React, { useEffect, useRef } from 'react';

export const BatteriesChargeLevelTableHead = ({
    selected,
    list,
    onSelectAll,
}) => {
    const allCheckbox = useRef();

    useEffect(() => {
        allCheckbox.current.indeterminate =
            selected.size > 0 && selected.size < list.length;
    }, [selected, list]);

    return (
        <thead>
            <tr>
                <th style={{ width: '20px', textAlign: 'center' }}>
                    <input
                        type="checkbox"
                        ref={allCheckbox}
                        value="all"
                        checked={
                            selected.size > 0 && selected.size === list.length
                        }
                        onChange={onSelectAll}
                    />
                </th>
                <th>{t`Регион`}</th>
                <th>{t`Верхняя планка заряда батареи`}</th>
                <th style={{ width: '10%' }}></th>
            </tr>
        </thead>
    );
};
