import React, { Component } from 'react';
import { standardError } from 'UTILS/standardError';
import { MainHeader } from 'ADMIN_COMMON/components/MainHeader';
import { Trans } from '@lingui/macro';

class ErrorBoundary extends Component {
    state = {
        hasError: false,
        errorStack: '',
    };

    componentDidCatch(error) {
        const { isPageWrapper } = this.props;
        if (!isPageWrapper) standardError(error);
        this.setState({ hasError: true, errorStack: error.stack });
    }

    render() {
        const { hasError, errorStack } = this.state;
        const { children, isPageWrapper } = this.props;
        if (hasError && isPageWrapper)
            return (
                <>
                    <MainHeader />
                    <label className="d-flex flex-column mt-3">
                        <Trans>Что-то пошло не так</Trans>:
                        <textarea rows="25" disabled>
                            {errorStack}
                        </textarea>
                    </label>
                </>
            );
        return !hasError && children;
    }
}

export default ErrorBoundary;
