export class AuthError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}

export class IncorrectChallengeResponseError extends AuthError {
    name = 'IncorrectChallengeResponseError';
}

export class IncorrectPasswordError extends AuthError {
    name = 'IncorrectPasswordError';
}

export class UserBlockedError extends AuthError {
    name = 'UserBlockedError';
}

export class UserNotAuthorizedError extends AuthError {
    name = 'UserNotAuthorizedError';
}
