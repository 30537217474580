import React from 'react';
import { Helmet } from 'react-helmet';

import { LazyBatteriesContainer } from './Batteries';
import Provider from 'react-redux/es/components/Provider';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';

const PageContent = withWrappedPage(LazyBatteriesContainer);

export const PageBatteries = () => {
    return (
        <Provider store={createAsyncReduxStore()}>
            <Helmet>
                <title>{t`Список батареек`}</title>
            </Helmet>

            <div className="container-fluid js_main_container modern-page">
                <div className="row">
                    <main role="main" className="px-4 w-100">
                        <div className="js_request_success"></div>
                        <div className="js_request_error"></div>
                        <div className="js_battery_react"></div>

                        <PageContent />
                    </main>
                </div>
            </div>
        </Provider>
    );
};
