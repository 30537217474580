import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { dynamicActivate, locales } from '../../../i18n';

export const useChangeLocale = () => {
    const { i18n } = useLingui();
    const currentLocale = i18n.locale;
    const changeLocale = useCallback(
        async (locale) => {
            await dynamicActivate(locale);
        },
        [i18n]
    );

    return {
        currentLocale,
        changeLocale,
    };
};

export { locales };
