'use strict';

(function () {
    var forEachFunc = function (callback, thisArg) {
        let T; let k;
        if (this === null) {
            throw new TypeError(' this is null or not defined');
        }
        const O = Object(this);
        const len = O.length >>> 0;
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a function');
        }
        if (arguments.length > 1) {
            T = thisArg;
        }
        k = 0;
        while (k < len) {
            let kValue;
            if (k in O) {
                kValue = O[k];
                callback.call(T, kValue, k, O);
            }
            k++;
        }
    };
    if (!Array.prototype.forEach) {
        Array.prototype.forEach = forEachFunc;
    }
    if (!NodeList.prototype.forEach) {
        NodeList.prototype.forEach = forEachFunc;
    }
})();
