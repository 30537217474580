import { createContext, useContext } from 'react';

export const SessionContext = createContext(null);

/**
 * @returns {import('../core/Session').Session}
 */
export const useSession = () => {
    const context = useContext(SessionContext);

    if (!context) {
        throw new Error(
            'SessionContext: useSession should only be used inside a SessionProvider'
        );
    }

    return context;
};
