'use strict';

import { isDomNode } from 'UTILS/isDomNode';
import { isNodeList } from 'UTILS/isNodeList';

/**
 * @param {string} className
 * @param {(Array|Element)} node
 * @returns {(Array|Element)}
 * */
function addClass(className, node) {
    if (!node) return null;
    if (!className || typeof className !== 'string') return node;
    const classNames = className.trim().split(' ');
    if (isNodeList(node)) {
        const result = [];
        for (let i = 0; i < node.length; i++) {
            if (!isDomNode(node[i])) continue;
            for (let j = 0; j < classNames.length; j++) {
                if (!node[i].hasClass(classNames[j]))
                    node[i].className = [node[i].className, classNames[j]].join(
                        node[i].className ? ' ' : ''
                    );
            }
            result.push(node[i]);
        }
        if (!result.length) return null;
        return result;
    }
    if (isDomNode(node)) {
        for (let j = 0; j < classNames.length; j++) {
            if (!node.hasClass(classNames[j]))
                node.className = [node.className, classNames[j]].join(
                    node.className ? ' ' : ''
                );
        }
        return node;
    }
    return null;
}

export { addClass };
