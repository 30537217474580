import { t } from '@lingui/macro';
import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';

import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import Spinner from 'ADMIN_COMMON/components/Spinner';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';

const Container = lazy(() => import('./Container/Container'));
const PageContent = withWrappedPage(Container);

export const PageUser = () => (
    <Provider store={createAsyncReduxStore()}>
        <Helmet>
            <title>{t`Информация о пользователе`}</title>
        </Helmet>

        <div className="container-fluid js_main_container modern-page">
            <div className="row">
                <main role="main" className="px-4 w-100">
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_user_react"></div>

                    <Suspense fallback={<Spinner />}>
                        <PageContent />
                    </Suspense>
                </main>
            </div>
        </div>
    </Provider>
);
