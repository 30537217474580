import './src/components';
import { keysToReset } from 'ADMIN_COMMON/constants/localStorageKeys';
import { withSuspense } from 'ADMIN_COMMON/components/HOC/withSuspense';
import { lazy } from 'react';
// TODO remove old keys after 1.88 release
[...keysToReset, 'loggedUser', 'stealSuspicionNotificationsDisabled'].forEach(
    (key) => localStorage.removeItem(key)
);

const LazyContainer = lazy(() => import('../../auth/LoginPage'));
export const PageLogin = withSuspense(LazyContainer);
