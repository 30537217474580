'use strict';

function replaceWith (Element) {
    let i = arguments.length;
    const parent = this.parentNode;
    const firstIsNode = +(parent && typeof Element === 'object');

    if (!parent) return;

    while (i-- > firstIsNode) {
        if (parent && typeof arguments[i] !== 'object') {
            arguments[i] = document.createTextNode(arguments[i]);
        } if (!parent && arguments[i].parentNode) {
            arguments[i].parentNode.removeChild(arguments[i]);
            continue;
        }
        parent.insertBefore(this.previousSibling, arguments[i]);
    }
    if (firstIsNode) parent.replaceChild(Element, this);
}
if (!Element.prototype.replaceWith) Element.prototype.replaceWith = replaceWith;
if (!CharacterData.prototype.replaceWith) CharacterData.prototype.replaceWith = replaceWith;
if (!DocumentType.prototype.replaceWith) DocumentType.prototype.replaceWith = replaceWith;
