import { Plural, t } from '@lingui/macro';
import { useMessages } from 'ADMIN_COMMON/hooks/useMessages';
import axios from 'COMMON/axios';
import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { standardError } from 'UTILS/standardError';
import { BatteriesChargeLevelEditForm } from './BatteriesChargeLevelEditForm';

export const BatteriesChargeLevelEditModal = ({
    editingList,
    show,
    onHide,
}) => {
    const queryClient = useQueryClient();
    const { getMessage } = useMessages();

    const initialValues = useMemo(() => {
        const maxLevel =
            new Set(editingList.map((item) => item.value.maxLevel)).size === 1
                ? editingList[0].value.maxLevel
                : '';

        return {
            regionIds: editingList.map((item) => item.region.id),
            value: {
                maxLevel,
            },
        };
    }, [editingList]);

    const batteriesChargeLevelMutation = useMutation(
        async (values) => {
            try {
                const { data } = await axios.post(
                    '/charge-battery-limit',
                    values
                );

                return data;
            } catch (err) {
                standardError(err);
            }
        },
        {
            onSuccess: (data) => {
                if (Array.isArray(data.limits)) {
                    const updatedMap = new Map(
                        data.limits.map((item) => [item.region.id, item])
                    );
                    const prevData = queryClient.getQueryData(
                        'charge-battery-limit'
                    );

                    queryClient.setQueryData('charge-battery-limit', {
                        ...prevData,
                        limits: prevData.limits.map((item) =>
                            updatedMap.has(item.region.id)
                                ? updatedMap.get(item.region.id)
                                : item
                        ),
                    });
                }

                onHide();
            },
        }
    );

    const handleSubmit = useCallback(
        (values) => {
            batteriesChargeLevelMutation.mutate(values);
        },
        [batteriesChargeLevelMutation]
    );

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t`Изменение планки заряда батареи`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-1">
                    <Plural
                        one="Регион"
                        other="Регионы"
                        value={editingList.length}
                    />
                    {': '}
                    {editingList
                        .map((item) =>
                            getMessage(
                                `region.name.${item.region.code}`,
                                item.region.name
                            )
                        )
                        .join(', ')}
                </div>

                <BatteriesChargeLevelEditForm
                    formId="batteriesChargeLevelEdit"
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="submit"
                    form="batteriesChargeLevelEdit"
                    disabled={batteriesChargeLevelMutation.isLoading}
                >{t`Сохранить`}</Button>
                <Button onClick={onHide} variant="secondary">
                    {t`Отменить`}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
