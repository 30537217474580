'use strict';

(function (e) {
    e.parents = e.parents || function (selector) {
        const parents = [];
        let node = this.parentNode;

        while (node && node instanceof Element) {
            if (node.matches(selector)) {
                parents.push(node);
                node = node.parentNode;
            } else {
                node = node.parentNode;
            }
        }

        return parents;
    };
})(Element.prototype);
