import React from 'react';
import { NotificationsBlock } from 'ADMIN_COMMON/components/NotificationsBlock';
import ErrorBoundary from 'ADMIN_COMMON/components/ErrorBoundary';
import { MainHeader } from 'ADMIN_COMMON/components/MainHeader';

export const withWrappedPage = (WrappedComponent) => (props) =>
    (
        <ErrorBoundary isPageWrapper>
            <MainHeader />
            <NotificationsBlock />
            <WrappedComponent {...props} />
        </ErrorBoundary>
    );
