'use strict';

/**
 * @returns {Element} div
 * */
function createDiv() {
    return document.createElement('div');
}

export { createDiv };
