import * as V from 'ADMIN_ACCIDENTS/constants/variables';
import classNames from 'classnames';
import React from 'react';
import styles from './AccidentImage.module.scss';

export const AccidentImage = ({
    src,
    alt,
    onClick,
    hideIcon = false,
    type,
}) => (
    <div className={styles.imageWrapper}>
        {!hideIcon && <button onClick={onClick} />}
        {type === 'image/jpeg' || type === 'image/png' ? (
            <img
                src={src}
                alt={alt}
                height={48}
                width={48}
                onClick={hideIcon ? onClick : null}
            />
        ) : (
            <div
                className={classNames(
                    styles.filePreview,
                    styles[`${V.labelByFileType[type]}Preview`]
                )}
                onClick={hideIcon ? onClick : null}
            >
                <p>{V.labelByFileType[type]}</p>
                <span>{alt}</span>
            </div>
        )}
    </div>
);
