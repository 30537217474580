export const AuthStateName = {
    Idle: 'idle',
    Setup: 'setup',
    SignIn: 'signIn',
    ConfirmSignIn: 'confirmSignIn',
    Authenticated: 'authenticated',
    SignOut: 'signOut',
};

export const AuthChallengeType = {
    Password: 'password',
    Sms: 'sms',
    Push: 'push',
    Call: 'call',
    Voice: 'voice',
    Undefined: 'undefined',
};
