import { t } from '@lingui/macro';
import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';

import ErrorBoundary from 'ADMIN_COMMON/components/ErrorBoundary';
import { withSuspense } from 'ADMIN_COMMON/components/HOC/withSuspense';
import { NotificationsBlock } from 'ADMIN_COMMON/components/NotificationsBlock';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';

const PageContent = withSuspense(lazy(() => import('./Container')));

export const PageVirtualDevices = () => (
    <Provider store={createAsyncReduxStore(true)}>
        <Helmet>
            <title>{t`Виртуальные устройства`}</title>
        </Helmet>

        <ErrorBoundary isPageWrapper={true}>
            <NotificationsBlock />
            <PageContent />
        </ErrorBoundary>
    </Provider>
);
