import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { LazySSContainer } from './Container';
import { Provider } from 'react-redux';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';

const PageContent = withWrappedPage(LazySSContainer);

export const PageSS = () => {
    return (
        <Provider store={createAsyncReduxStore()}>
            <Helmet>
                <title>{t`SS устройства`}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <main
                        role="main"
                        className="col-md-9 ml-sm-auto col-lg-10 px-4"
                    >
                        <div className="js_request_success"></div>
                        <div className="js_request_error"></div>
                        <div className="js_ss_react"></div>

                        <PageContent />
                    </main>
                </div>
            </div>
        </Provider>
    );
};
