import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { closeNotification } from 'ADMIN_COMMON/ac/notificationMethods';
import Toast from 'react-bootstrap/Toast';
import styles from './NotificationToast.module.scss';
import { getFullDateTime } from 'UTILS/getFullDateTime';
import classnames from 'classnames';
import { t } from '@lingui/macro';

const NotificationToastComponent = ({
    notification: { timestamp, type, message, counter },
    closeNotification,
}) => {
    const [show, setShow] = useState(true);
    const onClose = useCallback(() => {
        setShow(false);
        closeNotification(timestamp);
    }, []);

    const notificationsTypeDict = {
        ERROR: t`Ошибка!`,
        SUCCESS: t`Успех!`,
    };
    return (
        <Toast
            show={show}
            key={timestamp}
            onClose={onClose}
            animation={false}
            className={classnames(styles.toast, styles[type])}
        >
            <Toast.Header>
                <strong className="mr-auto">
                    {notificationsTypeDict[type]}{' '}
                    {counter > 1 && `(${counter})`}
                </strong>
                <small>{getFullDateTime(timestamp, true, true)}</small>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    );
};

export const NotificationToast = connect(null, { closeNotification })(
    NotificationToastComponent
);
