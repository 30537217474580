import { Trans } from '@lingui/macro';
import { updateAccidentStatus } from 'ADMIN_ACCIDENTS/ac/updateAccidentStatus';
import DefaultModalInput from 'ADMIN_COMMON/components/DefaultModalInput';
import SwitchInput from 'ADMIN_COMMON/components/SwitchInput';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';

const ResolveAccidentFormComponent = ({
    disableResolveMode,
    updateAccidentStatus,
    accident,
    onClose,
}) => {
    const [hasComplaint, setHasComplaint] = useState(false);
    const [resolveComment, setResolveComment] = useState('');
    const onSubmit = async () => {
        await updateAccidentStatus({
            complaint: hasComplaint,
            comment: { message: resolveComment },
            status: 'SOLVED_UNKNOWN_RISK',
            id: accident.id,
        });
        disableResolveMode();
        onClose();
    };
    const onChangeComplaint = ({ target: { checked } }) =>
        setHasComplaint(checked);
    const onChangeComment = ({ target: { value } }) => setResolveComment(value);
    return (
        <>
            <Modal.Body>
                <SwitchInput
                    id="complaint"
                    onChange={onChangeComplaint}
                    checked={hasComplaint}
                    className="mb-3"
                >
                    <Trans>Наличие претензии к WHOOSH</Trans>
                </SwitchInput>
                <DefaultModalInput
                    id="resolveComment"
                    onChange={onChangeComment}
                    type="textarea"
                    value={resolveComment}
                >
                    <Trans>Добавить комментарий</Trans>:
                </DefaultModalInput>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={onSubmit}>
                    <Trans>Решить</Trans>
                </Button>
                <Button onClick={disableResolveMode} variant="outline-primary">
                    <Trans>Отменить</Trans>
                </Button>
            </Modal.Footer>
        </>
    );
};

export const ResolveAccidentForm = connect(null, { updateAccidentStatus })(
    ResolveAccidentFormComponent
);
