import React, { Fragment } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useObserver } from '~/common/reactive/useObserver';
import { AuthStateName } from '../../auth/AuthAdapter';

/**
 * @typedef {Object} Props
 * @property {import('../../auth/cognito/CognitoAuthAdapter').CognitoAuthAdapter} auth
 * @property {React.ReactNode} children
 */

/**
 * @type {React.FC<Props>}
 */
export const ProtectedRoute = ({ auth, children }) => {
    const authState = useObserver(auth.state);
    const location = useLocation();

    const isAuthenticated = authState.name === AuthStateName.Authenticated;

    if (isAuthenticated) {
        return children ? <Fragment>{children}</Fragment> : <Outlet />;
    }

    const redirectUri = `${location.pathname}${location.search}${location.hash}`;
    const search = new URLSearchParams();

    if (redirectUri !== '/') {
        search.set('redirect_uri', redirectUri);
    }

    const urlSearch = search.size ? `?${search.toString()}` : '';

    return <Navigate to={`/login${urlSearch}`} />;
};
