import * as T from 'ADMIN_COMMON/constants/types';
import { replaceObjectInArray } from 'UTILS/arrayUtils';

const ERROR = 'ERROR';
const SUCCESS = 'SUCCESS';
const throwNotification = (message, type) => (dispatch, getState) => {
    const { notifications } = getState();
    let counter = 1;
    const sameNotification = notifications.find(
        (notification) => notification.message === message
    );
    if (sameNotification) {
        counter += sameNotification.counter;
        const updatedNotifications = replaceObjectInArray(
            notifications,
            {
                ...sameNotification,
                timestamp: new Date().toISOString(),
                counter,
            },
            'message'
        );
        return dispatch({
            type: T.THROW_NOTIFICATION,
            payload: updatedNotifications,
        });
    }
    dispatch({
        type: T.THROW_NOTIFICATION,
        payload: [
            ...notifications,
            {
                message,
                timestamp: new Date().toISOString(),
                type,
                counter,
            },
        ],
    });
};

export const throwErrorNotification = (message) => (dispatch) =>
    dispatch(throwNotification(message, ERROR));

export const throwSuccessNotification = (message) => (dispatch) =>
    dispatch(throwNotification(message, SUCCESS));

export const closeNotification = (itemToDelete) => (dispatch, getState) => {
    const { notifications } = getState();
    const updatedNotifications = notifications.filter(
        ({ timestamp }) => timestamp !== itemToDelete
    );
    dispatch({ type: T.CLOSE_NOTIFICATION, payload: updatedNotifications });
};
export const closeAllNotifications = () => ({
    type: T.CLOSE_ALL_NOTIFICATIONS,
    payload: [],
});
