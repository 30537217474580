import * as COMMON_TYPES from 'ADMIN_COMMON/constants/types';
import { getBooleanItem } from 'UTILS/localStorageHelpers';

const initialState = { isHidden: getBooleanItem('isNewAppAlertHidden') };

export const newAppAlert = (state = initialState, { type, payload }) => {
    switch (type) {
        case COMMON_TYPES.NEW_APP_ALERT: {
            return payload;
        }
        default:
            return state;
    }
};
