import axios from 'axios';
import { Auth } from '@aws-amplify/auth';
import { getHeaders } from 'UTILS/requestInfo';
import { standardError } from 'UTILS/standardError';
import { env } from 'ADMIN_COMMON/constants/enviroments';
import { getErrorMessageByCode } from './utils/getErrorMessageByCode';

export const authFulfilledInterceptor = async (request) => {
    let currentSession;
    try {
        currentSession = await Auth.currentSession();
    } catch (e) {
        window.location.href = '/login';
        return Promise.reject('SESSION_EXPIRED');
    }
    const accessToken = currentSession?.getAccessToken().getJwtToken();
    const idToken = currentSession?.getIdToken().getJwtToken();

    const defaultHeaders = getHeaders();
    request.headers = {
        ...request.headers,
        ...defaultHeaders,
        'X-auth-token': accessToken,
        'X-id-token': idToken,
    };
    if (request.method !== 'get' || request.method !== 'delete') {
        request.data = request.data || null;
    }
    return request;
};

export const authRejectedInterceptor = (err) => Promise.reject(err);

export const responseFulfilledInterceptor = (response) => {
    if (response.data.error) {
        throw response.data.error;
    }

    return response;
};

export const responseRejectedInterceptor = async (error) => {
    if (error === 'SESSION_EXPIRED') return;

    const { message, code, response, config } = error;

    if (!response) {
        if (navigator.onLine) {
            standardError({
                message: `Access to request at ${config.url} has been blocked by CORS policy`,
            });
        } else {
            standardError({
                message: 'Network error: Offline',
            });
        }
    } else if (response?.data?.errors) {
        response.data.errors.map(standardError);
    } else if (response?.data?.error) {
        if (typeof response.data.error === 'object') {
            const {
                data: {
                    error: { code, message },
                },
            } = response;
            standardError({
                error: {
                    code: code || response.status || 400,
                    message: getErrorMessageByCode(code) || message,
                },
            });
        } else {
            standardError({
                error: {
                    code: response.status,
                    message: response.data.error,
                },
            });
        }
    } else {
        standardError({
            error: { message, code: code || response?.status || 400 },
        });
    }
    throw error;
};

const axiosInstance = axios.create({
    baseURL: env.baseURL,
});

axiosInstance.interceptors.request.use(
    authFulfilledInterceptor,
    authRejectedInterceptor
);

axiosInstance.interceptors.response.use(
    responseFulfilledInterceptor,
    responseRejectedInterceptor
);

export default {
    get: async (...data) => await axiosInstance.get(...data),
    post: async (...data) => await axiosInstance.post(...data),
    put: async (...data) => await axiosInstance.put(...data),
    delete: async (...data) => await axiosInstance.delete(...data),
    patch: async (...data) => await axiosInstance.patch(...data),
};
