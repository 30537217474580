import axios from 'COMMON/axios';
import { LOGGED_USER } from 'ADMIN_COMMON/constants/localStorageKeys';
import {
    getLocalStorageItem,
    saveLocalStorageItem,
} from 'UTILS/localStorageHelpers';

export const requestLoggedUser = async () => {
    const savedUser = getLocalStorageItem(LOGGED_USER);
    if (savedUser) return savedUser;
    try {
        const {
            data: { user },
        } = await axios.get('/users/logged');
        saveLocalStorageItem(LOGGED_USER, user);
        return user;
    } catch (e) {
        return null;
    }
};
