import React from 'react';
import { Helmet } from 'react-helmet';

import Provider from 'react-redux/es/components/Provider';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { LazySubscriptionsContainer } from './Container';

const PageContent = withWrappedPage(LazySubscriptionsContainer);

export const PageSubscriptions = () => {
    return (
        <Provider store={createAsyncReduxStore()}>
            <Helmet>
                <title>Подписки</title>
            </Helmet>

            <div className="container-fluid">
                <div className="row">
                    <main role="main" className="w-100">
                        <div className="js_request_success"></div>
                        <div className="js_request_error"></div>
                        <div className="js_subscriptions_react"></div>

                        <PageContent />
                    </main>
                </div>
            </div>
        </Provider>
    );
};
