'use strict';

import './array-from';
import { isDisplayed } from 'UTILS/isDisplayed';

(function (e) {
    e.validateContainer = function (silent, ignoreFocusFirst) {
        let valid = true; let first;
        const nodes = Array.from(this.querySelectorAll('[data-validator]:not([disabled]), span[data-validator]'));
        const visibleNodes = nodes.filter(node => isDisplayed(node));
        const inputs = visibleNodes.map(node => node.hasClass('selectboxit-btn') ? node.parentNode.nextElementSibling : node);

        for (let i = 0; i < inputs.length; i++) {
            const validInput = inputs[i].validate(silent);
            if (valid && !validInput) first = inputs[i];
            valid = inputs[i].validate(silent) && valid;
        }

        if ((!silent && !valid) && !ignoreFocusFirst) setTimeout(() => first.focus(), 10);

        return valid;
    };
})(Element.prototype);
