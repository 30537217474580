'use strict';

import { createDiv } from 'UTILS/createDiv';
import { isDomNode } from 'UTILS/isDomNode';

/**
 * @param {string} string
 * @returns {(Element|Array)}
 * */
function createNode(string) {
    const div = createDiv();
    const result = [];

    if (!string || typeof string !== 'string') return null;

    string = string.trim();
    div.innerHTML = string;

    if (!string.length || !div.childElementCount) return null;

    for (let i = 0; i < div.childNodes.length; i++) {
        if (isDomNode(div.childNodes[i])) result.push(div.childNodes[i]);
    }

    if (!result.length) return null;
    if (result.length === 1) return result[0];
    return result;
}

export { createNode };
