import { t } from '@lingui/macro';
import { getAsyncNewAppAlertModule } from 'ADMIN_COMMON/asyncReducerModules/getAsyncNewAppAlertModule';
import { withDynamicModuleLoader } from 'ADMIN_COMMON/components/HOC/withDynamicModuleLoader';
import { NewAppAlert } from 'ADMIN_COMMON/components/NewAppAlert';
import Spinner from 'ADMIN_COMMON/components/Spinner';
import axios from 'COMMON/axios';
import React from 'react';
import { useQuery } from 'react-query';
import { BatteriesChargeLevelTable } from './BatteriesChargeLevelTable';

const BatteriesChargeLevelContainer = () => {
    const chargeBatteryLimitResult = useQuery(
        'charge-battery-limit',
        async () => {
            const { data } = await axios.get('/charge-battery-limit');

            return data;
        }
    );

    if (chargeBatteryLimitResult.isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <h2 className="pt-3">{t`Управление зарядом батареи`}</h2>

            <BatteriesChargeLevelTable
                list={
                    chargeBatteryLimitResult.isSuccess
                        ? chargeBatteryLimitResult.data.limits
                        : []
                }
            />

            <NewAppAlert
                message={t`Управление зарядом батареи переехало в Admin 2.0`}
                to={'/batteries-charge-level'}
            />
        </>
    );
};

export default withDynamicModuleLoader(BatteriesChargeLevelContainer, [
    getAsyncNewAppAlertModule(),
]);
