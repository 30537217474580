import React from 'react';
import Form from 'react-bootstrap/Form';

const DefaultModalInput = ({
    id,
    children,
    type = 'text',
    required = false,
    invalidFeedback = '',
    onChange,
    onBlur,
    onKeyDown,
    value,
    pattern = '.*',
    isInvalid = false,
    disabled = false,
    className = '',
    hideLabel = false,
    rows = 2,
    name,
    placeholder,
    min,
    max,
    step,
}) => (
    <Form.Group md="4" controlId={id} className={className}>
        {!hideLabel && <Form.Label>{children}</Form.Label>}
        <Form.Control
            {...(type === 'textarea' ? { as: type } : { type })}
            required={required}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value}
            pattern={pattern}
            isInvalid={isInvalid || Boolean(invalidFeedback)}
            disabled={disabled}
            rows={rows}
            name={name}
            placeholder={placeholder}
            min={min}
            max={max}
            step={step}
        />
        <Form.Control.Feedback type="invalid">
            {invalidFeedback}
        </Form.Control.Feedback>
    </Form.Group>
);

export default DefaultModalInput;
