import { UserPermission } from './UserPermission';
import { UserRole } from './UserRole';

/**
 * @typedef {Object} PermissionRuleObject
 * @property {UserRole} role
 * @property {Boolean} isPrimary
 *
 * @typedef {PermissionRuleObject|UserRole} PermissionRule
 */

/**
 * @type {Record<UserPermission, PermissionRule[]>}
 * @see https://confluence.whoosh-cloud.com/pages/viewpage.action?pageId=731185184
 */
export const permissions = {
    [UserPermission.AccidentStatusEdit]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
        UserRole.SecondLine,
        UserRole.Alarm,
    ],

    [UserPermission.Batteries]: [UserRole.SuperAdmin, UserRole.Admin],

    [UserPermission.BatteriesChargeLevel]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
    ],

    [UserPermission.CarEdit]: [UserRole.SuperAdmin, UserRole.Admin],

    [UserPermission.DailyPass]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.SecondLine,
    ],
    [UserPermission.DailyPassEdit]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],
    [UserPermission.DailyPassRefund]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.SecondLine,
    ],

    [UserPermission.Devices]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.Alarm,
        UserRole.FirstLine,
        UserRole.Operator,
        UserRole.SecondLine,
    ],
    [UserPermission.DeviceChangeStatusToDeleted]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
        { role: UserRole.FirstLine, isPrimary: true },
        { role: UserRole.SecondLine, isPrimary: true },
        { role: UserRole.Operator, isPrimary: true },
        { role: UserRole.Alarm, isPrimary: true },
    ],
    [UserPermission.DeviceChangeStatusToForwarding]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.SecondLine,
    ],
    [UserPermission.DeviceChangeStatusToFrozen]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
    ],
    [UserPermission.DeviceChangeStatusToMaintenance]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
        { role: UserRole.FirstLine, isPrimary: true },
        { role: UserRole.SecondLine, isPrimary: true },
        { role: UserRole.Operator, isPrimary: true },
        { role: UserRole.Alarm, isPrimary: true },
    ],
    [UserPermission.DeviceChangeStatusToNew]: [UserRole.SuperAdmin],
    [UserPermission.DeviceDownload]: [UserRole.SuperAdmin, UserRole.Admin],
    [UserPermission.DeviceEdit]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
        { role: UserRole.FirstLine, isPrimary: true },
        { role: UserRole.SecondLine, isPrimary: true },
        { role: UserRole.Operator, isPrimary: true },
        { role: UserRole.Alarm, isPrimary: true },
    ],
    [UserPermission.DeviceMassActions]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.SecondLine,
        UserRole.Alarm,
    ],

    [UserPermission.DeviceLabels]: [UserRole.SuperAdmin, UserRole.Admin],

    [UserPermission.ExternalFunctionalAreas]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
    ],

    [UserPermission.FirmwareUpdate]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],

    [UserPermission.Organizations]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],

    [UserPermission.ParkingEdit]: [UserRole.SuperAdmin, UserRole.Admin],

    [UserPermission.Partners]: [UserRole.SuperAdmin, UserRole.Admin],

    [UserPermission.Promocodes]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
        { role: UserRole.FirstLine, isPrimary: true },
        { role: UserRole.SecondLine, isPrimary: true },
        { role: UserRole.Operator, isPrimary: true },
        { role: UserRole.Alarm, isPrimary: true },
    ],
    [UserPermission.PromocodesEdit]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],
    [UserPermission.PromocodesSensitiveFilters]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],

    [UserPermission.Penalties]: [UserRole.SuperAdmin, UserRole.Admin],
    [UserPermission.PenaltiesEdit]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],

    [UserPermission.Regions]: [UserRole.SuperAdmin, UserRole.Admin],
    [UserPermission.RegionsEdit]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],
    [UserPermission.RegionsStatusEdit]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],

    [UserPermission.ServiceCars]: [UserRole.SuperAdmin, UserRole.Admin],

    [UserPermission.StealSuspicion]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.SecondLine,
        UserRole.Alarm,
    ],
    [UserPermission.StealSuspicionMassActions]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
        UserRole.SecondLine,
        UserRole.Alarm,
    ],

    [UserPermission.Subscriptions]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
        UserRole.SecondLine,
    ],

    [UserPermission.Tariffs]: [UserRole.SuperAdmin, UserRole.Admin],
    [UserPermission.TariffsEdit]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],

    [UserPermission.Terms]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],

    [UserPermission.TripDangerActions]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],
    [UserPermission.TripPenaltiesIssue]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
        UserRole.SecondLine,
    ],
    [UserPermission.TripShorten]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
        { role: UserRole.FirstLine, isPrimary: true },
        { role: UserRole.SecondLine, isPrimary: true },
        { role: UserRole.Operator, isPrimary: true },
        { role: UserRole.Alarm, isPrimary: true },
    ],

    [UserPermission.UserBlock]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.SecondLine,
        UserRole.Alarm,
    ],
    [UserPermission.UserCreate]: [UserRole.SuperAdmin],
    [UserPermission.UserDelete]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],
    [UserPermission.UserEditSensitive]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],
    [UserPermission.UserPolicyEdit]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],
    [UserPermission.UserSubscriptionPause]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.SecondLine,
    ],
    [UserPermission.UserSubscriptionRefund]: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.SecondLine,
    ],

    [UserPermission.VirtualDevices]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],

    [UserPermission.Warehouses]: [UserRole.SuperAdmin, UserRole.Admin],
    [UserPermission.WarehouseEdit]: [UserRole.SuperAdmin, UserRole.Admin],

    [UserPermission.Works]: [
        UserRole.SuperAdmin,
        { role: UserRole.Admin, isPrimary: true },
    ],

    [UserPermission.ZoneEdit]: [UserRole.SuperAdmin, UserRole.Admin],
    [UserPermission.ZoneDownload]: [UserRole.SuperAdmin, UserRole.Admin],
};
