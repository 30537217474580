export const loginUserId = 'loginUser';
export const loggedUserID = 'loggedUser';
export const notificationsID = 'notifications';
export const subscriptionsID = 'subscriptions';
export const subscriptionID = 'subscription';
export const subscriptionHistoryID = 'subscriptionHistory';
export const regionFiltersID = 'regionFilters';
export const SSDevicesID = 'SSDevices';
export const SSFiltersID = 'SSFilters';
export const mapActionsID = 'mapActions';
export const batteriesConfigID = 'batteriesConfig';
export const batteryID = 'battery';
export const removableBatteryStatesID = 'removableBatteryStates';
export const removableBatteryFiltersID = 'removableBatteryFilters';
export const parkingPartnersID = 'parkingPartners';
export const firmwareDevicesID = 'firmwareDevices';
export const firmwareFiltersID = 'firmwareFilters';
export const firmwareConfigID = 'firmwareConfig';
export const labelsID = 'labels';
export const actionsID = 'actions';
export const organizationPageFiltersID = 'organizationPageFilters';
export const organizationsID = 'organizations';
export const parkingsID = 'parkings';
export const parkingPageFormsID = 'parkingPageForms';
export const parkingPageFiltersID = 'parkingPageFilters';
export const warehousesID = 'warehouses';
export const deviceModelsID = 'deviceModels';
export const batteryModelsID = 'batteryModels';
export const organizationFiltersID = 'organizationFilters';
export const penaltiesID = 'penalties';
export const penaltyTariffsID = 'penaltyTariffs';
export const penaltiesSortID = 'penaltiesSort';
export const penaltiesFiltersID = 'penaltiesFilters';
export const promoFormsID = 'promoForms';
export const promosID = 'promos';
export const tariffsID = 'tariffs';
export const tripsID = 'trips';
export const tripFiltersID = 'tripFilters';
export const debtsFormID = 'debtsForm';
export const regionsID = 'regions';
export const deviceForTripID = 'deviceForTrip';
export const transactionID = 'transaction';
export const tripCommentsID = 'tripComments';
export const tripID = 'trip';
export const tripPenaltiesID = 'tripPenalties';
export const trackID = 'track';
export const usersID = 'users';
export const usersFiltersID = 'usersFilters';
export const blockUserID = 'blockUser';
export const operationAreasID = 'operationAreas';
export const deviceStatesID = 'deviceStates';
export const deviceStateFiltersID = 'deviceStateFilters';
export const deviceID = 'device';
export const deviceErrorsID = 'deviceErrors';
export const dynamicStateID = 'dynamicState';
export const deviceCommentsID = 'deviceComments';
export const locksID = 'locks';
export const devicePageFormsID = 'devicePageForms';
export const carsID = 'cars';
export const devicesID = 'devices';
export const homePageFiltersID = 'homePageFilters';
export const homePageFormsID = 'homePageForms';
export const searchDevicesID = 'searchDevices';
export const userID = 'user';
export const userCommentsID = 'userComments';
export const configsID = 'configs';
export const accidentsID = 'accidents';
export const accidentFiltersID = 'accidentFilters';
export const accidentAssigneesID = 'accidentAssignees';
export const accidentTypesID = 'accidentTypes';
export const signalsStealSuspicionID = 'signalsStealSuspicion';
export const signalsStealSuspicionSortID = 'signalsStealSuspicionSort';
export const signalsStealSuspicionFiltersID = 'signalsStealSuspicionFilters';
export const signalsStealSuspicionPresumptiveReasonsID =
    'signalsStealSuspicionPresumptiveReasons';
export const signalsStealSuspicionRealReasonsID =
    'signalsStealSuspicionRealReasons';
export const workDictionariesID = 'workDictionaries';
export const workFiltersID = 'workFilters';
export const dailyPassesID = 'dailyPasses';
export const dailyPassID = 'DailyPass';
export const dailyPassHistoryID = 'dailyPassHistory';
export const deletedAreasID = 'deletedAreas';
export const accidentAssigneesFilterID = 'accidentAssigneesFilter';
export const termsID = 'terms';
export const parkingManagersID = 'parkingManagers';
export const parkingOwnersID = 'parkingOwners';
export const parkingAdministrativeStatusesID = 'parkingAdministrativeStatuses';
export const parkingAdministrativeAgreementTypesID =
    'parkingAdministrativeAgreementTypes';
export const newAppAlertID = 'newAppAlert';
