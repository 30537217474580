import { SS_NOTIFICATION_DISABLED } from 'ADMIN_COMMON/constants/localStorageKeys';

export const saveLocalStorageItem = (key, value) => {
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
};

export const getLocalStorageItem = (key) =>
    JSON.parse(localStorage.getItem(key));

export const removeLocalStorageItem = (key) => localStorage.removeItem(key);

export const getBooleanItem = (key) => getLocalStorageItem(key) || false;

export const getSSNotificationsStatus = () => {
    const isDisabled = getLocalStorageItem(SS_NOTIFICATION_DISABLED);
    return isDisabled === null ? true : isDisabled;
};
