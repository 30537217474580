import { Trans } from '@lingui/macro';
import { postAccidentDuplicates } from 'ADMIN_ACCIDENTS/ac/postAccidentDuplicates';
import { AccidentStatus } from 'ADMIN_ACCIDENTS/components/Container/AccidentStatus';
import { useInitialProps } from 'ADMIN_COMMON/hooks/useInitialProps';
import { getFullDateTime } from 'UTILS/getFullDateTime';
import React, { useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import styles from './DuplicateForm.module.scss';

const DuplicateFormComponent = ({
    accidents,
    onClose,
    postAccidentDuplicates,
}) => {
    const alreadyDuplicated = useMemo(
        () =>
            accidents.reduce((values, { id, duplicate }) => {
                if (duplicate) values.push(id);
                return values;
            }, []),
        [accidents]
    );
    const [duplicates, setDuplicates] = useInitialProps(alreadyDuplicated);
    const onChangeDuplicate = ({ target: { value, checked } }) => {
        const setOfIds = new Set(duplicates);
        checked ? setOfIds.add(value) : setOfIds.delete(value);
        setDuplicates([...setOfIds]);
    };
    const onSubmitDuplicates = async () => {
        const processedDuplicates = accidents.reduce(
            (values, { id, duplicate }) => {
                if (duplicate) {
                    values[id] = duplicates.includes(id);
                    return values;
                }
                if (duplicates.includes(id)) values[id] = true;
                return values;
            },
            {}
        );
        await postAccidentDuplicates(processedDuplicates);
        onClose();
    };
    return (
        <>
            <Modal.Body className="scroll-modal-body">
                <p className="mb-2">
                    <Trans>Укажите дубликат</Trans>:
                </p>
                <div className={styles.tableWrapper}>
                    <table className={styles.table}>
                        <tbody>
                            {accidents.map(({ id, committedAt, status }) => (
                                <tr key={id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            value={id}
                                            onChange={onChangeDuplicate}
                                            checked={duplicates.includes(id)}
                                        />
                                    </td>
                                    <td>{id}</td>
                                    <td>
                                        {getFullDateTime(
                                            committedAt,
                                            false,
                                            true
                                        )}
                                    </td>
                                    <td>
                                        <AccidentStatus status={status} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={onSubmitDuplicates}>
                    <Trans>Сохранить изменения</Trans>
                </Button>
                <Button onClick={onClose} variant="secondary">
                    <Trans>Назад</Trans>
                </Button>
            </Modal.Footer>
        </>
    );
};
const mapStateToProps = ({ accidents: { accidents } }) => ({
    accidents: accidents.filter(
        ({ status }) => status !== 'SOLVED_UNKNOWN_RISK'
    ),
});

export const DuplicateForm = connect(mapStateToProps, {
    postAccidentDuplicates,
})(DuplicateFormComponent);
