import '../polyfills/array-from';
import '../polyfills/array-for-each';
import '../polyfills/array-is-array';
import '../polyfills/array-find';
import '../polyfills/array-unique';

import '../polyfills/element-matches';
import '../polyfills/element-closest';
import '../polyfills/element-get-dataset';
import '../polyfills/element-parents';
import '../polyfills/element-childOf';
import '../polyfills/element-has-class';

import '../polyfills/date';

import '../polyfills/string-tansliterate';
import '../polyfills/string-capitalize';

import '../polyfills/number-format-money';

import '../polyfills/object-assign';
import '../polyfills/object-values';

import '../polyfills/custom-event';

import '../polyfills/replace-with';

import '../polyfills/request-animation-frame';

import '../polyfills/element-collect-valid-data';
import '../polyfills/element-validate';
import '../polyfills/element-validate-container';

import '../JqueryPlugins';
import { debounce } from 'UTILS/debounce';
import { sessionExpired } from 'UTILS/sessionExpired';
import { removeLoaderWrapper } from 'UTILS/removeLoaderWrapper';

window.page = {};
window.debounce = debounce;
window.sessionExpired = sessionExpired;

removeLoaderWrapper();
