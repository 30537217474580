'use strict';

import { getSearchContext } from 'UTILS/getSearchContext';

/**
 * @param {String} searchSelector
 * @param {(string|Element|NodeList|HTMLCollection|Array)=} source
 * @returns {Array}
 * */
function find(searchSelector, source) {
    const result = [];
    const contexts = getSearchContext(source);
    for (let i = 0; i < contexts.length; i++) {
        result.push(
            ...Array.from(contexts[i].querySelectorAll(searchSelector))
        );
    }
    return result;
}

export { find };
