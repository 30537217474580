'use strict';

/**
 * @param {function} callback
 * @param {number} wait
 * @param {boolean=} immediate
 * @returns {function}
 * */
function debounce(callback, wait, immediate) {
    let timeout;
    return function (...args) {
        const context = this;
        const callNow = immediate && !timeout;
        const later = function () {
            timeout = null;
            if (!immediate) callback.apply(context, args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) callback.apply(context, args);
    };
}

export { debounce };
