import { UserPermission } from '~/session';

export const menuItems = [
    {
        code: 'menu.map',
        path: '/home',
    },
    {
        code: 'menu.users',
        path: '/users',
    },
    {
        code: 'menu.trips',
        path: '/trips/view',
    },
    {
        code: 'menu.regions',
        path: '/regions/view',
        permission: UserPermission.Regions,
    },
    {
        code: 'menu.tariffs',
        path: '/tariffs',
        permission: UserPermission.Tariffs,
    },
    {
        code: 'menu.promo',
        path: '/promo',
        permission: UserPermission.Promocodes,
    },
    {
        code: 'menu.penalty',
        path: '/penalties',
        permission: UserPermission.Penalties,
    },
    {
        code: 'menu.parkings',
        path: '/parkings',
    },
    {
        code: 'menu.organizations',
        path: '/organizations',
        permission: UserPermission.Organizations,
    },
    {
        code: 'menu.labels',
        path: '/labels',
        permission: UserPermission.DeviceLabels,
    },
    {
        code: 'menu.parking.partners',
        path: '/parking-partners',
        permission: UserPermission.Partners,
    },
    {
        code: 'menu.firmware.update',
        path: '/firmware-update',
        permission: UserPermission.FirmwareUpdate,
    },
    {
        code: 'menu.batteries',
        path: '/removable-batteries',
        permission: UserPermission.Batteries,
    },
    {
        code: 'menu.ss',
        path: '/ss-devices',
        permission: UserPermission.StealSuspicion,
    },
    {
        code: 'menu.virtual.devices',
        path: '/virtual/devices/view',
        permission: UserPermission.VirtualDevices,
    },
    {
        code: 'menu.subscriptions',
        path: '/subscriptions',
        permission: UserPermission.Subscriptions,
    },
    {
        code: 'menu.accidents',
        path: '/accidents',
    },
    {
        code: 'menu.ss.old',
        path: '/ss-devices/old',
        permission: UserPermission.StealSuspicion,
    },
    {
        code: 'menu.work',
        path: '/work',
        permission: UserPermission.Works,
    },
    {
        code: 'menu.dailypass',
        path: '/daily-passes',
        permission: UserPermission.DailyPass,
    },
    {
        code: 'menu.terms',
        path: '/terms',
        permission: UserPermission.Terms,
    },
    {
        code: 'menu.batteries.charge.level',
        path: '/batteries-charge-level',
        permission: UserPermission.BatteriesChargeLevel,
    },
];
