import React from 'react';

const SwitchInput = ({
    checked,
    onChange,
    id,
    children,
    className = '',
    readOnly = false,
}) => {
    return (
        <span className="switch">
            <input
                type="checkbox"
                className={`switch ${className}`}
                id={id}
                onChange={onChange}
                checked={checked}
                readOnly={readOnly}
            />
            <label htmlFor={id}>{children}</label>
        </span>
    );
};

export default SwitchInput;
