import React, { useCallback, useMemo, useState } from 'react';
import styles from './DateInput.module.scss';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import classNames from 'classnames';
import moment from 'moment';
import Form from 'react-bootstrap/Form';

const format = 'DD-MM-YYYY HH:mm';
const DateInput = ({
    momentDate,
    onChange,
    maxDate,
    minDate,
    disabled = false,
    isShowInput = true,
    className = '',
    isInvalid = false,
    invalidFeedback = 'Ошибка',
}) => {
    const [isOnFocus, setIsOnFocus] = useState(false);
    const [inputFocusValue, setInputFocusValue] = useState('');
    const inputValue = useMemo(() => {
        if (isOnFocus) return inputFocusValue;
        if (!isShowInput) return '';
        return momentDate.utc().format(format);
    }, [isShowInput, isOnFocus, inputFocusValue, momentDate]);
    const onChangeInput = useCallback(
        ({ target: { value } }) => setInputFocusValue(value),
        []
    );
    const onFocus = useCallback((e) => {
        setIsOnFocus(true);
        onChangeInput(e);
    }, []);
    const onBlur = useCallback(
        ({ target: { value } }) => {
            if (value === '') onChange('');
            if (moment.utc(value, format).isValid())
                onChange(moment.utc(value, format));
            setIsOnFocus(false);
        },
        [onChange]
    );
    return (
        <>
            <DatetimePickerTrigger
                moment={momentDate}
                onChange={onChange}
                className={classNames(styles.customDatePicker, className, {
                    [styles.invalidDatePicker]: isInvalid,
                })}
                maxDate={maxDate}
                minDate={minDate}
                disabled={disabled}
            >
                <>
                    <input
                        className={styles.dateInput}
                        type="text"
                        value={inputValue}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onChange={onChangeInput}
                        disabled={disabled}
                    />
                    <span
                        className={`fa fa-calendar-o ${styles.calendarIcon}`}
                    />
                </>
            </DatetimePickerTrigger>
            {isInvalid && (
                <Form.Text className={styles.invalidFeedback}>
                    {invalidFeedback}
                </Form.Text>
            )}
        </>
    );
};

export default DateInput;
