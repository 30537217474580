import React, { useEffect, useState } from 'react';
import ErrorBoundary from 'ADMIN_COMMON/components/ErrorBoundary';

export const LazyComponentsWrapper = ({ show, children }) => {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (show) setLoaded(true);
    }, [show]);
    return <ErrorBoundary>{loaded && children}</ErrorBoundary>;
};
