import { Plural, Trans, t } from '@lingui/macro';
import { AccidentImage } from 'ADMIN_ACCIDENTS/components/Container/AccidentModals/AccidentImage';
import { EditAccidentModalMap } from 'ADMIN_ACCIDENTS/components/Container/AccidentModals/EditAccidentModalBody/EditAccidentModalMap';
import * as V from 'ADMIN_ACCIDENTS/constants/variables';
import { processAccidents } from 'ADMIN_ACCIDENTS/utils/processAccidents';
import DateInput from 'ADMIN_COMMON/components/DateInput';
import DefaultModalInput from 'ADMIN_COMMON/components/DefaultModalInput';
import DefaultModalSelect from 'ADMIN_COMMON/components/DefaultModalSelect';
import regionStyle from 'ADMIN_COMMON/components/Filters/FilterWrapperStyles.module.scss';
import { RegionFilter } from 'ADMIN_COMMON/components/Filters/RegionFilter';
import { withUseMapsConnectionsHook } from 'ADMIN_COMMON/components/HOC/withUseMapsConnectionsHook';
import MultiSelect from 'ADMIN_COMMON/components/MultiSelect';
import SwitchInput from 'ADMIN_COMMON/components/SwitchInput';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { Component, createRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-dropdown-select';
import { connect } from 'react-redux';
import styles from './EditAccidentModalBody.module.scss';

class EditAccidentModalBodyComponent extends Component {
    constructor(props) {
        super(props);
        const { accident, regionFilters } = props;
        if (props.accident) {
            this.state = {
                region: accident.region.id,
                address: accident.address,
                threadId: accident.threadId,
                deviceCode: accident.device?.code || '',
                participants: accident.participants,
                severity: accident.criticalCase
                    ? 'CRITICAL'
                    : accident.severity,
                emergencyServices: accident.emergencyServices,
                description: accident.description,
                solution: accident.solution,
                files: accident.files,
                type: accident.type,
                cause: accident.cause,
                causeDetails: accident.causeDetails || [],
                subtype: accident.subtype,
                paymentId: accident.trip?.paymentId || '',
                committedAtMomentDate: moment.utc(accident.committedAt),
                assignee: accident.user
                    ? [{ value: accident.user.id, label: accident.user.name }]
                    : [V.assigneeDefaultValue],
                addressMarkerCoords: [],
                enteredAddress: '',
            };
        } else {
            this.state = {
                region: regionFilters?.[0].id || '',
                address: '',
                threadId: '',
                deviceCode: '',
                participants: [],
                severity: V.severityOptions[0].value,
                emergencyServices: [],
                description: '',
                solution: '',
                files: [],
                type: '',
                cause: '',
                causeDetails: [],
                subtype: '',
                paymentId: '',
                committedAtMomentDate: moment.utc(),
                assignee: [V.assigneeDefaultValue],
                addressMarkerCoords: [],
                enteredAddress: '',
            };
        }
        this.addressInputRef = createRef();
        this.filesInputRef = createRef();
    }

    componentDidMount() {
        if (this.state.address) this.setAddressInputValue(this.state.address);
    }

    updateCoordinates = (coords) => {
        this.setState((oldState) => ({
            ...oldState,
            addressMarkerCoords: coords,
        }));
    };

    onChangeAddress = debounce(({ target: { value } }) => {
        if (value.length < 3) return;
        this.setState((oldState) => ({ ...oldState, enteredAddress: value }));
    }, 1000);

    setAddressInputValue = (value) => {
        this.addressInputRef.current.value = value;
        this.setState((oldState) => ({ ...oldState, address: value }));
    };

    onChange = ({ target: { value, id } }) => {
        this.setState((oldState) => ({ ...oldState, [id]: value }));
    };

    onChangeType = ({ target: { value } }) => {
        this.setState((oldState) => ({
            ...oldState,
            type: value,
            subtype: '',
            cause: '',
            causeDetails: [],
        }));
    };

    onChangeCause = ({ target: { value } }) => {
        this.setState((oldState) => ({
            ...oldState,
            cause: value,
            causeDetails: [],
        }));
    };

    onChangeCauseDetails = (value) => {
        this.setState((oldState) => ({
            ...oldState,
            causeDetails: value.map(({ value }) => value),
        }));
    };

    onChangeSwitch = (key, id, checked) => {
        this.setState((oldState) => {
            const optionsSet = new Set(oldState[key]);
            checked ? optionsSet.add(id) : optionsSet.delete(id);
            return { ...oldState, [key]: [...optionsSet] };
        });
    };

    onChangeCheckbox = ({ target: { id, checked } }) => {
        this.setState((oldState) => ({ ...oldState, [id]: checked }));
    };

    onChangeEmergencyServices = ({ target: { id, checked } }) =>
        this.onChangeSwitch('emergencyServices', id, checked);

    onChangeFileInput = ({ target: { files } }) => {
        this.setState((oldState) => ({
            ...oldState,
            files: [...oldState.files, ...Array.from(files)],
        }));
    };
    onDeleteImage = (indexToDelete) => {
        this.setState((oldState) => ({
            ...oldState,
            files: oldState.files.filter(
                (item, index) => indexToDelete !== index
            ),
        }));
    };

    onSubmit = () => {
        const { committedAtMomentDate, files, addressMarkerCoords, ...state } =
            this.state;
        const { accident } = this.props;
        const processedAccident = accident
            ? processAccidents(
                  {
                      ...state,
                      internalId: accident.internalId,
                      id: accident.id,
                      committedAt: committedAtMomentDate.toDate(),
                      status: accident.status,
                      files: files.filter((image) => image instanceof File),
                      filesToDelete: accident.files.filter(
                          ({ url }) => !files.some((image) => image.url === url)
                      ),
                  },
                  addressMarkerCoords
              )
            : processAccidents(
                  {
                      ...state,
                      files,
                      committedAt: committedAtMomentDate.toDate(),
                  },
                  addressMarkerCoords
              );
        this.props.onSubmitAccident(processedAccident);
    };

    handleDateInput = (date) => {
        this.setState((oldState) => ({
            ...oldState,
            committedAtMomentDate: date ? moment.utc(date) : moment.utc(),
        }));
    };

    processImages = () => {
        const { files } = this.state;
        return files.map((image, index) => {
            const imageProps = {};
            if (image instanceof File) {
                imageProps.key = image.lastModified + index;
                imageProps.src = URL.createObjectURL(image);
                imageProps.alt = image.name;
                imageProps.type = image.type;
            } else {
                imageProps.key = image.key;
                imageProps.src = image.url;
                imageProps.alt = image.name;
                imageProps.type = image.type;
            }
            return (
                <AccidentImage
                    key={index}
                    {...imageProps}
                    onClick={() => this.onDeleteImage(index)}
                />
            );
        });
    };

    onSelectAssignee = (assignee) => {
        this.setState((oldState) => ({ ...oldState, assignee }));
    };

    render() {
        const {
            region,
            type,
            cause,
            causeDetails,
            subtype,
            threadId,
            deviceCode,
            severity,
            emergencyServices,
            description,
            solution,
            paymentId,
            committedAtMomentDate,
            assignee,
            enteredAddress,
        } = this.state;
        const { assignees, types, accident, mapsCreated, onClose } = this.props;
        const typeInfo = types[type];
        const subtypesInfo = typeInfo?.subtypes;
        const causesInfo = typeInfo?.causes;
        const causeDetailsInfo = cause && typeInfo?.causesDetails?.[cause];
        const assigneeOptions = [
            V.assigneeDefaultValue,
            ...assignees.map(({ id, name }) => ({ value: id, label: name })),
        ];
        const causeDetailsValue = causeDetails.map((item) => ({
            value: item,
            label: V.accidentCauseDetailDict[item],
        }));
        return (
            <>
                <Modal.Body className="scroll-modal-body">
                    <RegionFilter
                        id="region"
                        label={t`Регион` + ':'}
                        onChange={this.onChange}
                        value={region}
                        className={regionStyle.defaultModalLabel}
                    />
                    {mapsCreated && (
                        <>
                            <EditAccidentModalMap
                                accident={accident}
                                region={region}
                                setAddressInputValue={this.setAddressInputValue}
                                updateCoordinates={this.updateCoordinates}
                                enteredAddress={enteredAddress}
                            />
                            <p>
                                <Trans>
                                    Сдвиньте курсор на карте в точку инцидента
                                    или введите адрес ниже
                                </Trans>
                            </p>
                        </>
                    )}
                    <Form.Group md="4">
                        <Form.Label>
                            <Trans>Адрес</Trans>:
                        </Form.Label>
                        <Form.Control
                            onChange={this.onChangeAddress}
                            ref={this.addressInputRef}
                        />
                    </Form.Group>
                    <DefaultModalInput
                        id="paymentId"
                        value={paymentId}
                        onChange={this.onChange}
                    >
                        <Trans>ID поездки</Trans>:
                    </DefaultModalInput>
                    <DefaultModalInput
                        id="threadId"
                        value={threadId}
                        onChange={this.onChange}
                    >
                        <Trans>ID треда</Trans>:
                    </DefaultModalInput>
                    <DefaultModalInput
                        id="deviceCode"
                        value={deviceCode}
                        onChange={this.onChange}
                    >
                        <Trans>ID Самоката</Trans>:
                    </DefaultModalInput>
                    <Form.Group md="4">
                        <Form.Label>
                            <Trans>Дата и время инцидента</Trans>:
                        </Form.Label>
                        <DateInput
                            momentDate={committedAtMomentDate}
                            onChange={this.handleDateInput}
                        />
                    </Form.Group>
                    <DefaultModalSelect
                        id="type"
                        onChange={this.onChangeType}
                        value={type}
                        options={Object.entries(V.accidentTypeDict).map(
                            ([value, label]) => ({
                                value,
                                label,
                            })
                        )}
                        label={t`Тип инцидента` + ':'}
                    >
                        <option disabled value="">
                            {t`Не выбрано`}
                        </option>
                    </DefaultModalSelect>
                    {subtypesInfo && (
                        <DefaultModalSelect
                            id="subtype"
                            onChange={this.onChange}
                            value={subtype}
                            options={subtypesInfo}
                            label={t`Вид инцидента` + ':'}
                        >
                            <option disabled value="">
                                {t`Не выбрано`}
                            </option>
                        </DefaultModalSelect>
                    )}
                    {causesInfo && (
                        <DefaultModalSelect
                            id="cause"
                            onChange={this.onChangeCause}
                            value={cause}
                            options={causesInfo}
                            label={t`Причина инцидента` + ':'}
                        >
                            <option disabled value="">
                                {t`Не выбрано`}
                            </option>
                        </DefaultModalSelect>
                    )}
                    {cause && causeDetailsInfo && (
                        <div className="form-group">
                            <label
                                htmlFor="causeDetails"
                                className="form-label"
                            >
                                {V.accidentCauseTitleDict[cause] + ':'}
                            </label>
                            <MultiSelect
                                id="causeDetails"
                                onChange={this.onChangeCauseDetails}
                                values={causeDetailsValue}
                                options={causeDetailsInfo}
                                contentRenderer={() => (
                                    <div>
                                        {causeDetailsValue.length}{' '}
                                        <Plural
                                            one="элемент"
                                            few="элемента"
                                            other="элементов"
                                            value={causeDetailsValue.length}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    )}
                    <Form.Group md="4">
                        <Form.Label>
                            <Trans>Экстренные службы на месте</Trans>:
                        </Form.Label>
                        <div className="d-flex">
                            {V.emergencyServices.map(({ value, label }) => (
                                <SwitchInput
                                    id={value}
                                    key={value}
                                    onChange={this.onChangeEmergencyServices}
                                    checked={emergencyServices.includes(value)}
                                >
                                    {label}
                                </SwitchInput>
                            ))}
                        </div>
                    </Form.Group>
                    <DefaultModalSelect
                        id="severity"
                        value={severity}
                        onChange={this.onChange}
                        options={V.severityOptions}
                        label={t`Тяжесть` + ':'}
                    />
                    <DefaultModalInput
                        id="description"
                        onChange={this.onChange}
                        type="textarea"
                        value={description}
                    >
                        <Trans>Описание</Trans>:
                    </DefaultModalInput>
                    <DefaultModalInput
                        id="solution"
                        onChange={this.onChange}
                        type="textarea"
                        value={solution}
                    >
                        <Trans>Решение</Trans>:
                    </DefaultModalInput>
                    <Form.Group md="4">
                        <Form.Label>
                            <Trans>Фотографии</Trans>:
                        </Form.Label>
                        <div className={styles.imageInputBlock}>
                            {this.processImages()}
                            <Button
                                variant="outline-primary"
                                className={styles.addImageButton}
                                onClick={() =>
                                    this.filesInputRef.current.click()
                                }
                            >
                                +
                            </Button>
                            <input
                                ref={this.filesInputRef}
                                type="file"
                                accept="application/pdf, image/jpeg, image/png, application/vnd.openxmlformats-officedocument.wordprocessingml.document, audio/mpeg"
                                multiple
                                onChange={this.onChangeFileInput}
                                hidden
                            />
                        </div>
                    </Form.Group>
                    <Form.Group md="4">
                        <Form.Label>
                            <Trans>Ответственный</Trans>:
                        </Form.Label>
                        <Select
                            options={assigneeOptions}
                            searchable
                            values={assignee}
                            onChange={this.onSelectAssignee}
                            dropdownPosition="top"
                            dropdownHandle
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.onSubmit}>
                        <Trans>Сохранить</Trans>
                    </Button>
                    <Button variant="secondary" onClick={onClose}>
                        <Trans>Закрыть</Trans>
                    </Button>
                </Modal.Footer>
            </>
        );
    }
}

const mapStateToProps = ({ filters, assignees, regionFilters, types }) => ({
    assignees,
    filters,
    regionFilters,
    types,
});
export const EditAccidentModalBody = connect(mapStateToProps)(
    withUseMapsConnectionsHook(EditAccidentModalBodyComponent)
);
