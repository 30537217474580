import React from 'react';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createAsyncReduxStore } from 'UTILS/createAsyncReduxStore';
import { t } from '@lingui/macro';
import { withWrappedPage } from 'ADMIN_COMMON/components/HOC/withWrappedPage';
import { LazyTermsContainer } from './Container';

const PageContent = withWrappedPage(LazyTermsContainer);

export const PageTerms = () => (
    <Provider store={createAsyncReduxStore(true)}>
        <Helmet>
            <title>{t`Оферты`}</title>
        </Helmet>
        <div className="container-fluid modern-page">
            <div className="row">
                <main role="main" className="m-0 px-4 w-100">
                    <div className="js_request_success"></div>
                    <div className="js_request_error"></div>
                    <div className="js_ss_react"></div>

                    <PageContent />
                </main>
            </div>
        </div>
    </Provider>
);
