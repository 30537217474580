export const getUserNameAbbreviation = (fullName) => {
    const [firstName, lastName] = fullName.split(' ');
    let firstLetter = '';
    let secondLetter = '';
    if (firstName && typeof firstName === 'string')
        firstLetter = firstName[0].toUpperCase();
    if (lastName && typeof lastName === 'string')
        secondLetter = lastName[0].toUpperCase();
    return `${firstLetter}${secondLetter}` || 'U';
};
