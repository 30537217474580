import React from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Alert from 'react-bootstrap/Alert';
import { withDynamicModuleLoader } from 'ADMIN_COMMON/components/HOC/withDynamicModuleLoader';
import { useLogin } from '../../hooks/useLogin';
import { getAsyncLoginModule } from '../../asyncReducerModules/getAsyncLoginModule';
import { Trans, t } from '@lingui/macro';
import { useSearchParams } from 'react-router-dom';

const Container = () => {
    const [searchParams] = useSearchParams();
    const { handleChange, handleSubmit, error, closeError, hasError, form } =
        useLogin({ redirectUri: searchParams.get('redirect_uri') });

    return (
        <div className="text-center">
            <form className="form-signin" onSubmit={handleSubmit}>
                <h1 className="h3 mb-3 font-weight-normal">
                    <Trans>Пожалуйста, авторизуйтесь</Trans>
                </h1>
                {hasError && (
                    <Alert variant="danger" onClose={closeError} dismissible>
                        {error}
                    </Alert>
                )}
                <FormControl
                    type="tel"
                    id="inputPhone"
                    name="username"
                    placeholder={t`Номер телефона`}
                    required
                    autoFocus
                    onChange={handleChange('name')}
                    value={form.name}
                />
                <FormControl
                    type="password"
                    id="inputPassword"
                    name="password"
                    placeholder={t`Пароль`}
                    onChange={handleChange('password')}
                    value={form.password}
                    required
                />
                <Button size="lg" block type="submit">
                    <Trans>Войти</Trans>
                </Button>
            </form>
        </div>
    );
};

export default withDynamicModuleLoader(Container, [getAsyncLoginModule()]);
