'use strict';

import { prepareForValidation } from 'UTILS/prepareForValidation';

(function (e) {
    e.validate = function (silent, single) {
        if (!silent) prepareForValidation(this);
        if (!window.page.validators || !window.page.validators[this.dataset.validator]) return true;
        return window.page.validators[this.dataset.validator].validate(this, silent, single);
    };
})(Element.prototype);
