import {
    getLocalStorageItem,
    saveLocalStorageItem,
} from 'UTILS/localStorageHelpers';
import axios from 'COMMON/axios';
import * as T from 'ADMIN_COMMON/constants/types';

const saveFilters = (localStorageKey, filters) => {
    const filterObject = { filters, lastUpdateAt: new Date().toISOString() };
    saveLocalStorageItem(localStorageKey, filterObject);
};
const isLoadFiltersFromStorage = (filterObject) => {
    if (!filterObject) return false;
    const { lastUpdateAt } = filterObject;
    return !(lastUpdateAt && new Date() - new Date(lastUpdateAt) >= 3600000); // update 1 time per hour
};
export const commonFiltersRequest =
    ({
        successAction,
        url,
        localStorageKey,
        fieldName = 'filters',
        method = 'get',
        actionType,
    }) =>
    async (dispatch) => {
        try {
            const filterObject = getLocalStorageItem(localStorageKey);
            if (isLoadFiltersFromStorage(filterObject)) {
                const { filters } = filterObject;
                return dispatch(successAction(filters));
            }
            const { data } = await axios[method](url);
            const filters = data[fieldName] || [];
            saveFilters(localStorageKey, filters);
            dispatch(successAction(filters));
        } catch (err) {
            dispatch({ type: actionType + T.ERROR });
        }
    };
