'use strict';

import { addClass } from 'UTILS/addClass';
import { isDomNode } from 'UTILS/isDomNode';
import { isNodeList } from 'UTILS/isNodeList';
import { removeClass } from 'UTILS/removeClass';

/**
 * @param {string} className
 * @param {(Array|Element)} node
 * @param {=} toggle
 * @returns {(Array|Element)}
 * */
function toggleClass(className, node, toggle) {
    if (!node) return null;
    if (!className || typeof className !== 'string') return node;
    if (isNodeList(node)) {
        const result = [];
        if (typeof toggle !== 'undefined') {
            for (let i = 0; i < node.length; i++) {
                if (!isDomNode(node[i])) continue;
                toggle
                    ? addClass(className, node[i])
                    : removeClass(className, node[i]);
                result.push(node[i]);
            }
        } else {
            for (let i = 0; i < node.length; i++) {
                if (!isDomNode(node[i])) continue;
                node[i].hasClass(className)
                    ? removeClass(className, node[i])
                    : addClass(className, node[i]);
                result.push(node[i]);
            }
        }
        if (!result.length) return null;
        return result;
    }
    if (isDomNode(node)) {
        if (typeof toggle !== 'undefined') {
            toggle ? addClass(className, node) : removeClass(className, node);
        } else {
            node.hasClass(className)
                ? removeClass(className, node)
                : addClass(className, node);
        }
        return node;
    }
    return null;
}

export { toggleClass };
